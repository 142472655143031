import { BrowserRouter } from "react-router-dom";

import AppRoutes from "./routes";

import { Spinner } from "@/components/Spinner";
import { useAuthContext } from "@/contexts/auth/useAuthContext";

function App() {
  const { isLoading } = useAuthContext();

  if (isLoading) return <Spinner />;

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
