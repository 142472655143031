import { apiClient, getBearerToken } from "@/utils/apiClient";

export type TalkSummary = {
  [key: string]: string;
};

export const talkSummary = async (callId: string) => {
  const params = {
    call_id: callId
  };

  const bearerToken = await getBearerToken();
  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };

  try {
    const summary: TalkSummary = await apiClient.talk_summary.$post({
      query: params,
      config: {
        headers: headers
      }
    });
    console.log(summary);
    return summary;
  } catch (e) {
    // TODO: エラー通知
    console.error("Error:", e);
    return null;
  }
};
