import { apiClient, getBearerToken } from "@/utils/apiClient";
import { notifyErrorLog } from "@/utils/notifyErrorLog.ts";

export const getSumOfSummaryTargetChars = async () => {
  const bearerToken = await getBearerToken();

  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };

  try {
    const res = await apiClient.admin.summary_target.sum.$get({
      config: {
        headers: headers
      }
    });
    return res;
  } catch (e) {
    notifyErrorLog(e);
    throw new Error("Failed to get sum of summary target chars");
  }
};
