import { Center, Spinner as ChakraSpinner } from "@chakra-ui/react";
import { type FC } from "react";

type Props = {
  height?: string;
};

export const Spinner: FC<Props> = ({ height }: Props) => {
  return (
    <Center h={height ? height : "100vh"}>
      <ChakraSpinner
        color="blue.500"
        emptyColor="gray.200"
        size="xl"
        speed="0.65s"
        thickness="4px"
      />
    </Center>
  );
};
