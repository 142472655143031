import { Amplify, Auth } from "aws-amplify";
import { createContext, useEffect, useState } from "react";

import type { FC } from "react";

import { useCustomToast } from "@/hooks/useCustomToast";
import { authConfig } from "@/utils/authConfig";

type AuthProviderProps = {
  children: React.ReactNode;
};

export type AuthProps = {
  completeNewPassword: (newPassword: string) => Promise<void>;
  isAuthenticated: boolean;
  isChangingPassword: boolean;
  isLoading: boolean;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  userEmail: string;
};

export const AuthContext = createContext<AuthProps | undefined>(undefined);

Amplify.configure({ Auth: authConfig });

// Component側からはuseAuthContextを利用する
const useAuth = (): AuthProps => {
  const [user, setUser] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const { showErrorToast, showSuccessToast } = useCustomToast();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await Auth.currentAuthenticatedUser();
        console.log(userData.signInUserSession);
        setUserEmail(userData.attributes.email);
        setIsAuthenticated(true);
      } catch (error) {
        setUserEmail("");
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };
    fetchUser();
  }, []);

  const login = async (username: string, password: string) => {
    try {
      const userData = await Auth.signIn(username, password);
      if (userData.challengeName === "NEW_PASSWORD_REQUIRED") {
        setIsChangingPassword(true);
        setIsAuthenticated(false);
        showErrorToast("新しいパスワードを設定してください。");
      } else {
        setIsChangingPassword(false);
        setIsAuthenticated(true);
        setUserEmail(userData.attributes.email);
        showSuccessToast("ログインしました。");
      }
      setUser(userData);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      let message = "";
      if (error.code === "UserNotConfirmedException") {
        message = "ユーザーが確認されていません。";
      } else if (error.code === "PasswordResetRequiredException") {
        message = "パスワードのリセットが必要です。";
      } else if (error.code === "InvalidPasswordException") {
        message = "パスワードが正しくありません。";
      } else if (error.code === "NotAuthorizedException") {
        message = "ユーザー名またはパスワードが正しくありません。";
      } else {
        message = "ログインに失敗しました。";
      }
      showErrorToast(message);
    }
  };

  const logout = async () => {
    try {
      await Auth.signOut();
      setUser(null);
      setUserEmail("");
      setIsAuthenticated(false);
      showSuccessToast("ログアウトしました。");
    } catch (error) {
      showErrorToast("ログアウトに失敗しました。");
    }
  };

  const completeNewPassword = async (newPassword: string) => {
    try {
      const userData = await Auth.completeNewPassword(user, newPassword);
      setUser(userData);
      setIsAuthenticated(true);
      setIsChangingPassword(false);
      showSuccessToast("パスワードを変更し、ログインしました。");
    } catch (error) {
      showErrorToast(
        "パスワード変更に失敗しました。",
        "もう一度やり直してください。"
      );
    }
  };

  return {
    completeNewPassword,
    isAuthenticated,
    isChangingPassword,
    isLoading,
    login,
    logout,
    userEmail
  };
};

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
