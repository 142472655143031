import type { AspidaClient, BasicHeaders } from 'aspida';
import { dataToURLString } from 'aspida';
import type { Methods as Methods_x2ambn } from './admin/bulk_update_summary_target';
import type { Methods as Methods_1du1igj } from './admin/call/list';
import type { Methods as Methods_6d8xgm } from './admin/delete_faq';
import type { Methods as Methods_11qn3my } from './admin/delete_summary_target';
import type { Methods as Methods_13vl1o8 } from './admin/faq/create/csv';
import type { Methods as Methods_1glkf67 } from './admin/faq/list';
import type { Methods as Methods_pagjdo } from './admin/get_call_csv';
import type { Methods as Methods_17tdhw1 } from './admin/get_faq';
import type { Methods as Methods_j22ckl } from './admin/get_generated_question';
import type { Methods as Methods_14hhjjr } from './admin/get_summary_target';
import type { Methods as Methods_1ellon4 } from './admin/get_summary_target_list';
import type { Methods as Methods_wuocta } from './admin/summary_target/sum';
import type { Methods as Methods_5ysx55 } from './admin/tags/nofaq';
import type { Methods as Methods_meqbmh } from './admin/transcription/prompt';
import type { Methods as Methods_kt07gl } from './admin/transcription/prompt/upsert';
import type { Methods as Methods_xkfaoi } from './admin/upsert_summary_target';
import type { Methods as Methods_xu75a3 } from './admin/upsertfaq';
import type { Methods as Methods_9st1pv } from './create_call';
import type { Methods as Methods_lc9wap } from './faq/aggregate_related_faq';
import type { Methods as Methods_1ym47ee } from './faq/frequency_rank';
import type { Methods as Methods_1hynfh3 } from './faq/summarize_faq';
import type { Methods as Methods_i92khv } from './feedback_result';
import type { Methods as Methods_1qiuj3c } from './healthcheck';
import type { Methods as Methods_d9inqt } from './operation/note/list';
import type { Methods as Methods_xssl03 } from './search-faq';
import type { Methods as Methods_9a2yvc } from './search-tags';
import type { Methods as Methods_1vwc6lp } from './super_admin/cc/create';
import type { Methods as Methods_pqqveo } from './super_admin/faq/create/csv';
import type { Methods as Methods_1u8s93r } from './super_admin/operator/create';
import type { Methods as Methods_1n4k4qp } from './super_admin/transcription/prompt';
import type { Methods as Methods_1s0fnnx } from './super_admin/transcription/prompt/upsert';
import type { Methods as Methods_8ppqlb } from './talk_summary';
import type { Methods as Methods_1pns1yn } from './token';
import type { Methods as Methods_grg8dh } from './transcribe';

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? '' : baseURL).replace(/\/$/, '');
  const PATH0 = '/admin/bulk_update_summary_target';
  const PATH1 = '/admin/call/list';
  const PATH2 = '/admin/delete_faq';
  const PATH3 = '/admin/delete_summary_target';
  const PATH4 = '/admin/faq/create/csv';
  const PATH5 = '/admin/faq/list';
  const PATH6 = '/admin/get_call_csv';
  const PATH7 = '/admin/get_faq';
  const PATH8 = '/admin/get_generated_question';
  const PATH9 = '/admin/get_summary_target';
  const PATH10 = '/admin/get_summary_target_list';
  const PATH11 = '/admin/summary_target/sum';
  const PATH12 = '/admin/tags/nofaq';
  const PATH13 = '/admin/transcription/prompt';
  const PATH14 = '/admin/transcription/prompt/upsert';
  const PATH15 = '/admin/upsert_summary_target';
  const PATH16 = '/admin/upsertfaq';
  const PATH17 = '/create_call';
  const PATH18 = '/faq/aggregate_related_faq';
  const PATH19 = '/faq/frequency_rank';
  const PATH20 = '/faq/summarize_faq';
  const PATH21 = '/feedback_result';
  const PATH22 = '/healthcheck';
  const PATH23 = '/operation/note/list';
  const PATH24 = '/search-faq';
  const PATH25 = '/search-tags';
  const PATH26 = '/super_admin/cc/create';
  const PATH27 = '/super_admin/faq/create/csv';
  const PATH28 = '/super_admin/operator/create';
  const PATH29 = '/super_admin/transcription/prompt';
  const PATH30 = '/super_admin/transcription/prompt/upsert';
  const PATH31 = '/talk_summary';
  const PATH32 = '/token';
  const PATH33 = '/transcribe';
  const GET = 'GET';
  const POST = 'POST';

  return {
    admin: {
      bulk_update_summary_target: {
        /**
         * 要約対象項目を一括更新
         * @returns Successful Response
         */
        post: (option: { body: Methods_x2ambn['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_x2ambn['post']['resBody'], BasicHeaders, Methods_x2ambn['post']['status']>(prefix, PATH0, POST, option).json(),
        /**
         * 要約対象項目を一括更新
         * @returns Successful Response
         */
        $post: (option: { body: Methods_x2ambn['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_x2ambn['post']['resBody'], BasicHeaders, Methods_x2ambn['post']['status']>(prefix, PATH0, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH0}`,
      },
      call: {
        list: {
          /**
           * FAQがヒットしなかったタグ（key word）一覧を取得する.
           * 全データを取得できていない場合（データに続きがある）はbooleanがtrueになって返る
           * @returns Successful Response
           */
          get: (option: { query: Methods_1du1igj['get']['query'], config?: T | undefined }) =>
            fetch<Methods_1du1igj['get']['resBody'], BasicHeaders, Methods_1du1igj['get']['status']>(prefix, PATH1, GET, option).json(),
          /**
           * FAQがヒットしなかったタグ（key word）一覧を取得する.
           * 全データを取得できていない場合（データに続きがある）はbooleanがtrueになって返る
           * @returns Successful Response
           */
          $get: (option: { query: Methods_1du1igj['get']['query'], config?: T | undefined }) =>
            fetch<Methods_1du1igj['get']['resBody'], BasicHeaders, Methods_1du1igj['get']['status']>(prefix, PATH1, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_1du1igj['get']['query'] } | undefined) =>
            `${prefix}${PATH1}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
      },
      delete_faq: {
        /**
         * FAQの削除
         * @returns Successful Response
         */
        post: (option: { body: Methods_6d8xgm['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_6d8xgm['post']['resBody'], BasicHeaders, Methods_6d8xgm['post']['status']>(prefix, PATH2, POST, option).json(),
        /**
         * FAQの削除
         * @returns Successful Response
         */
        $post: (option: { body: Methods_6d8xgm['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_6d8xgm['post']['resBody'], BasicHeaders, Methods_6d8xgm['post']['status']>(prefix, PATH2, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH2}`,
      },
      delete_summary_target: {
        /**
         * 要約対象項目の作成/更新
         * @returns Successful Response
         */
        post: (option: { body: Methods_11qn3my['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_11qn3my['post']['resBody'], BasicHeaders, Methods_11qn3my['post']['status']>(prefix, PATH3, POST, option).json(),
        /**
         * 要約対象項目の作成/更新
         * @returns Successful Response
         */
        $post: (option: { body: Methods_11qn3my['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_11qn3my['post']['resBody'], BasicHeaders, Methods_11qn3my['post']['status']>(prefix, PATH3, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH3}`,
      },
      faq: {
        create: {
          csv: {
            /**
             * 管理者向け.csvでFAQを一括登録する。
             * csvには
             * ・question(null不可)
             * ・answer(null不可)
             * ・memo(null可)
             * ・url(null可)
             * ・ng_operation_note(null可)
             * のカラムが必要。
             * questionが空欄の場合は内部で自動生成する
             * @returns Successful Response
             */
            post: (option: { body: Methods_13vl1o8['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_13vl1o8['post']['resBody'], BasicHeaders, Methods_13vl1o8['post']['status']>(prefix, PATH4, POST, option, 'FormData').json(),
            /**
             * 管理者向け.csvでFAQを一括登録する。
             * csvには
             * ・question(null不可)
             * ・answer(null不可)
             * ・memo(null可)
             * ・url(null可)
             * ・ng_operation_note(null可)
             * のカラムが必要。
             * questionが空欄の場合は内部で自動生成する
             * @returns Successful Response
             */
            $post: (option: { body: Methods_13vl1o8['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_13vl1o8['post']['resBody'], BasicHeaders, Methods_13vl1o8['post']['status']>(prefix, PATH4, POST, option, 'FormData').json().then(r => r.body),
            $path: () => `${prefix}${PATH4}`,
          },
        },
        list: {
          /**
           * FAQ一覧を取得する
           */
          get: (option: { query: Methods_1glkf67['get']['query'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods_1glkf67['get']['status']>(prefix, PATH5, GET, option).send(),
          /**
           * FAQ一覧を取得する
           */
          $get: (option: { query: Methods_1glkf67['get']['query'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods_1glkf67['get']['status']>(prefix, PATH5, GET, option).send().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_1glkf67['get']['query'] } | undefined) =>
            `${prefix}${PATH5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
      },
      get_call_csv: {
        /**
         * Callテーブルから指定された期間のデータをCSVで返します。
         */
        post: (option: { body: Methods_pagjdo['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods_pagjdo['post']['status']>(prefix, PATH6, POST, option).send(),
        /**
         * Callテーブルから指定された期間のデータをCSVで返します。
         */
        $post: (option: { body: Methods_pagjdo['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods_pagjdo['post']['status']>(prefix, PATH6, POST, option).send().then(r => r.body),
        $path: () => `${prefix}${PATH6}`,
      },
      get_faq: {
        /**
         * FAQを取得する
         * @returns Successful Response
         */
        post: (option: { body: Methods_17tdhw1['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_17tdhw1['post']['resBody'], BasicHeaders, Methods_17tdhw1['post']['status']>(prefix, PATH7, POST, option).json(),
        /**
         * FAQを取得する
         * @returns Successful Response
         */
        $post: (option: { body: Methods_17tdhw1['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_17tdhw1['post']['resBody'], BasicHeaders, Methods_17tdhw1['post']['status']>(prefix, PATH7, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH7}`,
      },
      get_generated_question: {
        /**
         * @returns Successful Response
         */
        post: (option: { body: Methods_j22ckl['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_j22ckl['post']['resBody'], BasicHeaders, Methods_j22ckl['post']['status']>(prefix, PATH8, POST, option).json(),
        /**
         * @returns Successful Response
         */
        $post: (option: { body: Methods_j22ckl['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_j22ckl['post']['resBody'], BasicHeaders, Methods_j22ckl['post']['status']>(prefix, PATH8, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH8}`,
      },
      get_summary_target: {
        /**
         * 要約対象項目の取得
         * @returns Successful Response
         */
        post: (option: { body: Methods_14hhjjr['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_14hhjjr['post']['resBody'], BasicHeaders, Methods_14hhjjr['post']['status']>(prefix, PATH9, POST, option).json(),
        /**
         * 要約対象項目の取得
         * @returns Successful Response
         */
        $post: (option: { body: Methods_14hhjjr['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_14hhjjr['post']['resBody'], BasicHeaders, Methods_14hhjjr['post']['status']>(prefix, PATH9, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH9}`,
      },
      get_summary_target_list: {
        /**
         * 要約対象項目のリスト取得
         * @returns Successful Response
         */
        post: (option: { body: Methods_1ellon4['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_1ellon4['post']['resBody'], BasicHeaders, Methods_1ellon4['post']['status']>(prefix, PATH10, POST, option).json(),
        /**
         * 要約対象項目のリスト取得
         * @returns Successful Response
         */
        $post: (option: { body: Methods_1ellon4['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_1ellon4['post']['resBody'], BasicHeaders, Methods_1ellon4['post']['status']>(prefix, PATH10, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH10}`,
      },
      summary_target: {
        sum: {
          /**
           * コンタクトセンターで設定している要約項目とその詳細の合計文字数を取得する。
           * 合計でn文字以下になるのを推奨、という情報も返す。
           * @returns Successful Response
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_wuocta['get']['resBody'], BasicHeaders, Methods_wuocta['get']['status']>(prefix, PATH11, GET, option).json(),
          /**
           * コンタクトセンターで設定している要約項目とその詳細の合計文字数を取得する。
           * 合計でn文字以下になるのを推奨、という情報も返す。
           * @returns Successful Response
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_wuocta['get']['resBody'], BasicHeaders, Methods_wuocta['get']['status']>(prefix, PATH11, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH11}`,
        },
      },
      tags: {
        nofaq: {
          /**
           * FAQがヒットしなかったタグ（key word）一覧を取得する.
           * 全データを取得できていない場合（データに続きがある）はbooleanがtrueになって返る
           * @returns Successful Response
           */
          get: (option: { query: Methods_5ysx55['get']['query'], config?: T | undefined }) =>
            fetch<Methods_5ysx55['get']['resBody'], BasicHeaders, Methods_5ysx55['get']['status']>(prefix, PATH12, GET, option).json(),
          /**
           * FAQがヒットしなかったタグ（key word）一覧を取得する.
           * 全データを取得できていない場合（データに続きがある）はbooleanがtrueになって返る
           * @returns Successful Response
           */
          $get: (option: { query: Methods_5ysx55['get']['query'], config?: T | undefined }) =>
            fetch<Methods_5ysx55['get']['resBody'], BasicHeaders, Methods_5ysx55['get']['status']>(prefix, PATH12, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_5ysx55['get']['query'] } | undefined) =>
            `${prefix}${PATH12}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
      },
      transcription: {
        prompt: {
          upsert: {
            /**
             * whisper のプロンプトをアップサートする。
             * @returns Successful Response
             */
            post: (option: { body: Methods_kt07gl['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_kt07gl['post']['resBody'], BasicHeaders, Methods_kt07gl['post']['status']>(prefix, PATH14, POST, option).text(),
            /**
             * whisper のプロンプトをアップサートする。
             * @returns Successful Response
             */
            $post: (option: { body: Methods_kt07gl['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_kt07gl['post']['resBody'], BasicHeaders, Methods_kt07gl['post']['status']>(prefix, PATH14, POST, option).text().then(r => r.body),
            $path: () => `${prefix}${PATH14}`,
          },
          /**
           * whisper のプロンプトを取得する。
           * @returns Successful Response
           */
          get: (option: { query: Methods_meqbmh['get']['query'], config?: T | undefined }) =>
            fetch<Methods_meqbmh['get']['resBody'], BasicHeaders, Methods_meqbmh['get']['status']>(prefix, PATH13, GET, option).text(),
          /**
           * whisper のプロンプトを取得する。
           * @returns Successful Response
           */
          $get: (option: { query: Methods_meqbmh['get']['query'], config?: T | undefined }) =>
            fetch<Methods_meqbmh['get']['resBody'], BasicHeaders, Methods_meqbmh['get']['status']>(prefix, PATH13, GET, option).text().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_meqbmh['get']['query'] } | undefined) =>
            `${prefix}${PATH13}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
      },
      upsert_summary_target: {
        /**
         * 要約対象項目の作成/更新
         * @returns Successful Response
         */
        post: (option: { body: Methods_xkfaoi['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_xkfaoi['post']['resBody'], BasicHeaders, Methods_xkfaoi['post']['status']>(prefix, PATH15, POST, option).json(),
        /**
         * 要約対象項目の作成/更新
         * @returns Successful Response
         */
        $post: (option: { body: Methods_xkfaoi['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_xkfaoi['post']['resBody'], BasicHeaders, Methods_xkfaoi['post']['status']>(prefix, PATH15, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH15}`,
      },
      upsertfaq: {
        /**
         * タグ（複数可）を受取り、それを全文検索にかけてヒットしたFAQを返します。
         * @returns Successful Response
         */
        post: (option: { body: Methods_xu75a3['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_xu75a3['post']['resBody'], BasicHeaders, Methods_xu75a3['post']['status']>(prefix, PATH16, POST, option).json(),
        /**
         * タグ（複数可）を受取り、それを全文検索にかけてヒットしたFAQを返します。
         * @returns Successful Response
         */
        $post: (option: { body: Methods_xu75a3['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_xu75a3['post']['resBody'], BasicHeaders, Methods_xu75a3['post']['status']>(prefix, PATH16, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH16}`,
      },
    },
    create_call: {
      /**
       * 空のcallを作成します。
       * @returns Successful Response
       */
      post: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods_9st1pv['post']['resBody'], BasicHeaders, Methods_9st1pv['post']['status']>(prefix, PATH17, POST, option).json(),
      /**
       * 空のcallを作成します。
       * @returns Successful Response
       */
      $post: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods_9st1pv['post']['resBody'], BasicHeaders, Methods_9st1pv['post']['status']>(prefix, PATH17, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH17}`,
    },
    faq: {
      aggregate_related_faq: {
        /**
         * FAQ利用頻度集計API（通話終了時に叩くAPI）
         * keyword（会話履歴から重要そうなkeyword抽出）が作られていない場合keyword抽出処理から
         * keywordでfaq(es)検索し、faqと会話履歴を問題解決寄与されるかGPTに判定してもらい寄与されるとされたものを永続化する
         * 永続化先はFaqEvaludation
         * Args:
         *     background_tasks (BackgroundTasks): _description_
         *     body (AggregateRelatedFaqRequest): _description_
         *     current_user (AuthOperator, optional): _description_. Defaults to Depends(CognitoHandler.get_current_user).
         *     session (Session, optional): _description_. Defaults to Depends(get_db).
         *
         * Raises:
         *     e: _description_
         *
         * Returns:
         *     _type_: _description_
         */
        post: (option: { body: Methods_lc9wap['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods_lc9wap['post']['status']>(prefix, PATH18, POST, option).send(),
        /**
         * FAQ利用頻度集計API（通話終了時に叩くAPI）
         * keyword（会話履歴から重要そうなkeyword抽出）が作られていない場合keyword抽出処理から
         * keywordでfaq(es)検索し、faqと会話履歴を問題解決寄与されるかGPTに判定してもらい寄与されるとされたものを永続化する
         * 永続化先はFaqEvaludation
         * Args:
         *     background_tasks (BackgroundTasks): _description_
         *     body (AggregateRelatedFaqRequest): _description_
         *     current_user (AuthOperator, optional): _description_. Defaults to Depends(CognitoHandler.get_current_user).
         *     session (Session, optional): _description_. Defaults to Depends(get_db).
         *
         * Raises:
         *     e: _description_
         *
         * Returns:
         *     _type_: _description_
         */
        $post: (option: { body: Methods_lc9wap['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods_lc9wap['post']['status']>(prefix, PATH18, POST, option).send().then(r => r.body),
        $path: () => `${prefix}${PATH18}`,
      },
      frequency_rank: {
        /**
         * 高頻度に利用されたFAQランキングを返すAPI(通話画面で叩くAPIの)
         * FaqとFaqEvaluationからFaqEvaluationの多いものをランク順位に取得
         *
         * Returns:
         *     ResponseFAQContent: _description_
         * @returns Successful Response
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_1ym47ee['get']['resBody'], BasicHeaders, Methods_1ym47ee['get']['status']>(prefix, PATH19, GET, option).json(),
        /**
         * 高頻度に利用されたFAQランキングを返すAPI(通話画面で叩くAPIの)
         * FaqとFaqEvaluationからFaqEvaluationの多いものをランク順位に取得
         *
         * Returns:
         *     ResponseFAQContent: _description_
         * @returns Successful Response
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_1ym47ee['get']['resBody'], BasicHeaders, Methods_1ym47ee['get']['status']>(prefix, PATH19, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH19}`,
      },
      summarize_faq: {
        post: (option: { body: Methods_1hynfh3['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods_1hynfh3['post']['status']>(prefix, PATH20, POST, option).send(),
        $post: (option: { body: Methods_1hynfh3['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods_1hynfh3['post']['status']>(prefix, PATH20, POST, option).send().then(r => r.body),
        $path: () => `${prefix}${PATH20}`,
      },
    },
    feedback_result: {
      /**
       * ユーザーフィードバックを保存する
       */
      post: (option: { body: Methods_i92khv['post']['reqBody'], config?: T | undefined }) =>
        fetch<void, BasicHeaders, Methods_i92khv['post']['status']>(prefix, PATH21, POST, option).send(),
      /**
       * ユーザーフィードバックを保存する
       */
      $post: (option: { body: Methods_i92khv['post']['reqBody'], config?: T | undefined }) =>
        fetch<void, BasicHeaders, Methods_i92khv['post']['status']>(prefix, PATH21, POST, option).send().then(r => r.body),
      $path: () => `${prefix}${PATH21}`,
    },
    healthcheck: {
      get: (option?: { config?: T | undefined } | undefined) =>
        fetch<void, BasicHeaders, Methods_1qiuj3c['get']['status']>(prefix, PATH22, GET, option).send(),
      $get: (option?: { config?: T | undefined } | undefined) =>
        fetch<void, BasicHeaders, Methods_1qiuj3c['get']['status']>(prefix, PATH22, GET, option).send().then(r => r.body),
      $path: () => `${prefix}${PATH22}`,
    },
    operation: {
      note: {
        list: {
          /**
           * call_idからNG対応情報に抵触していないかどうかの情報を返します。
           * 返却値は一応FAQ情報全体（NG対応情報はFAQ情報と紐付いている）だが不要なら改修可
           * @returns Successful Response
           */
          get: (option: { query: Methods_d9inqt['get']['query'], config?: T | undefined }) =>
            fetch<Methods_d9inqt['get']['resBody'], BasicHeaders, Methods_d9inqt['get']['status']>(prefix, PATH23, GET, option).json(),
          /**
           * call_idからNG対応情報に抵触していないかどうかの情報を返します。
           * 返却値は一応FAQ情報全体（NG対応情報はFAQ情報と紐付いている）だが不要なら改修可
           * @returns Successful Response
           */
          $get: (option: { query: Methods_d9inqt['get']['query'], config?: T | undefined }) =>
            fetch<Methods_d9inqt['get']['resBody'], BasicHeaders, Methods_d9inqt['get']['status']>(prefix, PATH23, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_d9inqt['get']['query'] } | undefined) =>
            `${prefix}${PATH23}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
      },
    },
    search_faq: {
      /**
       * タグ（複数可）を受取り、それを全文検索にかけてヒットしたFAQを返します。
       * @returns Successful Response
       */
      post: (option: { body: Methods_xssl03['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_xssl03['post']['resBody'], BasicHeaders, Methods_xssl03['post']['status']>(prefix, PATH24, POST, option).json(),
      /**
       * タグ（複数可）を受取り、それを全文検索にかけてヒットしたFAQを返します。
       * @returns Successful Response
       */
      $post: (option: { body: Methods_xssl03['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_xssl03['post']['resBody'], BasicHeaders, Methods_xssl03['post']['status']>(prefix, PATH24, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH24}`,
    },
    search_tags: {
      /**
       * 通話IDを受取り、その文字起こしからキーワードタグを抽出し返却します。
       * @returns Successful Response
       */
      get: (option: { query: Methods_9a2yvc['get']['query'], config?: T | undefined }) =>
        fetch<Methods_9a2yvc['get']['resBody'], BasicHeaders, Methods_9a2yvc['get']['status']>(prefix, PATH25, GET, option).json(),
      /**
       * 通話IDを受取り、その文字起こしからキーワードタグを抽出し返却します。
       * @returns Successful Response
       */
      $get: (option: { query: Methods_9a2yvc['get']['query'], config?: T | undefined }) =>
        fetch<Methods_9a2yvc['get']['resBody'], BasicHeaders, Methods_9a2yvc['get']['status']>(prefix, PATH25, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods_9a2yvc['get']['query'] } | undefined) =>
        `${prefix}${PATH25}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
    },
    super_admin: {
      cc: {
        create: {
          /**
           * spiral 内部向け.コンタクトセンターのマスターデータを作成する
           * 最初のオペレーターも同時に作成する。
           * その際、オペレータデータがcognitoにも登録され、そのsub値をRDBのIDとして使う。
           * @returns Successful Response
           */
          post: (option: { body: Methods_1vwc6lp['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1vwc6lp['post']['resBody'], BasicHeaders, Methods_1vwc6lp['post']['status']>(prefix, PATH26, POST, option).json(),
          /**
           * spiral 内部向け.コンタクトセンターのマスターデータを作成する
           * 最初のオペレーターも同時に作成する。
           * その際、オペレータデータがcognitoにも登録され、そのsub値をRDBのIDとして使う。
           * @returns Successful Response
           */
          $post: (option: { body: Methods_1vwc6lp['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1vwc6lp['post']['resBody'], BasicHeaders, Methods_1vwc6lp['post']['status']>(prefix, PATH26, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH26}`,
        },
      },
      faq: {
        create: {
          csv: {
            /**
             * spiral 内部向け.csvでFAQを一括登録する。
             * csvには
             * ・question(null不可)
             * ・answer(null不可)
             * ・memo(null可)
             * ・url(null可)
             * ・ng_operation_note(null可)
             * のカラムが必要。
             * @returns Successful Response
             */
            post: (option: { body: Methods_pqqveo['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_pqqveo['post']['resBody'], BasicHeaders, Methods_pqqveo['post']['status']>(prefix, PATH27, POST, option, 'FormData').json(),
            /**
             * spiral 内部向け.csvでFAQを一括登録する。
             * csvには
             * ・question(null不可)
             * ・answer(null不可)
             * ・memo(null可)
             * ・url(null可)
             * ・ng_operation_note(null可)
             * のカラムが必要。
             * @returns Successful Response
             */
            $post: (option: { body: Methods_pqqveo['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_pqqveo['post']['resBody'], BasicHeaders, Methods_pqqveo['post']['status']>(prefix, PATH27, POST, option, 'FormData').json().then(r => r.body),
            $path: () => `${prefix}${PATH27}`,
          },
        },
      },
      operator: {
        create: {
          /**
           * spiral 内部向け.オペレーターのデータを作成する。
           * その際、オペレータデータがcognitoにも登録され、そのsub値をRDBのIDとして使う。
           * @returns Successful Response
           */
          post: (option: { body: Methods_1u8s93r['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1u8s93r['post']['resBody'], BasicHeaders, Methods_1u8s93r['post']['status']>(prefix, PATH28, POST, option).json(),
          /**
           * spiral 内部向け.オペレーターのデータを作成する。
           * その際、オペレータデータがcognitoにも登録され、そのsub値をRDBのIDとして使う。
           * @returns Successful Response
           */
          $post: (option: { body: Methods_1u8s93r['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1u8s93r['post']['resBody'], BasicHeaders, Methods_1u8s93r['post']['status']>(prefix, PATH28, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH28}`,
        },
      },
      transcription: {
        prompt: {
          upsert: {
            /**
             * spiral 内部向け. フロントから操作できるようになり次第消す
             * @returns Successful Response
             */
            post: (option: { body: Methods_1s0fnnx['post']['reqBody'], query: Methods_1s0fnnx['post']['query'], config?: T | undefined }) =>
              fetch<Methods_1s0fnnx['post']['resBody'], BasicHeaders, Methods_1s0fnnx['post']['status']>(prefix, PATH30, POST, option).text(),
            /**
             * spiral 内部向け. フロントから操作できるようになり次第消す
             * @returns Successful Response
             */
            $post: (option: { body: Methods_1s0fnnx['post']['reqBody'], query: Methods_1s0fnnx['post']['query'], config?: T | undefined }) =>
              fetch<Methods_1s0fnnx['post']['resBody'], BasicHeaders, Methods_1s0fnnx['post']['status']>(prefix, PATH30, POST, option).text().then(r => r.body),
            $path: (option?: { method: 'post'; query: Methods_1s0fnnx['post']['query'] } | undefined) =>
              `${prefix}${PATH30}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          /**
           * spiral 内部向け. フロントから操作できるようになり次第消す
           * @returns Successful Response
           */
          get: (option: { query: Methods_1n4k4qp['get']['query'], config?: T | undefined }) =>
            fetch<Methods_1n4k4qp['get']['resBody'], BasicHeaders, Methods_1n4k4qp['get']['status']>(prefix, PATH29, GET, option).text(),
          /**
           * spiral 内部向け. フロントから操作できるようになり次第消す
           * @returns Successful Response
           */
          $get: (option: { query: Methods_1n4k4qp['get']['query'], config?: T | undefined }) =>
            fetch<Methods_1n4k4qp['get']['resBody'], BasicHeaders, Methods_1n4k4qp['get']['status']>(prefix, PATH29, GET, option).text().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_1n4k4qp['get']['query'] } | undefined) =>
            `${prefix}${PATH29}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
      },
    },
    talk_summary: {
      /**
       * call_idからトークの要約を返します.
       * @returns Successful Response
       */
      post: (option: { query: Methods_8ppqlb['post']['query'], config?: T | undefined }) =>
        fetch<Methods_8ppqlb['post']['resBody'], BasicHeaders, Methods_8ppqlb['post']['status']>(prefix, PATH31, POST, option).json(),
      /**
       * call_idからトークの要約を返します.
       * @returns Successful Response
       */
      $post: (option: { query: Methods_8ppqlb['post']['query'], config?: T | undefined }) =>
        fetch<Methods_8ppqlb['post']['resBody'], BasicHeaders, Methods_8ppqlb['post']['status']>(prefix, PATH31, POST, option).json().then(r => r.body),
      $path: (option?: { method: 'post'; query: Methods_8ppqlb['post']['query'] } | undefined) =>
        `${prefix}${PATH31}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
    },
    token: {
      /**
       * cognito認証トークンを簡単に取得するための開発用エンドポイントです。
       */
      post: (option: { body: Methods_1pns1yn['post']['reqBody'], config?: T | undefined }) =>
        fetch<void, BasicHeaders, Methods_1pns1yn['post']['status']>(prefix, PATH32, POST, option).send(),
      /**
       * cognito認証トークンを簡単に取得するための開発用エンドポイントです。
       */
      $post: (option: { body: Methods_1pns1yn['post']['reqBody'], config?: T | undefined }) =>
        fetch<void, BasicHeaders, Methods_1pns1yn['post']['status']>(prefix, PATH32, POST, option).send().then(r => r.body),
      $path: () => `${prefix}${PATH32}`,
    },
    transcribe: {
      /**
       * 与えられた音声文から文字起こしを生成して返します。local確認時に音声ファイルを使う場合は、AWS_TRANSCRIBEを使う場合のみwavファイルでないと失敗する。
       * @returns Successful Response
       */
      post: (option: { body: Methods_grg8dh['post']['reqBody'], query: Methods_grg8dh['post']['query'], config?: T | undefined }) =>
        fetch<Methods_grg8dh['post']['resBody'], BasicHeaders, Methods_grg8dh['post']['status']>(prefix, PATH33, POST, option, 'FormData').json(),
      /**
       * 与えられた音声文から文字起こしを生成して返します。local確認時に音声ファイルを使う場合は、AWS_TRANSCRIBEを使う場合のみwavファイルでないと失敗する。
       * @returns Successful Response
       */
      $post: (option: { body: Methods_grg8dh['post']['reqBody'], query: Methods_grg8dh['post']['query'], config?: T | undefined }) =>
        fetch<Methods_grg8dh['post']['resBody'], BasicHeaders, Methods_grg8dh['post']['status']>(prefix, PATH33, POST, option, 'FormData').json().then(r => r.body),
      $path: (option?: { method: 'post'; query: Methods_grg8dh['post']['query'] } | undefined) =>
        `${prefix}${PATH33}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
    },
  };
};

export type ApiInstance = ReturnType<typeof api>;
export default api;
