import api from "@api/$api";
import aspida from "@aspida/axios";
import { Auth } from "aws-amplify";
import axios from "axios";

export const getBearerToken = async () => {
  const cognitoData = await Auth.currentSession();
  const currentSession = cognitoData;
  const idToken = cognitoData.getIdToken().getJwtToken();
  const [, payload] = idToken.split(".");
  const { exp } = JSON.parse(atob(payload));

  // console.log(JSON.parse(atob(payload)));

  // UNIXタイムスタンプをDateオブジェクトに変換
  // const expirationDate = new Date(exp * 1000);
  // console.log('トークンの有効期限:', expirationDate);

  // 現在のUNIXタイムスタンプを取得
  // 30秒猶予をもたせた時間
  const now = Math.floor(Date.now() / 1000 - 30);
  // トークンの有効期限と現在の時間を比較
  if (now >= exp) {
    console.log("Token expired. update Bearer Token");
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const newCurrentSession = await cognitoUser.refreshSession(
      currentSession.getRefreshToken().getToken()
    );
    return newCurrentSession.getIdToken().getJwtToken();
  } else {
    return idToken;
  }
};

export const getOperatorId = async () => {
  const cognitoData = await Auth.currentSession();
  const idToken = cognitoData.getIdToken().getJwtToken();
  const [, payload] = idToken.split(".");
  const { sub } = JSON.parse(atob(payload));

  return sub;
};

export const apiClient = api(
  aspida(axios, { baseURL: `${import.meta.env.VITE_API_HOST}` })
);
