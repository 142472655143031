import { useEffect, useState } from "react";

import { getSumOfSummaryTargetChars } from "@/api/admin/Summary/getSumOfSummaryTargetChars";
import { ResSumSummaryTargetChar } from "@/api/aspida/@types";

export type UseGetSumOfSummaryTargetChars = () => {
  usedCount: number | null;
  limit: number | null;
};

export const useGetSumOfSummaryTargetChars: UseGetSumOfSummaryTargetChars =
  () => {
    const [usedCount, setUsedCount] = useState<number | null>(null);
    const [limit, setLimit] = useState<number | null>(null);
    const fetchData = async () => {
      const res: ResSumSummaryTargetChar = await getSumOfSummaryTargetChars();
      setUsedCount(res.used_char_sum);
      setLimit(res.recommended_char_sum ?? null);
    };

    useEffect(() => {
      fetchData();
    }, []);

    return { usedCount, limit };
  };
