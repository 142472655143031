import {
  Box,
  Button,
  HStack,
  Heading,
  Link,
  ModalBody,
  Text,
  VStack,
  useDisclosure
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import type { QAFormData } from "@/components/Admin";
import type { ResponseFAQContentItem } from "@api/@types";

import { deleteFAQ } from "@/api/admin/FAQ/delete.ts";
import { getQAContent } from "@/api/admin/FAQ/getQAContent.ts";
import { upsertQAContent } from "@/api/admin/FAQ/upsertQAContent.ts";
import { QAndAForm } from "@/components/Admin";
import { CenterDialog } from "@/components/Dialogs/CenterDialog";
import { useCustomToast } from "@/hooks/useCustomToast";
import { AdminHeader } from "@/layouts/AdminHeader";
import { routing } from "@/utils/routing";

type PageStateType = "show" | "edit";

export const FAQContent = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isEditing = searchParams.get("edit") === "true";
  const [pageState, setPageState] = useState<PageStateType>(
    isEditing ? "edit" : "show"
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showSuccessToast } = useCustomToast();
  const { qaId } = useParams();
  const [qaContent, setQAContent] = useState<
    ResponseFAQContentItem | undefined
  >(undefined);

  useEffect(() => {
    setPageState(isEditing ? "edit" : "show");
  }, [isEditing]);

  useEffect(() => {
    (async () => {
      await fetchQAContent();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchQAContent = async () => {
    if (qaId === undefined) return;
    const data = await getQAContent({ id: qaId });
    if (data) {
      setQAContent(data);
    }
  };

  const submitAction = async (data: QAFormData) => {
    if (qaId === undefined) return;
    const status = await upsertQAContent({
      id: qaId,
      question: data.question,
      answer: data.answer,
      memo: data.memo,
      referralLink: data.referralLink,
      ngOperationNote: data.ngOperationNote,
      summary: data.summary
    });

    if (status) {
      fetchQAContent();
      navigate(routing.admin.faq.content.genPath(qaId));
    }
    console.log(data);
    console.log("submitAction");
  };
  const backAction = () => {
    if (qaId === undefined) return;
    console.log("backAction");
    navigate(routing.admin.faq.content.genPath(qaId));
  };

  const removeAction = async () => {
    if (qaId === undefined) return;
    console.log("removeAction");
    const res = await deleteFAQ({ id: qaId });
    console.log(res);
    if (res) {
      navigate(routing.admin.faq.list.path);
      showSuccessToast(`質問:「${qaContent?.question}」を削除しました。`);
    }
  };

  const handleEdit = () => {
    console.log("handleEdit");
    setSearchParams({ edit: "true" });
  };

  const faqData: {
    id: keyof QAFormData;
    label: string;
    placeholder?: string;
    value: string | undefined;
  }[] = [
    {
      id: "question",
      label: "質問",
      placeholder: "例: 自動車税の支払い時期は？",
      value: qaContent?.question
    },
    {
      id: "answer",
      label: "回答",
      placeholder:
        "例: 自動車税・軽自動車税は、その年の4月から翌年3月までの1年分の税金を、前払いの形で支払います。",
      value: qaContent?.answer
    },
    {
      id: "memo",
      label: "メモ",
      placeholder: "例: mail@gmail.com",
      value: qaContent?.memo || ""
    },
    {
      id: "summary",
      label: "要約",
      placeholder: "",
      value: qaContent?.summary || ""
    },
    {
      id: "referralLink",
      label: "参考記事リンク",
      placeholder: "例: https://example.com",
      value: qaContent?.url || ""
    },
    {
      id: "ngOperationNote",
      label: "NG対応アラート",
      placeholder:
        "例: 2024年5月以降に購入された商品Aでは、旧バージョンと異なり水洗いを案内するのはNGです。",
      value: qaContent?.ng_operation_note || ""
    }
  ];

  if (qaContent === undefined) return <></>;

  return (
    <VStack w="full" spacing="6">
      <AdminHeader
        title={pageState === "show" ? "質問応答 詳細" : "質問応答 編集"}
        onReturnClick={() => {
          navigate(-1); // ブラウザの履歴を一つ戻る（このページは、「質問応答一覧」か「よく使う質問応答一覧」のいずれからも遷移してくる可能性がある。）
        }}
      />
      {pageState === "show" ? (
        <VStack w="full" px={10} pb={8} spacing="8">
          <HStack justify="flex-end" w="full">
            <HStack spacing="6">
              <Button
                colorScheme="purple"
                onClick={() => onOpen()}
                variant="outline"
              >
                削除
              </Button>
              <Button colorScheme="purple" onClick={() => handleEdit()}>
                編集
              </Button>
            </HStack>
          </HStack>
          <VStack w="full" spacing="10">
            {faqData.map((item, index) => (
              <VStack key={item.id + index} align="start" w="full">
                <Heading as="h3" w="full" fontSize="3xl" fontWeight="bold">
                  {item.label}
                </Heading>
                <Box
                  w="full"
                  px={4}
                  py={2}
                  whiteSpace="pre-wrap"
                  wordBreak="break-word"
                  overflowWrap="break-word"
                >
                  {item.id === "referralLink" ? (
                    <Link
                      color="teal"
                      fontSize="2xl"
                      href={item.value}
                      isExternal
                    >
                      {item.value}
                    </Link>
                  ) : (
                    <Text fontSize="2xl">{item.value}</Text>
                  )}
                </Box>
              </VStack>
            ))}
          </VStack>
        </VStack>
      ) : (
        <QAndAForm
          faqData={faqData}
          submitAction={submitAction}
          backAction={backAction}
        />
      )}
      <CenterDialog
        isOpen={isOpen}
        primaryBtnLabel="削除"
        primaryBtnColorScheme="red"
        primaryBtnClick={removeAction}
        secondaryBtnLabel="キャンセル"
        secondaryBtnClick={onClose}
        secondaryBtnColorScheme="red"
        secondaryBtnVariant="outline"
        onClose={onClose}
      >
        <ModalBody p={6}>
          <Text align="left">質問:「{qaContent.question}」 を削除しますか</Text>
        </ModalBody>
      </CenterDialog>
    </VStack>
  );
};
