import { AvatarGroup, HStack, VStack } from "@chakra-ui/react";

import { ChatLoadingBalloon } from "@/components/ChatLoadingBalloon";
import { Customer } from "@/components/Customer";
import { Operator } from "@/components/Operator";

type UserVoiceActivityProps = {
  talkingStates: {
    [key: number]: boolean;
  };
  customerChannel: string;
};
export const UserVoiceActivity = (props: UserVoiceActivityProps) => {
  const { talkingStates, customerChannel } = props;
  return (
    <HStack justify="flex-start">
      <AvatarGroup max={2} size="md">
        {Object.keys(talkingStates)
          .filter(index => talkingStates[parseInt(index)])
          .map(index => {
            const isCustomer = index === customerChannel;
            return (
              <VStack key={index} spacing={1}>
                {isCustomer ? (
                  <Customer w={10} h={10} />
                ) : (
                  <Operator w={10} h={10} />
                )}
              </VStack>
            );
          })}
      </AvatarGroup>
      {Object.values(talkingStates).some(isTalking => isTalking) && (
        <VStack h="full">
          <ChatLoadingBalloon />
        </VStack>
      )}
    </HStack>
  );
};
