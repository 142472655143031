import { apiClient, getBearerToken, getOperatorId } from "@/utils/apiClient";
import { notifyErrorLog } from "@/utils/notifyErrorLog.ts";

type Props = {
  id: string;
};

export const getQAContent = async (params: Props) => {
  const { id } = params;

  const bearerToken = await getBearerToken();

  const operatorId = await getOperatorId();

  const newParams = {
    operator_id: operatorId,
    id: id
  };

  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };

  try {
    const res = await apiClient.admin.get_faq.$post({
      body: newParams,
      config: {
        headers: headers
      }
    });
    return res;
  } catch (e) {
    notifyErrorLog(e);
    return null;
  }
};
