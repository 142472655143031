import { apiClient, getBearerToken, getOperatorId } from "@/utils/apiClient";
import { notifyErrorLog } from "@/utils/notifyErrorLog.ts";
import { ResponseFAQContent } from "@api/@types";

type Props = {
  offset?: number;
  limit?: number;
  updated_at_start?: string | null;
  updated_at_end?: string | null;
  search_word?: string;
};

export const getFAQList = async (params: Props) => {
  const { offset, limit, updated_at_start, updated_at_end, search_word } = params;

  const bearerToken = await getBearerToken();

  const operatorId = await getOperatorId();

  const newParams = {
    operator_id: operatorId,
    offset: offset || 0,
    limit: limit || 20,
    updated_at_start: updated_at_start,
    updated_at_end: updated_at_end,
    search_word: search_word
  };

  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };

  type Response = [ResponseFAQContent, boolean];

  try {
    const res = (await apiClient.admin.faq.list.$get({
      query: newParams,
      config: {
        headers: headers
      }
    })) as unknown as Response;
    const QAContent = res[0] as ResponseFAQContent;
    const isLast = res[1] as boolean;
    console.log(QAContent);
    return { items: QAContent.data, isLast: isLast };
  } catch (e) {
    notifyErrorLog(e);
    return { items: [], hasMore: false };
  }
};
