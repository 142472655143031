import * as Sentry from "@sentry/browser";
import { Auth } from "aws-amplify";

import type { AxiosError } from "axios";

const getUserId = async () => {
  const user = await Auth.currentAuthenticatedUser();
  return user.username;
};

export function notifyErrorLog(err: Error | AxiosError | unknown) {
  console.error(err);

  getUserId()
    .then(user_id => {
      Sentry.setUser({ id: user_id });
    })
    .catch(_ => {
      Sentry.setUser({ id: "not logged in" });
    })
    .finally(() => {
      Sentry.captureException(err);
    });
}
