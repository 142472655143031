import { useEffect, useRef, useState } from "react";

import type { RecorderContextType } from "@/utils/detectSpeech.ts";

import { useCustomToast } from "@/hooks/useCustomToast.tsx";
import { MAX_INTERVAL_RECORDING_TIME } from "@/utils/constants.ts";
import {
  detectSpeech,
  setupVoiceDetectionRecorders
} from "@/utils/detectSpeech.ts";

export const useRecorders = () => {
  const recorderContexts = useRef<RecorderContextType[]>([]);
  const isStereo = useRef<boolean>(false);
  const { showErrorToast } = useCustomToast();
  const [isLoadingAudioMedia, setIsLoadingAudioMedia] = useState<boolean>(true);

  const updateRecorderContext = (props: {
    index: number;
    updateContext: {
      talkingTimeFrom?: number; // timestamp
      talkingTimeTo?: number | null;
    };
  }) => {
    const { index, updateContext } = props;
    const contexts = recorderContexts.current;
    const currentRecorderContext = contexts[index];
    contexts.splice(index, 1, {
      ...currentRecorderContext,
      ...updateContext
    });
    recorderContexts.current = contexts;
  };

  const toggleRecordingWithVADChanged = () => {
    recorderContexts.current.forEach((recorderContext, index) => {
      const { recorder, talkingTimeFrom, talkingTimeTo } = recorderContext;
      // console.log("recoder", recorder.state);
      const isActiveSpeech = detectSpeech({
        analyser: recorderContext.analyser
      });
      if (isActiveSpeech) {
        updateRecorderContext({
          index,
          updateContext: { talkingTimeTo: null }
        });
        if (recorder.state === "inactive") {
          console.log("active");
          recorder.start();
          updateRecorderContext({
            index,
            updateContext: { talkingTimeFrom: Date.now() }
          });
        }
      } else {
        if (recorder.state === "recording") {
          const INACTIVE_TIME = 1000; // ミリ秒
          const now = Date.now();
          // 音声再生時間が超えた場合は録音を停止して書き起こしを行う。
          if (now - talkingTimeFrom > MAX_INTERVAL_RECORDING_TIME) {
            console.log("stopWithMaxRecordingIntervalTime");
            recorder.stop();
            updateRecorderContext({
              index,
              updateContext: { talkingTimeTo: null }
            });
          } else if (talkingTimeTo === null) {
            updateRecorderContext({
              index,
              updateContext: { talkingTimeTo: Date.now() }
            });
          } else {
            if (now - talkingTimeTo > INACTIVE_TIME) {
              console.log("stop");
              recorder.stop();
              updateRecorderContext({
                index,
                updateContext: { talkingTimeTo: null }
              });
            }
          }
        }
      }
    });
  };

  useEffect(() => {
    const constraints = {
      video: false,
      audio: { echoCancellation: false } // エコーキャンセラ無効化
    };

    (async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        // オーディオトラックを取得
        const audioTrack = stream.getAudioTracks()[0];
        console.log(audioTrack);
        // オーディオトラックの設定を取得
        const settings = audioTrack.getSettings();
        console.log(settings);

        isStereo.current = settings.channelCount !== 1;
        console.log(isStereo.current ? "stereo" : "monaural");

        const newRecorderContexts = setupVoiceDetectionRecorders({
          stream: stream
        });

        recorderContexts.current = newRecorderContexts;
      } catch (e) {
        console.log(e);
        showErrorToast(
          "マイクを利用できません。",
          `ブラウザの設定を確認するか、PCがマイクを認識できているかご確認ください。\n ${e}`
        );
      } finally {
        setIsLoadingAudioMedia(false);
      }
    })();
    // NOTE: showErrorToastを入れると録音が動かなくなった。
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    recorderContexts,
    toggleRecordingWithVADChanged,
    isStereo,
    isLoadingAudioMedia
  };
};
