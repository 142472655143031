import { useEffect, useState } from "react";

import type { CallTranscriptionItem } from "@api/@types";

import { MainContext } from "@/contexts/mainContext/useMainContext";

export type MainContextType = {
  callId: string | null;
  isRecording: boolean;
  enableMicrophone: boolean;
  transcriptions: CallTranscriptionItem[];
  notes: string;
};

export type MainContextParamType = {
  callId?: string | null;
  isRecording?: boolean;
  enableMicrophone?: boolean;
  transcriptions?: CallTranscriptionItem[];
  notes?: string;
};

const defaultContext = {
  callId: null,
  isRecording: false,
  enableMicrophone: true,
  transcriptions: [],
  notes: ""
};

type Props = {
  children: React.ReactNode;
};
export const MainContextProvider = ({ children }: Props) => {
  const [mainContext, setContext] = useState<MainContextType>(defaultContext);

  const setMainContext = (obj: MainContextParamType) => {
    setContext({ ...mainContext, ...obj });
  };

  useEffect(() => {
    console.log(mainContext);
  }, [mainContext]);

  // useEffect(() => {
  //   setHomeContext(defaultHomeContext)
  // }, [])

  // NOTE: 事前処理でapi fetchする必要がある時に利用
  // if(homeContext === defaultHomeContext)
  //   return (<div>Loading...</div>)

  return (
    <MainContext.Provider value={{ ...mainContext, setMainContext }}>
      {children}
    </MainContext.Provider>
  );
};
