import { apiClient, getBearerToken, getOperatorId } from "@/utils/apiClient";
import { notifyErrorLog } from "@/utils/notifyErrorLog.ts";
import dayjs from "dayjs";

type Props = {
  lastUpdatedAt?: string | null;
  limit?: number;
};

const ExtendedISO8601Format = "YYYY-MM-DDTHH:mm:ss.SSS";

export const getSummaryTargets = async (params: Props) => {
  const { lastUpdatedAt, limit } = params;

  const bearerToken = await getBearerToken();

  const operatorId = await getOperatorId();

  const today = dayjs.utc().add(1, "day").format(ExtendedISO8601Format);
  let lastUpdatedAtDayjs;
  if (lastUpdatedAt) {
    lastUpdatedAtDayjs = dayjs.utc(lastUpdatedAt).format(ExtendedISO8601Format);
    console.log(lastUpdatedAtDayjs);
  }

  const newParams = {
    operator_id: operatorId,
    last_updated_at: lastUpdatedAtDayjs || today,
    limit: limit || 20
  };

  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };

  try {
    const res = await apiClient.admin.get_summary_target_list.$post({
      body: newParams,
      config: {
        headers: headers
      }
    });
    console.log(res);
    const content = res.data;
    console.log(content);
    return { items: res.data };
  } catch (e) {
    notifyErrorLog(e);
    return { items: [] };
  }
};
