import { VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import type { QAFormData } from "@/components/Admin";

import { upsertQAContent } from "@/api/admin/FAQ/upsertQAContent.ts";
import { QAndAForm } from "@/components/Admin";
import { useCustomToast } from "@/hooks/useCustomToast";
import { usePostSummarizeFaq } from "@/hooks/usePostSummarizeFaq";
import { AdminHeader } from "@/layouts/AdminHeader";
import { routing } from "@/utils/routing";

export const NewFAQ = () => {
  const navigate = useNavigate();
  const { showSuccessToast } = useCustomToast();
  const { asyncPostData: asyncPostSummarizeFaq } = usePostSummarizeFaq();

  const submitAction = async (data: QAFormData) => {
    console.log(data);
    console.log("submitAction");

    const newId = `${crypto.randomUUID()}`;

    const status = await upsertQAContent({
      id: newId,
      question: data.question,
      answer: data.answer,
      memo: data.memo,
      referralLink: data.referralLink,
      ngOperationNote: data.ngOperationNote
    });

    if (status) {
      // 要約backgroundtask
      asyncPostSummarizeFaq({ faq_id: newId });
      navigate(routing.admin.faq.list.path);
      showSuccessToast("新しい質問応答を登録しました。");
    }

    console.log(status);
  };
  const backAction = () => {
    console.log("backAction");
    navigate(routing.admin.faq.list.path);
  };
  return (
    <VStack w="full" spacing="6">
      <AdminHeader
        title="質問応答 新規追加"
        onReturnClick={() => navigate(routing.admin.faq.list.path)}
      />
      <QAndAForm
        submitAction={submitAction}
        backAction={backAction}
        faqData={[
          {
            id: "question",
            label: "質問",
            placeholder: "例: 自動車税の支払い時期は？",
            value: ""
          },
          {
            id: "answer",
            label: "回答",
            placeholder:
              "例: 自動車税・軽自動車税は、その年の4月から翌年3月までの1年分の税金を、前払いの形で支払います。",
            value: ""
          },
          {
            id: "memo",
            label: "メモ",
            placeholder: "例: mail@gmail.com",
            value: ""
          },
          {
            id: "referralLink",
            label: "参考記事リンク",
            placeholder: "例: https://example.com",
            value: ""
          },
          {
            id: "ngOperationNote",
            label: "NG対応アラート",
            placeholder:
              "例: 2024年5月以降に購入された商品Aでは、旧バージョンと異なり水洗いを案内するのはNGです。",
            value: undefined
          }
        ]}
      />
    </VStack>
  );
};
