import { useMicVAD, utils } from "@ricky0123/vad-react";
import { useEffect, useState } from "react";

import { useMainContext } from "@/contexts/mainContext/useMainContext.ts";
import { useCustomToast } from "@/hooks/useCustomToast";
import { useValueRef } from "@/hooks/useValueRef.tsx";

export type VADType = {
  listening: boolean;
  errored:
    | false
    | {
        message: string;
      };
  loading: boolean;
  userSpeaking: boolean;
  pause: () => void;
  start: () => void;
  toggle: () => void;
};

type Props = {
  stream: MediaStream;
  speaker: "customer" | "operator" | "unknown";
  setTalkingStates: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  setIsMediaRecorderInitialized: (value: boolean) => void;
  addRecordingQueueTask: (
    audioBlob: Blob,
    recorderIndex: number,
    recordingTime: number
  ) => void;
  isActiveVoice: boolean;
  setIsActiveVoice: (isActiveVoice: boolean) => void;
  vadRef: React.MutableRefObject<VADType | null>;
};

export const MicVAD = (props: Props) => {
  const {
    stream,
    speaker,
    setTalkingStates,
    setIsMediaRecorderInitialized,
    addRecordingQueueTask,
    isActiveVoice,
    setIsActiveVoice,
    vadRef
  } = props;
  const [startTime, setStartTime] = useState<number>(0);
  const { showErrorToast } = useCustomToast();

  const channel = speaker == "operator" ? "1" : "0"; // "0" or "1
  const mainCtx = useMainContext();
  const mainCtxRef = useValueRef(mainCtx);

  const positiveSpeechThreshold = parseFloat("0.8");
  const redemptionFrames = parseFloat("8");

  const vad: VADType = useMicVAD({
    modelURL: "/vad/silero_vad.onnx",
    workletURL: "/vad/vad.worklet.bundle.min.js",
    startOnLoad: true,
    stream: stream,
    positiveSpeechThreshold: positiveSpeechThreshold,
    negativeSpeechThreshold: 0.5,
    redemptionFrames: redemptionFrames,
    // minSpeechFrames: 3,
    preSpeechPadFrames: 10,
    submitUserSpeechOnPause: true,
    onFrameProcessed: probs => {
      console.log("VAD: onFrameProcessed", probs);
    },
    onVADMisfire: () => {
      console.log("VAD misfire skip");
      setIsActiveVoice(false);
    },
    onSpeechStart: () => {
      // NOTE: 以下の場合はスキップ
      // NOTE: - マイクが無効
      const enableMicrophone = mainCtxRef.current.enableMicrophone;
      const isSkip = !enableMicrophone && speaker !== "customer";
      console.table({
        status: "VAD: onSpeechStart",
        enableMicrophone,
        isActiveVoice,
        isSkip
      });

      if (isSkip) {
        console.table({
          state: "VAD: onSpeechStart: SKIP"
        });
        return;
      }
      setTalkingStates(prev => ({ ...prev, [channel]: true }));
      setStartTime(Date.now());
      setIsActiveVoice(true);
    },
    onSpeechEnd: async audio => {
      // NOTE: 以下の場合はスキップ
      // NOTE: - マイクが無効
      const enableMicrophone = mainCtxRef.current.enableMicrophone;
      const isSkip = !enableMicrophone && speaker !== "customer";
      console.table({
        status: "onSpeechEnd",
        enableMicrophone,
        isActiveVoice
      });
      setTalkingStates(prev => ({ ...prev, [channel]: false }));
      if (isSkip) {
        console.table({
          state: "VAD: onSpeechEnd: SKIP"
        });
        return;
      }

      const endTime = Date.now();
      console.log("recording time:", endTime - startTime, "ms");

      const wavBuffer = utils.encodeWAV(audio);
      const base64 = utils.arrayBufferToBase64(wavBuffer);

      const bin = atob(base64.replace(/^.*,/, ""));
      const buffer = new Uint8Array(bin.length).map((_, i) =>
        bin.charCodeAt(i)
      );
      try {
        const audioBlob = new Blob([buffer.buffer], {
          type: "audio/wav"
        });

        addRecordingQueueTask(
          audioBlob,
          speaker === "operator" ? 1 : 0,
          endTime - startTime
        );

        setIsActiveVoice(false);
      } catch (e) {
        return false;
      }
    }
  });
  vadRef.current = vad;

  useEffect(() => {
    console.log("vad loading", vad.loading);
    if (!vad.loading) {
      console.log("vad loaded");
      setIsMediaRecorderInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vad.loading]);

  useEffect(() => {
    if (vad.errored) {
      console.log("vad errored", vad.errored.message);
      showErrorToast(vad.errored.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vad.errored]);

  return <></>;
};
