import {
  Box,
  Button,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";

import type { ModalContentProps, ThemeTypings } from "@chakra-ui/react";
import type { FC } from "react";

type ButtonVariant = "solid" | "outline" | "ghost" | "link" | "unstyled";

type CenterDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  headerText?: string;
  headerTextColor?: string;
  primaryBtnLabel?: string;
  primaryBtnColorScheme?: ThemeTypings["colorSchemes"];
  primaryBtnVariant?: ButtonVariant;
  primaryBtnClick?: () => void;
  secondaryBtnLabel?: string;
  secondaryBtnColorScheme?: ThemeTypings["colorSchemes"];
  secondaryBtnVariant?: ButtonVariant;
  secondaryBtnClick?: () => void;
  isPrimaryBtnDisabled?: boolean;
  isSecondaryBtnDisabled?: boolean;
  isShowPrimaryBtn?: boolean;
  isShowSecondaryBtn?: boolean;
  isShowCloseBtn?: boolean;
  enableClose?: boolean;
} & ModalContentProps;

export const CenterDialog: FC<CenterDialogProps> = props => {
  const {
    children,
    isOpen,
    onClose,
    isShowCloseBtn = "true",
    headerText,
    headerTextColor,
    primaryBtnLabel,
    primaryBtnClick,
    secondaryBtnLabel = "閉じる",
    primaryBtnColorScheme = "purple",
    primaryBtnVariant = "solid",
    secondaryBtnClick = onClose,
    secondaryBtnColorScheme = "gray",
    secondaryBtnVariant = "solid",
    isPrimaryBtnDisabled = false,
    isSecondaryBtnDisabled = false,
    isShowPrimaryBtn = true,
    isShowSecondaryBtn = true,
    enableClose = true,
    ...modalContentProps
  } = props;

  return (
    <Modal
      autoFocus={false}
      closeOnOverlayClick={enableClose}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        w="calc(100% - 32px)" // 100% - 16px * 2
        maxW="528px" // 560px - 16px * 2
        mx={4}
        py={0}
        borderRadius="6px"
        {...modalContentProps}
      >
        {isShowCloseBtn && (
          <Box pos="absolute" top="0" right="0">
            <ModalCloseButton
              color="text.main"
              fontSize="12px"
              _focus={{ boxShadow: "none" }}
              autoFocus={false}
            />
          </Box>
        )}

        {headerText && (
          <ModalHeader
            color={headerTextColor}
            fontSize="lg"
            fontWeight="bold"
            textAlign="left"
          >
            {headerText}
          </ModalHeader>
        )}
        {children}

        {(isShowPrimaryBtn || isShowSecondaryBtn) && (
          <HStack justify="end" w="full" px={6} py={4} spacing={3}>
            {isShowSecondaryBtn && (
              <Button
                h={10}
                colorScheme={secondaryBtnColorScheme}
                isDisabled={isSecondaryBtnDisabled}
                onClick={secondaryBtnClick}
                variant={secondaryBtnVariant}
              >
                {secondaryBtnLabel}
              </Button>
            )}
            {isShowPrimaryBtn && (
              <Button
                h={10}
                colorScheme={primaryBtnColorScheme}
                isDisabled={isPrimaryBtnDisabled}
                onClick={primaryBtnClick}
                variant={primaryBtnVariant}
              >
                {primaryBtnLabel}
              </Button>
            )}
          </HStack>
        )}
      </ModalContent>
    </Modal>
  );
};
