import { apiClient, getBearerToken, getOperatorId } from "@/utils/apiClient";
import { notifyErrorLog } from "@/utils/notifyErrorLog.ts";

type Props = {
  id: string;
  name: string;
  details: string;
  importanceLevel: number;
};

export const upsertSummaryTarget = async (params: Props) => {
  const { id, name, details, importanceLevel } = params;

  const bearerToken = await getBearerToken();

  const operatorId = await getOperatorId();

  const newParams = {
    id: id,
    operator_id: operatorId,
    name: name,
    details: details,
    importance_level: importanceLevel || 0,
    updated_by: operatorId
  };

  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };

  try {
    const res = await apiClient.admin.upsert_summary_target.$post({
      body: newParams,
      config: {
        headers: headers
      }
    });
    return res.status;
  } catch (e) {
    notifyErrorLog(e);
    return false;
  }
};
