import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  Textarea,
  VStack
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";

import type { SubmitHandler } from "react-hook-form";

export type SummaryFormData = {
  name: string;
  details: string;
  importanceLevel: number;
};

type Props = {
  summaryData: {
    id: keyof SummaryFormData;
    label: string;
    desription?: string;
    placeholder?: string;
    value: SummaryFormData[keyof SummaryFormData] | undefined;
  }[];
  backAction: () => void;
  submitAction: SubmitHandler<SummaryFormData>;
};

const validationRules = {
  name: {
    required: "項目名を入力してください。",
    maxLength: {
      value: 100,
      message: "項目名は100文字以内で入力してください。"
    }
  },
  details: {
    required: "要約のシステム指示を入力してください。",
    maxLength: {
      value: 500,
      message: "要約のシステム指示は500文字以内で入力してください。"
    }
  },
  importanceLevel: {
    required: "重要度を入力してください",
    min: { value: 1, message: "重要度は1以上の値を入力してください。" }
  }
};

export const SummaryTargetForm = (props: Props) => {
  const { submitAction, backAction, summaryData } = props;
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<SummaryFormData>();

  const onSubmit: SubmitHandler<SummaryFormData> = async data => {
    await submitAction(data);
    console.log(data);
  };

  const isNew = summaryData?.[0].id === "name" && summaryData?.[0].value === "";

  return (
    <Box as="form" w="full" noValidate onSubmit={handleSubmit(onSubmit)}>
      <VStack w="full" px={10} pt="2" pb={8} spacing="8">
        {summaryData?.map((item, index) => (
          <FormControl key={index}>
            <FormLabel fontSize="3xl" fontWeight="bold">
              {item.label}
            </FormLabel>
            {item.desription && (
              <Text mb={3} fontSize="md">
                {item.desription}
              </Text>
            )}
            <VStack align="start" w="full">
              {item.id === "name" ? (
                <Controller
                  name={item.id}
                  control={control}
                  defaultValue={(item.value || "") as string}
                  render={({ field }) => (
                    <Input
                      w="full"
                      placeholder={item.placeholder}
                      {...field}
                      data-1p-ignore
                      maxLength={100}
                    />
                  )}
                  rules={validationRules[item.id]}
                />
              ) : item.id === "importanceLevel" ? (
                <Controller
                  name={item.id}
                  control={control}
                  defaultValue={(item.value || 1) as number}
                  render={({ field }) => (
                    <NumberInput {...field} w="full" min={1}>
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  )}
                  rules={validationRules[item.id]}
                />
              ) : (
                <Controller
                  name={item.id}
                  control={control}
                  defaultValue={(item.value || "") as string}
                  render={({ field }) => (
                    <Textarea
                      w="full"
                      placeholder={item.placeholder}
                      {...field}
                      maxLength={500}
                    />
                  )}
                  rules={validationRules[item.id]}
                />
              )}
              {errors[item.id] && (
                <Text color="red.500" fontSize="sm">
                  {errors[item.id]?.message}
                </Text>
              )}
            </VStack>
          </FormControl>
        ))}
        <HStack justify="flex-end" w="full" spacing="6">
          <Button
            colorScheme={isNew ? "teal" : "purple"}
            // isDisabled={!isValid}
            onClick={backAction}
            type="reset"
            variant="outline"
          >
            キャンセル
          </Button>
          <Button
            colorScheme={isNew ? "teal" : "purple"}
            // isDisabled={!isValid}
            type="submit"
            variant="solid"
          >
            {isNew ? "登録" : "更新"}
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};
