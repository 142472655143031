import { Box, Text, VStack } from "@chakra-ui/react";
import { FC } from "react";
import { LuTrophy } from "react-icons/lu";

import { HomeHeading } from "../HomeHeading";
import { ProposalAccordion, ProposalProps } from "../ProposalAccordion";

type FAQRankingProps = {
  isLargerThan960: boolean;
  smallFontSize: string;
  qalist: ProposalProps[]; // TODO: ここを変更
};

export const FAQRanking: FC<FAQRankingProps> = ({
  isLargerThan960,
  smallFontSize,
  qalist
}) => {
  // const mainCtx = useMainContext();

  return (
    <Box w="full" maxW={isLargerThan960 ? "960px" : "full"} mx="auto">
      <VStack px="6" spacing="6">
        <VStack w="full" spacing="3">
          <HomeHeading icon={LuTrophy} title="FAQ頻出ランキング" />
          <Text as="span" align="right" w="full" fontSize={smallFontSize}>
            ※頻出のものから順に表示されています。
          </Text>
          <Box w="full" p="2" bgColor="white">
            {/* {mainCtx.callId && isLoadingSearchResult ? (
              <VStack>
                <Skeleton w="full" h="20px" />
                <Skeleton w="full" h="20px" />
                <Skeleton w="full" h="20px" />
              </VStack>
            ) : ( */}
            <ProposalAccordion qalist={qalist} />
            {/* )} */}
          </Box>
        </VStack>
      </VStack>
    </Box>
  );
};
