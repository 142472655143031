import { HStack, Switch, Text } from "@chakra-ui/react";

import { useMainContext } from "@/contexts/mainContext/useMainContext.ts";

export const MicToggleButton = () => {
  const mainCtx = useMainContext();

  const toggleEnableMicrophone = () => {
    mainCtx.setMainContext({ enableMicrophone: !mainCtx.enableMicrophone });
  };
  return (
    <HStack pos="relative" top="-3" spacing="0">
      <HStack h="12" px="2" spacing="2">
        <Text as="label" fontSize="xl" cursor="pointer" htmlFor="mic-switch">
          マイク
        </Text>
        <Switch
          defaultChecked={mainCtx.enableMicrophone}
          id="mic-switch"
          isChecked={mainCtx.enableMicrophone}
          onChange={() => {
            toggleEnableMicrophone();
          }}
          size="lg"
        />
      </HStack>
    </HStack>
  );
};
