import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

// NOTE: Naomi.AIから移植

// NOTE: 本来はutcなら末尾にZをつけたりするが、バックエンドの仕様に合わせるために末尾にZをつけない
const ExtendedISO8601Format = "YYYY-MM-DDTHH:mm:ss.SSS";

/** timestampが文字列の場合は、dayjsに渡す前にInvalid Dateにならないように前処理する */
const timestampToDayjs = (timestamp?: string | number | Date | dayjs.Dayjs) => {
  if (typeof timestamp !== "string") {
    return dayjs(timestamp);
  }

  // YYYY-MM-DD形式の場合はそのままdayjsに渡す
  const isValidDateFormat = (date: string): boolean => {
    return /^\d{4}-\d{1,2}-\d{1,2}$/.test(date);
  };
  if (isValidDateFormat(timestamp)) {
    return dayjs(timestamp);
  }

  // timestampにシングルクオートが混入するケースへの対処
  const dateString = timestamp.replace(/'/g, "");

  // 末尾にZがない場合timezone awareとして扱われてしまう場合があるの対処
  const dateStringWithZ = dateString.endsWith("Z")
    ? dateString
    : `${dateString}Z`;

  return dayjs(dateStringWithZ);
};

const formatDateAndTime = (date: string) => {
  const inputDate = timestampToDayjs(date);
  const today = dayjs();

  const formattedDateTime = inputDate.format("YYYY-MM-DD HH:mm");

  if (inputDate.isBefore(today, "day")) {
    return formattedDateTime;
  }

  return inputDate.format("HH:mm");
};

const formatTime = (date: string) => {
  const inputDate = timestampToDayjs(date);

  return inputDate.format("HH:mm");
};

const isToday = (inputDate: string): boolean => {
  const today = dayjs();
  const targetDate = timestampToDayjs(inputDate);
  return targetDate.isBefore(today, "day") || targetDate.isSame(today, "day");
};

const getFormattedDate = (inputDate: string): string => {
  const today = dayjs();
  const targetDate = timestampToDayjs(inputDate);

  if (targetDate.isSame(today, "day")) {
    return "Today"; // 日付が今日であれば "Today" を返す
  } else {
    return targetDate.format("YYYY/MM/DD"); // それ以外の場合は日付を指定のフォーマットで返す
  }
};

// NOTE: backend APIの仕様により、dateStringを末尾zのないISO8601に変換する
// ISO8601形式の日付文字列を取得する (zなし)
// e.g.: dateString = 2023-11-07T04:41:56.097105Z
const getIsoFormatTime = (dateString: string): string => {
  return dayjs.utc(dateString).format(ExtendedISO8601Format);
};

// e.g.: dateString = '2023-11-07T04:41:56.097105Z'
const getNextDay = (dateString?: string): string => {
  if (dateString) {
    return dayjs.utc(dateString).add(1, "day").format(ExtendedISO8601Format);
  } else {
    return dayjs.utc().add(1, "day").format(ExtendedISO8601Format);
  }
};

export {
  formatDateAndTime,
  formatTime,
  getFormattedDate,
  getIsoFormatTime,
  getNextDay,
  isToday,
  timestampToDayjs
};
