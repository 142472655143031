import {
  Button,
  Center,
  FormControl,
  HStack,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { MdChevronLeft, MdChevronRight, MdSearch } from "react-icons/md";

import { DetailDrawer } from "@/components/Admin/DetailDrawer";
import { DatePicker } from "@/components/Admin/RangeDatePicker/DatePicker";
import { Spinner } from "@/components/Spinner";
import { useCustomToast } from "@/hooks/useCustomToast";
import { useGetCalls } from "@/hooks/useGetCalls";
import { AdminHeader } from "@/layouts/AdminHeader";
import { timestampToDayjs } from "@/utils/dateUtils";

const ExtendedISO8601Format = "YYYY-MM-DD";
export const CallList = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [contentDetail, setContentDetail] = useState<string>("");

  const [startDateJst, setStartDateJst] = useState<string>(
    dayjs.utc().add(-1, "day").format(ExtendedISO8601Format)
  );
  const [endDateJst, setEndDateJst] = useState<string>(
    dayjs.utc().format(ExtendedISO8601Format)
  );
  const limit = 20;
  const headerHeight = 300;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const { calls, hasMore, refetch, isLoading } = useGetCalls({
    startDateJst: startDateJst,
    endDateJst: endDateJst,
    limit: limit,
    offset: limit * (currentPage - 1)
  });

  const prevAction = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextAction = async () => {
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const { showErrorToast } = useCustomToast();

  const handleOnSearch = () => {
    if (dayjs.utc(endDateJst).diff(dayjs.utc(startDateJst), "day") > 360) {
      showErrorToast("検索期間は360日以内に設定してください。");
      return;
    }
    setCurrentPage(1);
    refetch();
  };

  const handleOnDetail = (content: string) => {
    setContentDetail(content);
    onOpen();
  };

  return (
    <VStack w="full" spacing="6">
      <AdminHeader
        title="通話ログ 一覧"
        description="通話の文字起こしログ一覧です。"
      />
      <VStack w="full" px={10} pb={8} spacing="6">
        <HStack justify="space-between" w="full">
          <HStack spacing="3">
            <HStack spacing="2">
              <FormControl>
                <DatePicker
                  selected={new Date(startDateJst)}
                  selectsStart
                  maxDate={new Date(endDateJst)}
                  onChange={date => {
                    setStartDateJst(date?.toISOString() || startDateJst);
                  }}
                />
              </FormControl>
              <Text>〜</Text>
              <FormControl>
                <DatePicker
                  selected={new Date(endDateJst)}
                  selectsEnd
                  onChange={date =>
                    setEndDateJst(date?.toISOString() || endDateJst)
                  }
                  minDate={new Date(startDateJst)}
                  maxDate={new Date()}
                />
              </FormControl>
            </HStack>
            <Button
              colorScheme="gray"
              leftIcon={<MdSearch />}
              onClick={handleOnSearch}
            >
              検索
            </Button>
          </HStack>
          <HStack>
            <IconButton
              fontSize="4xl"
              aria-label="Prev"
              colorScheme="gray"
              icon={<MdChevronLeft />}
              isDisabled={currentPage === 1}
              isRound={true}
              onClick={() => prevAction()}
            />
            <IconButton
              fontSize="4xl"
              aria-label="Next"
              colorScheme="gray"
              icon={<MdChevronRight />}
              isDisabled={!hasMore}
              isRound={true}
              onClick={() => nextAction()}
            />
          </HStack>
        </HStack>
        <TableContainer
          overflowY="auto"
          w="full"
          h={`calc(100vh - ${headerHeight}px)`}
        >
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>担当者</Th>
                <Th>日時</Th>
                <Th>通話内容</Th>
                <Th>詳細</Th>
              </Tr>
            </Thead>
            <Tbody>
              {calls && calls.length > 0 ? (
                calls.map(item => (
                  <Tr key={item.call_id}>
                    <Td>{item.email ?? ""}</Td>
                    <Td>
                      {timestampToDayjs(item.last_updated_at_utc ?? "").format(
                        "YYYY-MM-DD"
                      )}
                    </Td>
                    <Td>
                      <Text
                        color={!item.transcription ? "gray.500" : undefined}
                        whiteSpace="pre-line"
                        wordBreak="break-word"
                        overflowWrap="break-word"
                        noOfLines={2}
                      >
                        {item.transcription
                          ? item.transcription
                          : "通話ログはありません。"}
                      </Text>
                    </Td>
                    <Td>
                      {item.transcription && (
                        <Button
                          w="100px"
                          onClick={() =>
                            handleOnDetail(item.transcription as string)
                          }
                        >
                          詳細表示
                        </Button>
                      )}
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={4}>
                    {isLoading ? (
                      <Spinner height={`calc(100vh - ${headerHeight}px)`} />
                    ) : (
                      <Center w="full" h="300px">
                        データがありません
                      </Center>
                    )}
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
        <DetailDrawer
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setContentDetail("");
          }}
          content={contentDetail}
        />
      </VStack>
    </VStack>
  );
};
