import { createContext, useContext } from "react";

import type {
  MainContextType,
  MainContextParamType
} from "@/contexts/mainContext/MainContextProvider";

type MainContextHookType = MainContextType & {
  setMainContext: (newObj: MainContextParamType) => void;
};

export const MainContext = createContext<MainContextHookType | null>(null);

export const useMainContext = () => {
  const context = useContext(MainContext);
  if (!context) {
    throw new Error("useZoomContext must be used within a ZoomContextType");
  }
  return context;
};
