import { HStack, Text, VStack } from "@chakra-ui/react";
import { Fragment } from "react";

import type { CallTranscriptionItem } from "@api/@types";
import type { FC } from "react";

import { Customer } from "@/components/Customer";
import { Operator } from "@/components/Operator";
import { Transcription } from "@/components/Transcription";
import { useFontSize } from "@/hooks/useFontSize";

type SpeakerBoxProps = {
  transcriptions: CallTranscriptionItem[];
};

export const SpeakerBox: FC<SpeakerBoxProps> = props => {
  const { transcriptions } = props;
  const userNameFontSize = useFontSize("sm");

  return (
    <VStack spacing={4}>
      {transcriptions.map((transcription, index) => (
        <Fragment key={index}>
          {(transcription.speaker === "customer" ||
            transcription.speaker === "unknown") && (
            <HStack align="start" justify="flex-start" w="full">
              <VStack spacing={1}>
                <Customer />
                <Text fontSize={userNameFontSize} whiteSpace="nowrap">
                  {transcription.speaker === "customer" ? "お相手" : "不明"}
                </Text>
              </VStack>
              <Transcription
                text={transcription.content}
                mr={20}
                bg="gray.50"
              />
            </HStack>
          )}
          {transcription.speaker === "operator" && (
            <HStack align="start" justify="flex-end" w="full">
              <Transcription
                text={transcription.content}
                ml={20}
                bg="pink.50"
              />
              <VStack spacing="1px">
                <Operator />
                <Text fontSize={userNameFontSize} whiteSpace="nowrap">
                  あなた
                </Text>
              </VStack>
            </HStack>
          )}
        </Fragment>
      ))}
    </VStack>
  );
};
