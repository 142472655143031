import { Avatar } from "@chakra-ui/react";
import { LiaUserAstronautSolid } from "react-icons/lia";

import type { AvatarProps } from "@chakra-ui/react";
export const Operator = (props: AvatarProps) => {
  return (
    <Avatar
      w={12}
      h={12}
      bg="pink.200"
      icon={<LiaUserAstronautSolid fontSize="3rem" />}
      {...props}
    />
  );
};
