import { useSessionStorage } from "react-use";

import ZoomContext from "./useZoomContext";

import type { ZoomLevel } from "./useZoomContext";
import type { FC } from "react";

type ZoomProviderProps = {
  children: React.ReactNode;
};

export const ZoomProvider: FC<ZoomProviderProps> = ({ children }) => {
  const [zoom, setZoom] = useSessionStorage<ZoomLevel>("zoom", "normal");

  return (
    <ZoomContext.Provider
      value={{
        zoom,
        setZoom
      }}
    >
      {children}
    </ZoomContext.Provider>
  );
};
