import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet, HelmetProvider } from "react-helmet-async";

import App from "./App.tsx";
import { AuthProvider } from "./contexts/auth/AuthProvider.tsx";
import { ZoomProvider } from "./contexts/zoom/ZoomProvider.tsx";
import { theme } from "./utils/chakraTheme";

import { MainContextProvider } from "@/contexts/mainContext/MainContextProvider";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <ZoomProvider>
          <MainContextProvider>
            <HelmetProvider>
              <Helmet>
                <link
                  href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;600;700&family=Inter:wght@400;500;600;700&display=swap"
                  rel="stylesheet"
                />
              </Helmet>
              <App />
            </HelmetProvider>
          </MainContextProvider>
        </ZoomProvider>
      </ChakraProvider>
    </AuthProvider>
  </React.StrictMode>
);
