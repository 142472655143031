import { apiClient, getBearerToken, getOperatorId } from "@/utils/apiClient";
import { notifyErrorLog } from "@/utils/notifyErrorLog.ts";
import { ResGetCalls } from "@api/@types";
import dayjs from "dayjs";

type Props = {
  // operatorId?: string | null;
  startDateJst?: string | null;
  endDateJst?: string | null;
  limit?: number;
  offset?: number;
};

const ExtendedISO8601Format = "YYYY-MM-DD";

export const getCalls = async (params: Props): Promise<ResGetCalls> => {
  const { startDateJst, endDateJst, limit, offset } = params;

  const bearerToken = await getBearerToken();

  const operatorId = await getOperatorId();

  let startDateJstDayjs;
  if (startDateJst) {
    startDateJstDayjs = dayjs.utc(startDateJst).format(ExtendedISO8601Format);
    console.log(startDateJstDayjs);
  } else {
    startDateJstDayjs = dayjs
      .utc()
      .add(-1, "day")
      .format(ExtendedISO8601Format);
  }
  let endDateJstDayjs;
  if (endDateJst) {
    endDateJstDayjs = dayjs.utc(endDateJst).format(ExtendedISO8601Format);
    console.log(endDateJstDayjs);
  } else {
    endDateJstDayjs = dayjs.utc().add(1, "day").format(ExtendedISO8601Format);
  }

  const newParams = {
    operator_id: operatorId,
    start_date_jst: startDateJstDayjs,
    end_date_jst: endDateJstDayjs,
    offset: offset || 0,
    limit: limit || 20
  };

  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };

  try {
    const res: ResGetCalls = await apiClient.admin.call.list.$get({
      query: newParams,
      config: {
        headers: headers
      }
    });

    return res;
  } catch (e) {
    notifyErrorLog(e);
    throw new Error("failed to get calls");
    console.error(e);
  }
};
