import {
  Button,
  Center,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdChevronRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import type { ResponseFAQContentItem } from "@api/@types";

import { ProposalProps } from "@/components/ProposalAccordion";
import { Spinner } from "@/components/Spinner";
import { useGetFrequencyRank } from "@/hooks/useGetFrequencyRank";
import { AdminHeader } from "@/layouts/AdminHeader";
import { timestampToDayjs } from "@/utils/dateUtils";
import { routing } from "@/utils/routing";

export const FAQRankingList = () => {
  const [faqList, setFAQList] = useState<ResponseFAQContentItem[]>([]);
  const navigate = useNavigate();
  const headerHeight = 268;

  const moveToFAQContentPage = (qaId: string) => {
    navigate(routing.admin.faq.content.genPath(qaId));
  };
  // FAQ頻出ランキング
  const { proposals: frequencyRanks, isLoading } = useGetFrequencyRank();

  useEffect(() => {
    if (!frequencyRanks) return;

    setFAQList(
      frequencyRanks.map((item: ProposalProps) => {
        return {
          id: item.id,
          question: item.title,
          answer: item.content,
          updated_at: item.updatedAt,
          memo: item.memo,
          priority: 0,
          url: item.href,
          ng_operation_note: item.ng_operation_note
        };
      })
    );
  }, [frequencyRanks]);

  return (
    <VStack w="full" spacing="6">
      <AdminHeader
        title="よく使う質問応答一覧"
        description="※最近一ヶ月間で使用頻度が高いと推定されているものから表示しています。"
      />
      <VStack w="full" px={10} pb={8} spacing="6">
        <TableContainer
          overflowY="auto"
          w="full"
          h={`calc(100vh - ${headerHeight}px)`}
        >
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>質問</Th>
                <Th>返答</Th>
                <Th px="1">最終更新日</Th>
                <Th>詳細</Th>
              </Tr>
            </Thead>
            <Tbody>
              {faqList && faqList.length > 0 ? (
                faqList.map((item, index) => (
                  <Tr key={item.question + index}>
                    <Td maxW="3xl">
                      <Text
                        whiteSpace="pre-line"
                        wordBreak="break-word"
                        overflowWrap="break-word"
                        noOfLines={2}
                      >
                        {item.question}
                      </Text>
                    </Td>
                    <Td maxW="3xl">
                      <Text
                        whiteSpace="pre-line"
                        wordBreak="break-word"
                        overflowWrap="break-word"
                        noOfLines={1}
                      >
                        {item.answer}
                      </Text>
                    </Td>
                    <Td w="160px" px="1">
                      <Text
                        textAlign={item.updated_at ? "left" : "center"}
                        whiteSpace="pre-line"
                        wordBreak="break-word"
                        overflowWrap="break-word"
                      >
                        {item.updated_at
                          ? timestampToDayjs(item.updated_at).format(
                              "YYYY-MM-DD"
                            )
                          : "-"}
                      </Text>
                    </Td>
                    <Td px="1">
                      <Button
                        w="100px"
                        aria-label={"detail"}
                        onClick={() => moveToFAQContentPage(item.id)}
                        rightIcon={<Icon as={MdChevronRight} w={7} h={7} />}
                        variant={"ghost"}
                      >
                        詳細
                      </Button>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={4}>
                    {isLoading ? (
                      <Spinner height={`calc(100vh - ${headerHeight}px)`} />
                    ) : (
                      <Center w="full" h="300px">
                        データがありません
                      </Center>
                    )}
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
    </VStack>
  );
};
