import { Text } from "@chakra-ui/react";

import type { TextProps } from "@chakra-ui/react";
import type { FC } from "react";

import { useFontSize } from "@/hooks/useFontSize";

type Props = {
  text: string;
} & TextProps;

function alignString(text: string): string {
  // remove double quotes
  if (text.startsWith('"')) {
    text = text.slice(1);
  }
  if (text.endsWith('"')) {
    text = text.slice(0, -1);
  }
  // 句点、疑問符、感嘆符の後に改行を入れる
  text = text.replace(/([。？！?!])/g, "$1\n");
  return text;
}

export const Transcription: FC<Props> = (props: Props) => {
  const { text, ...textProps } = props;
  const fontSize = useFontSize();

  const alignedText = alignString(text);

  return (
    <Text
      p={3}
      fontSize={fontSize}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      {...textProps}
    >
      {alignedText}
    </Text>
  );
};
