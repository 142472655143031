import { useEffect, useState } from "react";

import { ZOOM_TO_NUMBER, useZoomContext } from "@/contexts/zoom/useZoomContext";

const FONT_SIZES = ["xs", "sm", "md", "lg", "xl", "2xl"]; // コンポーネント側の型によって"lg"以上がない等の場合があるので注意

export const useFontSize = (initialFontSize: string = "md"): string => {
  const [fontSize, setFontSize] = useState(initialFontSize);
  const { zoom } = useZoomContext();

  useEffect(() => {
    const currentIndex = FONT_SIZES.indexOf(initialFontSize);
    const zoomOffset = ZOOM_TO_NUMBER[zoom];

    const maxIndex = FONT_SIZES.length - 1;
    const proposedIndex = currentIndex + zoomOffset;
    const newSizeIndex = Math.min(maxIndex, proposedIndex);

    setFontSize(FONT_SIZES[newSizeIndex]);
  }, [initialFontSize, zoom]);

  return fontSize;
};
