import { Circle, HStack, Icon, Text, Tooltip } from "@chakra-ui/react";
import { MdCall, MdCallEnd, MdMic, MdMicOff } from "react-icons/md";

import type { FC } from "react";

import { useMainContext } from "@/contexts/mainContext/useMainContext";

type PhoneProps = {
  isSpeaker: boolean;
};

export const Phone: FC<PhoneProps> = ({ isSpeaker }) => {
  const mainCtx = useMainContext();

  return (
    <HStack
      h="12"
      py="6"
      pr="2"
      pl="6"
      bg="gray.100"
      border="1px solid"
      borderColor="gray.300"
      borderTopLeftRadius="lg"
      borderBottomLeftRadius="lg"
      dropShadow="base"
    >
      <Text fontSize="2xl">{isSpeaker ? "通話中" : "停止中"}</Text>
      <Tooltip
        fontSize="md"
        hasArrow
        label={!isSpeaker ? "停止中です" : "通話中です"}
      >
        <Circle bg={isSpeaker ? "red.500" : "green.500"} size="9">
          <Icon as={isSpeaker ? MdCall : MdCallEnd} w="6" h="6" color="white" />
        </Circle>
      </Tooltip>
      <Tooltip
        fontSize="md"
        hasArrow
        label={!mainCtx.enableMicrophone ? "ミュート中です" : "マイクONです"}
      >
        <Circle bg={"gray.400"} size="9">
          <Icon
            as={mainCtx.enableMicrophone ? MdMic : MdMicOff}
            w="6"
            h="6"
            color="white"
          />
        </Circle>
      </Tooltip>
    </HStack>
  );
};
