import {
  Button,
  Center,
  FormControl,
  HStack,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { MdChevronLeft, MdChevronRight, MdSearch } from "react-icons/md";

import type { ResGetTagsWithoutFAQ } from "@api/@types";

import { getTagsWithoutFAQ } from "@/api/admin/Tags/list";
import { DetailDrawer } from "@/components/Admin/DetailDrawer";
import { DatePicker } from "@/components/Admin/RangeDatePicker/DatePicker";
import { Spinner } from "@/components/Spinner";
import { useCustomToast } from "@/hooks/useCustomToast";
import { AdminHeader } from "@/layouts/AdminHeader";
import { timestampToDayjs } from "@/utils/dateUtils";

const ExtendedISO8601Format = "YYYY-MM-DD";
export const TagList = () => {
  const [tagsWithoutFAQ, setTagsWithoutFAQ] =
    useState<ResGetTagsWithoutFAQ | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [contentDetail, setContentDetail] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const [startDateJst, setStartDateJst] = useState<string>(
    dayjs.utc().add(-1, "day").format(ExtendedISO8601Format)
  );
  const [endDateJst, setEndDateJst] = useState<string>(
    dayjs.utc().format(ExtendedISO8601Format)
  );

  const limit = 20;
  const headerHeight = 300;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLastPage, setIsLastPage] = useState<boolean>(
    !tagsWithoutFAQ?.has_next || false
  );

  const fetchData = async () => {
    setIsLoading(true);
    const response = await getTagsWithoutFAQ({
      startDateJst: startDateJst,
      endDateJst: endDateJst,
      limit: limit,
      offset: limit * (currentPage - 1)
    });

    setTagsWithoutFAQ(response);
    setIsLastPage(!response?.has_next || false);
    setIsLoading(false);
  };

  const prevAction = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextAction = async () => {
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const { showErrorToast } = useCustomToast();

  const handleOnSearch = () => {
    if (dayjs.utc(endDateJst).diff(dayjs.utc(startDateJst), "day") > 180) {
      showErrorToast("検索期間は180日以内に設定してください。");
      return;
    }
    setCurrentPage(1);
    fetchData();
  };

  const handleOnDetail = (content: string) => {
    setContentDetail(content);
    onOpen();
  };

  return (
    <VStack w="full" spacing="6">
      <AdminHeader
        title="検索0件キーワード 一覧"
        description="FAQの検出ができなかった検索履歴の表示"
      />
      <VStack w="full" px={10} pb={8} spacing="6">
        <HStack justify="space-between" w="full">
          <HStack spacing="3">
            <HStack spacing="2">
              <FormControl>
                <DatePicker
                  selected={new Date(startDateJst)}
                  selectsStart
                  maxDate={new Date(endDateJst)}
                  onChange={date => {
                    setStartDateJst(date?.toISOString() || startDateJst);
                  }}
                />
              </FormControl>
              <Text>〜</Text>
              <FormControl>
                <DatePicker
                  selected={new Date(endDateJst)}
                  selectsEnd
                  onChange={date =>
                    setEndDateJst(date?.toISOString() || endDateJst)
                  }
                  minDate={new Date(startDateJst)}
                  maxDate={new Date()}
                />
              </FormControl>
            </HStack>
            <Button
              colorScheme="gray"
              // isDisabled={
              //   dayjs.utc(endDateJst).diff(dayjs.utc(startDateJst), "day") < 0
              // }
              leftIcon={<MdSearch />}
              onClick={handleOnSearch}
            >
              検索
            </Button>
          </HStack>
          <HStack>
            <IconButton
              fontSize="4xl"
              aria-label="Prev"
              colorScheme="gray"
              icon={<MdChevronLeft />}
              isDisabled={currentPage === 1}
              isRound={true}
              onClick={() => prevAction()}
            />
            <IconButton
              fontSize="4xl"
              aria-label="Next"
              colorScheme="gray"
              icon={<MdChevronRight />}
              isDisabled={isLastPage}
              isRound={true}
              onClick={() => nextAction()}
            />
          </HStack>
        </HStack>
        <TableContainer
          overflowY="auto"
          w="full"
          h={`calc(100vh - ${headerHeight}px)`}
        >
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>キーワード</Th>
                <Th>日時</Th>
                <Th>通話内容</Th>
                <Th>詳細</Th>
              </Tr>
            </Thead>
            <Tbody>
              {tagsWithoutFAQ?.data && tagsWithoutFAQ.data.length > 0 ? (
                tagsWithoutFAQ.data.map((item, index) => (
                  <Tr key={item.tag + index}>
                    <Td>{item.tag}</Td>
                    <Td>
                      {timestampToDayjs(item.datetime_utc ?? "").format(
                        "YYYY-MM-DD HH:mm"
                      )}
                    </Td>
                    <Td>
                      <Text
                        whiteSpace="pre-line"
                        wordBreak="break-word"
                        overflowWrap="break-word"
                        noOfLines={2}
                      >
                        {item.transcription}
                      </Text>
                    </Td>
                    <Td>
                      {item.transcription && (
                        <Button
                          w="100px"
                          onClick={() => handleOnDetail(item.transcription)}
                        >
                          通話ログ
                        </Button>
                      )}
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={4}>
                    {isLoading ? (
                      <Spinner height={`calc(100vh - ${headerHeight}px)`} />
                    ) : (
                      <Center w="full" h="300px">
                        データがありません
                      </Center>
                    )}
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
        <DetailDrawer
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setContentDetail("");
          }}
          content={contentDetail}
        />
      </VStack>
    </VStack>
  );
};
