import {
  HStack,
  Heading as ChakraHeading,
  Circle,
  Icon,
  Text,
  Box
} from "@chakra-ui/react";

import type { StackProps } from "@chakra-ui/react";
import type { FC } from "react";
import type { IconType } from "react-icons";

import { useChakraSize } from "@/hooks/useChakraSize";
import { useFontSize } from "@/hooks/useFontSize";

type HomeHeadingProps = {
  title: string;
  icon: IconType | React.FC;
  children?: React.ReactNode;
} & StackProps;

export const HomeHeading: FC<HomeHeadingProps> = ({
  title,
  icon,
  children,
  ...rest
}) => {
  const fontSize = useFontSize();
  const circleSize = useChakraSize("9");
  const iconSize = useChakraSize("6");

  return (
    <HStack justify="start" w="full" {...rest}>
      <Circle bg="cyan.400" size={circleSize}>
        {typeof icon === "function" ? (
          <Box as={icon} w={iconSize} h={iconSize} color="white" /> // ChakraUIのアイコン
        ) : (
          <Icon as={icon} w={iconSize} h={iconSize} color="white" /> // 他のアイコンライブラリ
        )}
      </Circle>
      <ChakraHeading as="h2" w="full" size={fontSize}>
        <HStack justify="space-between">
          <Text as="span">{title}</Text>
          {children}
        </HStack>
      </ChakraHeading>
    </HStack>
  );
};
