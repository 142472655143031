import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  List,
  ListItem,
  Text
} from "@chakra-ui/react";
import { FC, memo } from "react";

type DetailDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  content?: string;
};

export const DetailDrawer: FC<DetailDrawerProps> = memo(
  ({ isOpen, onClose, content }) => {
    const formattedContent = content?.replace(
      /\[(customer|operator|unknown):\]/g,
      "\n[$1:]"
    );

    return (
      <Drawer isOpen={isOpen} onClose={onClose} size="lg">
        <DrawerContent overflow="auto" roundedLeft="xl">
          <DrawerCloseButton />
          <DrawerHeader textAlign="center">通話ログ</DrawerHeader>
          <DrawerBody>
            {formattedContent && (
              <List w="full" spacing={1}>
                {formattedContent.split("\n").map((line, index) => (
                  <ListItem key={index}>
                    <Text
                      whiteSpace="pre-line"
                      wordBreak="break-word"
                      overflowWrap="break-word"
                    >
                      {line}
                    </Text>
                  </ListItem>
                ))}
              </List>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }
);

DetailDrawer.displayName = "DetailDrawer";
