import type { AggregateRelatedFaqRequest } from "./aspida/@types";

import { apiClient, getBearerToken } from "@/utils/apiClient";

export type PostAggregateRelatedFaqProps = Omit<AggregateRelatedFaqRequest, 'operator_id'>;

export const postAggregateRelatedFaq = async ({ call_id }: PostAggregateRelatedFaqProps) => {

  const bearerToken = await getBearerToken();
  const [, payload] = bearerToken.split(".");
  const { sub } = JSON.parse(atob(payload));
  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };

  const params: AggregateRelatedFaqRequest = {
    call_id: call_id,
    operator_id: sub
  }

  try {
    const res = await apiClient.faq.aggregate_related_faq.$post({
        body: params,
      config: {
        headers: headers
      }
    });
    console.log(res);
    return res;
  } catch (e) {
    console.log("frequency_rank api error");
    // TODO: エラー通知
    return false;
  }
};
