import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure
} from "@chakra-ui/react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import type { FC } from "react";

import { faqCreateCsv } from "@/api/faqCreateCsv";
import { QACsvFileUploadForm } from "@/components/Admin/QACsvFileUploadForm"; // Import the new component
import { useCustomToast } from "@/hooks/useCustomToast";

type FormData = {
  file: File | null;
};

type QACsvUploadDialogProps = {
  onUploadSuccess: () => void;
};

export const QACsvUploadDialog: FC<QACsvUploadDialogProps> = ({
  onUploadSuccess
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showSuccessToast, showErrorToast } = useCustomToast();
  const [isUploading, setIsUploading] = useState(false); // アップロード中の状態を管理

  const methods = useForm<FormData>({
    defaultValues: {
      file: null
    }
  });

  const { handleSubmit } = methods;

  const handleFormSubmit = async (data: FormData) => {
    if (data.file) {
      setIsUploading(true);
      try {
        const result = await faqCreateCsv(data.file);
        if (result) {
          showSuccessToast("ファイルが正常にアップロードされました");
          onUploadSuccess();
          onClose();
        } else {
          showErrorToast("ファイルのアップロードに失敗しました");
        }
      } catch (error) {
        showErrorToast("ファイルのアップロード中にエラーが発生しました");
        console.error("Error uploading file:", error);
      } finally {
        setIsUploading(false);
      }
    } else {
      showErrorToast("ファイルが選択されていません");
    }
  };

  return (
    <>
      <Button onClick={onOpen}>CSVアップロード</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minW={"40%"}>
          <ModalHeader>CSVファイルのアップロード</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormProvider {...methods}>
              <form
                noValidate
                autoComplete="off"
                className="w-full"
                onSubmit={handleSubmit(handleFormSubmit)}
              >
                <QACsvFileUploadForm />
              </form>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <HStack className="mt-4">
              {isUploading ? (
                <Spinner />
              ) : (
                <>
                  <Button onClick={onClose} variant="outline">
                    キャンセル
                  </Button>
                  <Button
                    onClick={handleSubmit(handleFormSubmit)}
                    type="submit"
                  >
                    アップロード
                  </Button>
                </>
              )}
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
