import {
  Button,
  Center,
  FormControl,
  HStack,
  Icon,
  IconButton,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { ChangeEvent, useEffect, useState } from "react";
import {
  MdAddCircleOutline,
  MdChevronLeft,
  MdChevronRight,
  MdFilterList
} from "react-icons/md";
import { useNavigate } from "react-router-dom";

import type { ResponseFAQContentItem } from "@api/@types";

import { getFAQList } from "@/api/admin/FAQ/list.ts";
import { QACsvUploadDialog } from "@/components/Admin/QACsvFileUploadDialog";
import { DatePicker } from "@/components/Admin/RangeDatePicker/DatePicker";
import { Spinner } from "@/components/Spinner";
import { AdminHeader } from "@/layouts/AdminHeader";
import { timestampToDayjs } from "@/utils/dateUtils";
import { routing } from "@/utils/routing";

const ExtendedISO8601Format = "YYYY-MM-DD";
export const FAQList = () => {
  const [faqList, setFAQList] = useState<ResponseFAQContentItem[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentList, setCurrentList] = useState<ResponseFAQContentItem[]>([]);
  const [lastPage, setLastPage] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const currentDateJst = dayjs.utc().format(ExtendedISO8601Format);
  const [startDateJst, setStartDateJst] = useState<string | null>(null);
  const [endDateJst, setEndDateJst] = useState<string | null>(null);

  const [searchWord, setSearchWord] = useState<string>("");
  const [triggerRefetch, setTriggerRefetch] = useState<boolean>(false);
  const navigate = useNavigate();
  const headerHeight = 284;

  const PAGE_SIZE = 20;

  useEffect(() => {
    const start = (currentPage - 1) * PAGE_SIZE;
    const end = start + PAGE_SIZE;
    setCurrentList(faqList.slice(start, end));
    console.log(faqList);
  }, [currentPage, faqList]);

  const fetchFAQList = async () => {
    const res = await getFAQList({
      offset: (currentPage - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
      updated_at_start: startDateJst,
      updated_at_end: endDateJst,
      search_word: searchWord
    });
    console.log(res);
    setFAQList(prev => [...prev, ...res.items]);

    return res;
  };

  const handleCsvUploadSuccess = async () => {
    // TODO:react-queryを使ってリロードするように修正する
    window.location.reload();
  };

  const moveToFAQContentPage = (qaId: string) => {
    navigate(routing.admin.faq.content.genPath(qaId));
  };

  const prevAction = () => {
    setCurrentPage(currentPage - 1);
  };
  const nextAction = async () => {
    console.log([currentPage]);

    setCurrentPage(currentPage + 1);
  };

  let didInitialize = false;
  useEffect(() => {
    if (!didInitialize) {
      (async () => {
        console.log("initialize");
        const res = await fetchFAQList();
        if (res.isLast) {
          setLastPage(currentPage);
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      didInitialize = true;
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (
        triggerRefetch ||
        (currentPage > 1 &&
          faqList.length < currentPage * PAGE_SIZE &&
          currentPage !== lastPage)
      ) {
        setIsLoading(true);
        const res = await fetchFAQList();

        if (res.isLast) {
          setLastPage(currentPage);
        }
        setTriggerRefetch(false);
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, triggerRefetch]);

  const handleChangeSearchWord = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  const handleOnSearch = () => {
    setLastPage(null);
    setFAQList([]);
    setCurrentPage(1);
    setTriggerRefetch(true);
  };

  return (
    <VStack w="full" spacing="6">
      <AdminHeader title="質問応答 一覧" />
      <VStack w="full" px={10} pb={8} spacing="6">
        <HStack justify="space-between" w="full">
          <HStack justify="start" w="full">
            <HStack spacing="2">
              <HStack spacing="2">
                <FormControl>
                  <DatePicker
                    selected={startDateJst ? new Date(startDateJst) : null}
                    selectsStart
                    maxDate={
                      endDateJst
                        ? new Date(endDateJst)
                        : new Date(currentDateJst)
                    }
                    onChange={date => {
                      setStartDateJst(date?.toISOString() || null);
                    }}
                    placeholderText="日付を選択"
                  />
                </FormControl>
                <Text>〜</Text>
                <FormControl>
                  <DatePicker
                    selected={endDateJst ? new Date(endDateJst) : null}
                    selectsEnd
                    onChange={date => {
                      setEndDateJst(date?.toISOString() || null);
                    }}
                    minDate={startDateJst ? new Date(startDateJst) : null}
                    maxDate={new Date()}
                    placeholderText="日付を選択"
                  />
                </FormControl>
              </HStack>
              <FormControl>
                <Input
                  onChange={handleChangeSearchWord}
                  placeholder="キーワードを入力"
                />
              </FormControl>
            </HStack>
            <Button
              colorScheme="gray"
              isDisabled={!startDateJst && !endDateJst && !searchWord}
              leftIcon={<MdFilterList />}
              onClick={handleOnSearch}
            >
              絞り込み
            </Button>
          </HStack>
          <HStack>
            <HStack spacing="4">
              <QACsvUploadDialog onUploadSuccess={handleCsvUploadSuccess} />
              <Button
                px="4"
                colorScheme="teal"
                leftIcon={<MdAddCircleOutline size="24px" />}
                onClick={() => navigate(routing.admin.faq.new.path)}
              >
                新規追加
              </Button>
            </HStack>
            <HStack>
              <IconButton
                fontSize="4xl"
                aria-label="Prev"
                colorScheme="gray"
                icon={<MdChevronLeft />}
                isDisabled={currentPage === 1}
                isRound={true}
                onClick={() => prevAction()}
              />
              <IconButton
                fontSize="4xl"
                aria-label="Next"
                colorScheme="gray"
                icon={<MdChevronRight />}
                isDisabled={lastPage === currentPage}
                isRound={true}
                onClick={() => nextAction()}
              />
            </HStack>
          </HStack>
        </HStack>
        <TableContainer
          overflowY="auto"
          w="full"
          h={`calc(100vh - ${headerHeight}px)`}
        >
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>質問</Th>
                <Th>返答</Th>
                <Th px="1">最終更新日</Th>
                <Th>詳細</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentList && currentList.length > 0 ? (
                currentList.map((item, index) => (
                  <Tr key={item.question + index}>
                    <Td maxW="3xl">
                      <Text
                        whiteSpace="pre-line"
                        wordBreak="break-word"
                        overflowWrap="break-word"
                        noOfLines={2}
                      >
                        {item.question}
                      </Text>
                    </Td>
                    <Td maxW="3xl">
                      <Text
                        whiteSpace="pre-line"
                        wordBreak="break-word"
                        overflowWrap="break-word"
                        noOfLines={1}
                      >
                        {item.answer}
                      </Text>
                    </Td>
                    <Td w="160px" px="1">
                      <Text
                        textAlign={item.updated_at ? "left" : "center"}
                        whiteSpace="pre-line"
                        wordBreak="break-word"
                        overflowWrap="break-word"
                      >
                        {item.updated_at
                          ? timestampToDayjs(item.updated_at).format(
                              "YYYY-MM-DD HH:mm"
                            )
                          : "-"}
                      </Text>
                    </Td>
                    <Td px="1">
                      <Button
                        w="100px"
                        aria-label={"detail"}
                        onClick={() => moveToFAQContentPage(item.id)}
                        rightIcon={<Icon as={MdChevronRight} w={7} h={7} />}
                        variant={"ghost"}
                      >
                        詳細
                      </Button>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={4}>
                    {isLoading ? (
                      <Spinner height={`calc(100vh - ${headerHeight}px)`} />
                    ) : (
                      <Center w="full" h="300px">
                        データがありません
                      </Center>
                    )}
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
    </VStack>
  );
};
