import {
  Box,
  BoxProps,
  Circle,
  HStack,
  Heading,
  Text,
  VStack
} from "@chakra-ui/react";
import { memo, type FC } from "react";
import {
  MdPhone,
  MdQuestionAnswer,
  MdSavedSearch,
  MdSummarize
} from "react-icons/md";
import { Link, useMatch } from "react-router-dom";

import { AdminLogo } from "@/assets/Icons";
import { routing } from "@/utils/routing";

type CustomNavLinkProps = {
  to: string;
  children: React.ReactNode;
} & BoxProps;

const CustomNavLink: FC<CustomNavLinkProps> = ({ to, children, ...rest }) => {
  const match = useMatch(`${to}*`); // 前方一致でマッチするかどうか

  return (
    <Box
      as={Link}
      w="full"
      px={3}
      py={1.5}
      color="black"
      bg={match ? "white" : "transparent"}
      borderRadius="8"
      to={to}
      {...rest}
    >
      <HStack justify="space-around" w="full">
        {children}
        {match && <Circle bg="green.500" size="4" />}
      </HStack>
    </Box>
  );
};

export const AdminSidebar: FC = memo(function AdminSidebar() {
  return (
    <Box pos="fixed" minW="320px" minH="100vh" px={4} py={8} bg="cyan.100">
      <VStack w="full" spacing={8}>
        <Heading as="h1" w="full" fontSize="3xl">
          <AdminLogo w="290px" h="50px" />
        </Heading>
        <VStack w="full" fontSize="2xl" spacing={5}>
          <VStack w="full" spacing="1">
            <CustomNavLink to={routing.admin.faq.list.path}>
              <HStack w="full">
                <MdQuestionAnswer />
                <Text>質問応答</Text>
              </HStack>
            </CustomNavLink>
            <CustomNavLink
              to={routing.admin.faqRanking.list.path}
              w="258px"
              ml="8"
            >
              <HStack w="full">
                <Text fontSize="lg">よく使う質問応答</Text>
              </HStack>
            </CustomNavLink>
          </VStack>
          <CustomNavLink to={routing.admin.summary.list.path}>
            <HStack w="full">
              <MdSummarize />
              <Text>通話要約</Text>
            </HStack>
          </CustomNavLink>
          <CustomNavLink to={routing.admin.tags.list.path}>
            <HStack w="full">
              <MdSavedSearch />
              <Text>検索0件キーワード</Text>
            </HStack>
          </CustomNavLink>
          <CustomNavLink to={routing.admin.call.list.path}>
            <HStack w="full">
              <MdPhone />
              <Text>通話ログ</Text>
            </HStack>
          </CustomNavLink>
        </VStack>
      </VStack>
    </Box>
  );
});
