import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Container, Highlight, Link } from "@chakra-ui/react";

import type { ProposalProps } from "..";
import type { FC } from "react";

import { useFontSize } from "@/hooks/useFontSize";

type ProposalContentProps = Pick<ProposalProps, "query" | "content" | "href">;

export const ProposalContent: FC<ProposalContentProps> = props => {
  const fontSize = useFontSize();

  const { query, content, href } = props;
  return (
    <Container
      // TODO: float使いたくなかったが大掛かりになるため一旦こちらで対応
      maxW="full"
      my="2"
      p={2}
      fontSize={fontSize}
      bg="white"
      borderWidth="1px"
      borderColor="gray.400"
      borderRadius="base"
      _after={{
        content: '""',
        display: "block",
        clear: "both"
      }}
      whiteSpace="pre-wrap"
    >
      <Highlight
        query={query ?? []}
        styles={{
          py: "0",
          bg: "red.100",
          borderRadius: "base"
        }}
      >
        {/*<div*/}
        {/*  dangerouslySetInnerHTML={{*/}
        {/*    __html: content*/}
        {/*  }}*/}
        {/*/>*/}
        {content}
      </Highlight>

      {href && (
        <Link
          h="auto"
          color="blue.500"
          float="right"
          href={href}
          isExternal
          variant="none"
        >
          詳細を表示
          <ExternalLinkIcon h="6" mx="2px" verticalAlign="-6px" w="6" />
        </Link>
      )}
    </Container>
  );
};
