import type { FAQSummarizeFaqRequest } from "./aspida/@types";

import { apiClient, getBearerToken } from "@/utils/apiClient";

export type PostSummarizeFaqProps = FAQSummarizeFaqRequest;

export const postSummarizeFaq = async ({ faq_id }: PostSummarizeFaqProps) => {

  const bearerToken = await getBearerToken();
  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };

  const params: FAQSummarizeFaqRequest = {
    faq_id: faq_id
  }

  try {
    const res = await apiClient.faq.summarize_faq.$post({
        body: params,
      config: {
        headers: headers
      }
    });
    console.log(res);
    return res;
  } catch (e) {
    console.log("summarize api error");
    // TODO: エラー通知
    return false;
  }
};
