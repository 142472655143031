import { PostSummarizeFaqProps, postSummarizeFaq } from "@/api/summarizeFaq";

export const usePostSummarizeFaq = () => {

  const asyncPostData = async ({ faq_id }: Partial<PostSummarizeFaqProps>) => {
    if(!faq_id) {
      console.error('faq_id are required'); 
      return;
    }
    try {
      const res = await postSummarizeFaq({faq_id});
      console.log(res);
    } catch (error) {
      console.error("Error posting summarize FAQ:", error);
    }
  };

  return { asyncPostData };
};
