export const routing = {
  home: {
    path: "/"
  },
  login: {
    path: "/login"
  },
  feedback: {
    path: "/feedback"
  },
  admin: {
    faq: {
      list: {
        path: "/admin/faq/"
      },
      new: {
        path: "/admin/faq/new"
      },
      content: {
        path: "/admin/faq/:qaId",
        genPath: (qaId: string) => `/admin/faq/${qaId}`
      }
    },
    faqRanking: {
      list: {
        path: "/admin/faq-rank/"
      }
    },
    summary: {
      list: {
        path: "/admin/summary/"
      },
      new: {
        path: "/admin/summary/new"
      },
      content: {
        path: "/admin/summary/:pageId",
        genPath: (pageId: string) => `/admin/summary/${pageId}`
      }
    },
    tags: {
      list: {
        path: "/admin/tags/"
      }
    },
    call: {
      list: {
        path: "/admin/call/list/"
      }
    }
  }
};
