import { Box } from "@chakra-ui/react";
import { memo } from "react";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";

import { AdminSidebar } from "@/layouts/AdminSidebar";

export const AdminLayout: React.FC = memo(function AdminLayout() {
  return (
    <>
      <Helmet>
        <title>DialMate管理</title>
      </Helmet>
      <Box>
        <AdminSidebar />
        <Box pt="188px" pl="320px">
          <Outlet />
        </Box>
      </Box>
    </>
  );
});
