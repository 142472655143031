import { PostAggregateRelatedFaqProps, postAggregateRelatedFaq } from "@/api/aggregateRelatedFaq";

export const usePostAggregateRelatedFaq = () => {

  const postData = async ({ call_id }: Partial<PostAggregateRelatedFaqProps>) => {
    if(!call_id) {
      console.error('call_id are required'); 
      return;
    }
    try {
      const res = await postAggregateRelatedFaq({call_id});
      console.log(res);
    } catch (error) {
      console.error("Error posting aggregate related FAQ:", error);
    }
  };

  return { postData };
};
