import { Box, Accordion as ChakraAccordion } from "@chakra-ui/react";

import { ProposalContent } from "./ProposalContent";
import { ProposalNgAlert } from "./ProposalNgAlert";

import type { FC } from "react";

import { ProposalAccordionItem } from "@/components/ProposalAccordion/ProposalAccordionItem";
import { ProposalMemoArea } from "@/components/ProposalAccordion/ProposalMemoArea";
import { useMainContext } from "@/contexts/mainContext/useMainContext.ts";

export type ProposalProps = {
  id: string;
  title: string;
  content: string;
  memo: string;
  href?: string | null;
  priority: number;
  updatedAt: string | null;
  tags?: Record<"name" | "colorScheme", string>[];
  breadcrumbs?: string[];
  isSummary?: boolean; //旧仕様の名残り。昔はfaqの種類にSummaryタイプとQAタイプの2パターンがあった。混乱するので近々改修予定
  query?: string[];
  ng_operation_note?: string | null;
  summaryContent?: string | null;
};

type ProposalAccordionProps = {
  qalist: ProposalProps[];
  // fetchQA: () => void;
};

export const ProposalAccordion: FC<ProposalAccordionProps> = ({ qalist }) => {
  console.log("qalist", qalist);
  // const homeCtx = useHomeContext();

  // const [qalist, setQA] = useState<ProposalProps[]>([]);
  const mainCtx = useMainContext();

  const getBgColor = (priority: number) => {
    console.log("priority", priority);
    if (priority < 3) {
      return "pink.50";
    } else if (priority >= 3 && priority < 7) {
      return "pink.100";
    } else if (priority >= 7) {
      return "pink.200";
    } else {
      return "pink.50"; // default
    }
  };

  // const convertBrTagsToNewline = (inputString: string) => {
  //   return inputString.replace(/<br\s*\/?>/gi, "\n");
  // };

  // const fetchQA = async () => {
  //   const question = homeCtx.selectedQuestionText || "";
  //   const callId = mainCtx.callId;
  //   if (question.length == 0 || !callId) {
  //     return;
  //   }
  //   const body = await postSearchQA({ callId, question });
  //   if (Array.isArray(body)) {
  //     const newList: ProposalProps[] = [];
  //     body.forEach(data => {
  //       const tags = data.tags.map((tagName: string) => {
  //         return { name: tagName };
  //       });

  //       newList.push({
  //         id: data.id,
  //         title: data.question ?? data.summary ?? "",
  //         content: convertBrTagsToNewline(data.full_content ?? ""),
  //         tags: tags,
  //         breadcrumbs: data.breadcrumbs,
  //         href: data.url,
  //         query: data.highlights,
  //         isSummary: data.summary !== null
  //       });
  //     });
  //     setQA(newList);
  //   }
  // };

  // useEffect(() => {
  //   const requesting = async () => {
  //     await fetchQA();
  //   };
  //   requesting();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [homeCtx.selectedQuestionText]);

  return (
    <ChakraAccordion allowMultiple defaultIndex={[0]}>
      {qalist.map((item, index) => (
        <Box
          key={item.id}
          mb={index === qalist.length - 1 ? 0 : 2}
          p="2"
          bg={getBgColor(item.priority)}
          borderRadius="base"
        >
          <ProposalAccordionItem
            isSummary={item.isSummary}
            title={item.title}
            updatedAt={item.updatedAt}
            qalistIndex={index}
            summaryContent={item.summaryContent}
          >
            {item.ng_operation_note && (
              <ProposalNgAlert ng_operation_note={item.ng_operation_note} />
            )}
            <ProposalContent
              content={item.content}
              href={item.href}
              query={item.query}
            />
            {item.memo.length > 0 && <ProposalMemoArea memo={item.memo} />}
          </ProposalAccordionItem>
        </Box>
      ))}
      {mainCtx.callId && qalist.length === 0 && (
        <Box color="gray.500">データが見つかりませんでした。</Box>
      )}
    </ChakraAccordion>
  );
};
