import { HStack, Heading, IconButton, Text, VStack } from "@chakra-ui/react";
import { memo } from "react";
import { MdChevronLeft } from "react-icons/md";

import { AdminMenu, Breadcrumbs } from "@/components/Admin/";

type AdminHeaderProps = {
  title: string;
  description?: string;
  onReturnClick?: () => void;
} & React.ComponentProps<typeof VStack>;

export const AdminHeader: React.FC<AdminHeaderProps> = memo(
  function AdminHeader(props) {
    const { title, description, onReturnClick, ...rest } = props;

    return (
      <VStack
        pos="fixed"
        zIndex="10"
        top="0"
        left="322px"
        w="full"
        px={10}
        pt={8}
        pb={3}
        bg="white"
        spacing={6}
        {...rest}
      >
        <HStack align="center" justify="space-between" w="full">
          <Breadcrumbs />
          <AdminMenu />
        </HStack>
        <HStack align="center" justify="flex-start" w="full" spacing={6}>
          {onReturnClick && (
            <IconButton
              w="48px"
              minW="unset"
              h="48px"
              fontSize="48px"
              aria-label="Back"
              colorScheme="gray"
              icon={<MdChevronLeft />}
              isRound={true}
              onClick={onReturnClick}
            />
          )}
          <VStack>
            <Heading as="h2" w="full" fontSize="4xl">
              {title}
            </Heading>
            {description && (
              <Text align="left" w="full" color="gray.500">
                {description}
              </Text>
            )}
          </VStack>
        </HStack>
      </VStack>
    );
  }
);
