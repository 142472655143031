import { DragHandleIcon, Icon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { FC, useEffect, useState } from "react";
import { MdChevronRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { bulkUpdateSummaryTarget } from "@/api/admin/Summary/bulkUpdate";
import { ResponseGetSummaryTarget } from "@/api/aspida/@types";
import { Spinner } from "@/components/Spinner";
import { useCustomToast } from "@/hooks/useCustomToast";
import { timestampToDayjs } from "@/utils/dateUtils";
import { routing } from "@/utils/routing";

type SortableItemProps = {
  row: ResponseGetSummaryTarget;
  index: number;
};

const SortableItem: React.FC<SortableItemProps> = ({ row, index }) => {
  const navigate = useNavigate();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: row.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const moveToContentPage = (pageId: string) => {
    navigate(routing.admin.summary.content.genPath(pageId));
  };

  return (
    <Tr ref={setNodeRef} style={style}>
      <Td w="100" {...attributes} {...listeners} cursor="grab">
        <Flex align="center" gap="2" minW="max-content">
          <DragHandleIcon w={4} h={4} />
          {index + 1}
        </Flex>
      </Td>
      <Td>
        <Text
          whiteSpace="pre-line"
          wordBreak="break-word"
          overflowWrap="break-word"
          noOfLines={2}
        >
          {row.name}
        </Text>
      </Td>
      <Td w="200px">
        <Text>
          {timestampToDayjs(row.updated_at ?? "").format("YYYY-MM-DD HH:mm")}
        </Text>
      </Td>
      <Td w="148px">
        <Button
          w="100px"
          aria-label={"detail"}
          onClick={() => moveToContentPage(row.id)}
          rightIcon={<Icon as={MdChevronRight} w={7} h={7} />}
          variant={"ghost"}
        >
          詳細
        </Button>
      </Td>
    </Tr>
  );
};

type SummaryTableProps = {
  summaries: ResponseGetSummaryTarget[];
  isLoading: boolean;
};

export const SummariesTable: FC<SummaryTableProps> = ({
  summaries,
  isLoading
}) => {
  const [rows, setRows] = useState<ResponseGetSummaryTarget[]>([]);
  const { showSuccessToast, showErrorToast } = useCustomToast();

  useEffect(() => {
    setRows(summaries);
  }, [summaries]);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const headerHeight = 284;

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setRows(items => {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over.id);
        const rows = arrayMove(items, oldIndex, newIndex);

        (async () => {
          try {
            const params = rows.map((v, i) => {
              return {
                id: v.id,
                name: v.name,
                details: v.details,
                importanceLevel: rows.length - i
              };
            });
            await bulkUpdateSummaryTarget({
              summaryTargets: params
            });
            showSuccessToast("通話要約の重要度順が更新されました。");
          } catch (e) {
            console.error("Error in bulk update for summary targets:", e);
            showErrorToast("通話要約の重要度順の更新に失敗しました。");
          }
        })();

        return rows;
      });
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <TableContainer
        overflowY="auto"
        w="full"
        h={`calc(100vh - ${headerHeight}px)`}
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th w="88px">No.</Th>
              <Th>要約</Th>
              <Th>最終更新日</Th>
              <Th>詳細</Th>
            </Tr>
          </Thead>
          {rows.length !== 0 ? (
            <SortableContext
              items={rows}
              strategy={verticalListSortingStrategy}
            >
              <Tbody>
                {rows.map((row, index) => (
                  <SortableItem key={row.id} row={row} index={index} />
                ))}
              </Tbody>
            </SortableContext>
          ) : (
            <Tbody>
              <Tr>
                <Td colSpan={4}>
                  {isLoading ? (
                    <Spinner height={`calc(100vh - ${headerHeight}px)`} />
                  ) : (
                    <Center w="full" h="300px">
                      データがありません
                    </Center>
                  )}
                </Td>
              </Tr>
            </Tbody>
          )}
        </Table>
      </TableContainer>
    </DndContext>
  );
};
