import { Avatar } from "@chakra-ui/react";
import { LiaUserAltSolid } from "react-icons/lia";

import type { AvatarProps } from "@chakra-ui/react";

export const Customer = (props: AvatarProps) => {
  return (
    <Avatar
      w={12}
      h={12}
      icon={<LiaUserAltSolid fontSize="3rem" />}
      {...props}
    />
  );
};
