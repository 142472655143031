import { VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import type { SummaryFormData } from "@/components/Admin";

import { upsertSummaryTarget } from "@/api/admin/Summary/upsert.ts";
import { SummaryTargetForm } from "@/components/Admin";
import { useCustomToast } from "@/hooks/useCustomToast.tsx";
import { AdminHeader } from "@/layouts/AdminHeader";
import { routing } from "@/utils/routing";

export const NewSummarize = () => {
  const navigate = useNavigate();
  const { showSuccessToast } = useCustomToast();

  const submitAction = async (data: SummaryFormData) => {
    console.log(data);
    console.log("submitAction");

    const newId = `${crypto.randomUUID()}`;

    const status = await upsertSummaryTarget({
      id: newId,
      name: data.name,
      details: data.details,
      importanceLevel: 1 // TODO: check update or not
    });

    if (status) {
      navigate(routing.admin.summary.list.path);
      showSuccessToast("新しい要約を登録しました。");
    }

    console.log(status);
  };
  const backAction = () => {
    console.log("backAction");
    navigate(routing.admin.summary.list.path);
  };
  return (
    <VStack w="full" spacing="6">
      <AdminHeader title="通話要約 新規追加" />
      <SummaryTargetForm
        submitAction={submitAction}
        backAction={backAction}
        summaryData={[
          {
            id: "name",
            label: "項目名",
            placeholder: "例: お客様の名前",
            value: ""
          },
          {
            id: "details",
            label: "要約のシステム指示",
            placeholder: "例: 会話内容から年齢を推定してください",
            value: ""
          },
          {
            id: "importanceLevel",
            label: "重要度",
            value: ""
          }
        ]}
      />
    </VStack>
  );
};
