import { useEffect, useState } from "react";

import { getFrequencyRank } from "@/api/frequencyRank";
import { ProposalProps } from "@/components/ProposalAccordion";
import { convertBrTagsToNewline } from "@/utils/convertUtils";

type UseGetFrequencyRank = () => {
  proposals: ProposalProps[];
  isLoading: boolean;
};

export const useGetFrequencyRank: UseGetFrequencyRank = () => {
  const [proposals, setProposals] = useState<ProposalProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = await getFrequencyRank();
      if (res) {
        const newList: ProposalProps[] = res.data.map(item => {
          return {
            id: item.id,
            title: item.question ?? "",
            content: convertBrTagsToNewline(item.answer ?? ""),
            href: item.url,
            memo: convertBrTagsToNewline(item.memo ?? ""),
            priority: 0, // ランキングにはpriorityが不要な為固定値で対応
            updatedAt: item.updated_at,
            summaryContent: item.summary
          };
        });
        setProposals(newList);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return { proposals, isLoading };
};
