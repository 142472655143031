import { apiClient, getBearerToken } from "@/utils/apiClient";
// NOTE: 回答パターンごとに型を追加していく。
type Answer = {
  rating: string;
  description: string;
};

type FeedbackResultParams = {
  callId: string;
  question: string;
  answer: Answer;
};

export const postFeedbackResult = async (params: FeedbackResultParams) => {
  const { callId, question, answer } = params;

  const newParams = {
    call_id: callId,
    question: question,
    answer: JSON.stringify(answer)
  };
  const bearerToken = await getBearerToken();
  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };

  try {
    const res = await apiClient.feedback_result.$post({
      body: newParams,
      config: {
        headers: headers
      }
    });
    console.log(res);
    return true;
  } catch (e) {
    console.log("feed back api error");
    // TODO: エラー通知
    return false;
  }
};
