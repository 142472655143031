import { createContext, useContext } from "react";

export type ZoomLevel = "normal" | "middle" | "large";

export const ZOOM_TO_NUMBER: Record<ZoomLevel, number> = {
  normal: 0,
  middle: 1,
  large: 2
};

type ZoomContextType = {
  zoom: ZoomLevel;
  setZoom: (zoom: ZoomLevel) => void;
};

const ZoomContext = createContext<ZoomContextType | null>(null);

export const useZoomContext = () => {
  const context = useContext(ZoomContext);
  if (!context) {
    throw new Error("useZoomContext must be used within a ZoomContextType");
  }
  return context;
};

export default ZoomContext;
