import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { useAuthContext } from "@/contexts/auth/useAuthContext";
import { AdminLayout } from "@/layouts/AdminLayout";
import { CallList } from "@/pages/admin/Calls";
import { FAQList } from "@/pages/admin/FAQ";
import { FAQContent } from "@/pages/admin/FAQ/[id].tsx";
import { NewFAQ } from "@/pages/admin/FAQ/new.tsx";
import { FAQRankingList } from "@/pages/admin/FAQRanking";
import { SummarizeList } from "@/pages/admin/Summarize";
import { SummarizeContent } from "@/pages/admin/Summarize/[id]";
import { NewSummarize } from "@/pages/admin/Summarize/new.tsx";
import { TagList } from "@/pages/admin/TagsWithoutFAQ";
import { Feedback } from "@/pages/Feedback";
import { Home } from "@/pages/Home";
import { Login } from "@/pages/Login";
import { routing } from "@/utils/routing";

const AppRoutes = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthContext();
  const isLoginPage = location.pathname === "/login";

  useEffect(() => {
    // ログインしていない状態でログインページ以外にアクセスした場合はログインページにリダイレクト
    if (!isAuthenticated && !isLoginPage) {
      navigate("/login");
    } else if (isAuthenticated && isLoginPage) {
      // ログインしている状態でログインページにアクセスした場合はホーム画面にリダイレクト
      navigate("/");
    }
  }, [isAuthenticated, isLoginPage, navigate]);

  return (
    <Routes>
      <Route element={<Home />} path={routing.home.path} />
      <Route element={<Login />} path={routing.login.path} />
      <Route element={<Feedback />} path={routing.feedback.path} />
      {/* Admin layout-routes https://reactrouter.com/en/main/route/route#layout-routes */}
      <Route element={<AdminLayout />}>
        <Route element={<FAQList />} path={routing.admin.faq.list.path} />
        <Route
          element={<FAQRankingList />}
          path={routing.admin.faqRanking.list.path}
        />
        <Route element={<NewFAQ />} path={routing.admin.faq.new.path} />
        <Route element={<FAQContent />} path={routing.admin.faq.content.path} />
        <Route
          element={<SummarizeList />}
          path={routing.admin.summary.list.path}
        />
        <Route
          element={<NewSummarize />}
          path={routing.admin.summary.new.path}
        />
        <Route
          element={<SummarizeContent />}
          path={routing.admin.summary.content.path}
        />
        <Route element={<TagList />} path={routing.admin.tags.list.path} />
        <Route element={<CallList />} path={routing.admin.call.list.path} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
