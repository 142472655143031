import {
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  AccordionItem as ChakraAccordionItem,
  HStack,
  Heading,
  Text,
  VStack
} from "@chakra-ui/react";
import { useState, type FC } from "react";
import { MdSummarize } from "react-icons/md";

import type { ProposalProps } from "@/components/ProposalAccordion";

import { useFontSize } from "@/hooks/useFontSize";
import { timestampToDayjs } from "@/utils/dateUtils";

type ProposalAccordionItemProps = Pick<
  ProposalProps,
  "title" | "isSummary" | "updatedAt" | "summaryContent"
> & {
  qalistIndex: number;
  children: React.ReactNode;
};

export const ProposalAccordionItem: FC<ProposalAccordionItemProps> = ({
  title,
  isSummary,
  summaryContent,
  updatedAt,
  qalistIndex,
  children
}) => {
  const [isOpen, setIsOpen] = useState(qalistIndex === 0);
  const fontSize = useFontSize();
  const summaryFontSize = useFontSize("sm");
  const timestampSize = useFontSize("xs");

  return (
    <ChakraAccordionItem border="none">
      <AccordionButton px="1" py="2" onClick={() => setIsOpen(!isOpen)}>
        <HStack justify="space-around" w="full" spacing="4">
          <HStack w="full" spacing="4">
            <Box
              display="inline-block"
              w="6"
              fontSize="3xl"
              fontWeight="bold"
              textAlign="center"
            >
              {isSummary ? "i" : "Q"}
            </Box>
            <VStack w="full" spacing="0.5">
              <Heading
                as="h3"
                verticalAlign="4px"
                w="full"
                pl="2"
                fontSize={fontSize}
                fontWeight="medium"
                textAlign="left"
              >
                {title}
              </Heading>
              {!isOpen && summaryContent && (
                <Text
                  w="full"
                  mt={1}
                  px={2}
                  color="gray.600"
                  fontSize={summaryFontSize}
                  textAlign="left"
                  bg="whiteAlpha.600"
                  borderRadius="base"
                  wordBreak="break-all"
                >
                  <Box
                    display="inline-block"
                    verticalAlign="-4px"
                    mr="1"
                    fontSize="18"
                  >
                    <MdSummarize />
                  </Box>
                  返答要約：
                  {summaryContent}
                </Text>
              )}
            </VStack>
          </HStack>
          {updatedAt && (
            <Text
              w="32"
              fontSize={timestampSize}
              lineHeight={1.4}
              textAlign="left"
            >
              最終更新日:
              <br />
              {timestampToDayjs(updatedAt).format("YYYY-MM-DD HH:mm")}
            </Text>
          )}
        </HStack>

        <AccordionIcon boxSize={9} />
      </AccordionButton>
      <AccordionPanel p="0">{children}</AccordionPanel>
    </ChakraAccordionItem>
  );
};
