import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text
} from "@chakra-ui/react";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams
} from "react-router-dom";

type BreadcrumbPath = {
  path: string;
  breadcrumbName: string;
  isLink: boolean;
};

type Params = {
  [key: string]: string | undefined;
};

const getBreadcrumbName = (pathSegment: string, params: Params): string => {
  // ここでpathSegmentとパラメータに基づいて名称を決定
  switch (pathSegment) {
    case "admin":
      return "管理";
    case "faq":
      return "質問応答";
    case "faq-rank":
      return "よく使う質問応答";
    case "summary":
      return "通話要約";
    case "tags":
      return "検索0件キーワード";
    case "new":
      return "新規追加";
    default:
      return params[pathSegment] ?? pathSegment; // パラメータの値を表示
  }
};

export const Breadcrumbs: React.FC = () => {
  const location = useLocation();
  const params = useParams<Params>();
  const [searchParams, _setSearchParams] = useSearchParams();
  const isEditing = searchParams.get("edit") === "true";
  const pathnames = location.pathname
    .split("/")
    .slice(1)
    .filter(x => x);

  const paths: BreadcrumbPath[] = pathnames.map((pathSegment, index, arr) => {
    const path = `/${arr.slice(0, index + 1).join("/")}`;
    let breadcrumbName = getBreadcrumbName(pathSegment, params);
    // 編集モード時に末尾の項目名に「 - 編集」を追加
    if (isEditing && index === pathnames.length - 1) {
      breadcrumbName += " - 編集";
    }

    return {
      path,
      breadcrumbName,
      isLink: pathSegment !== "admin" && !(index === pathnames.length - 1)
    };
  });

  return (
    <Breadcrumb>
      {paths.map((path, index) => (
        <BreadcrumbItem key={index} isCurrentPage={index === paths.length - 1}>
          {path.isLink ? (
            <BreadcrumbLink as={Link} to={path.path}>
              {path.breadcrumbName}
            </BreadcrumbLink>
          ) : (
            <Text as="span">{path.breadcrumbName}</Text>
          )}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
