import { Box, IconButton, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { MdContentCopy } from "react-icons/md";

import type { TalkSummary } from "@/api/talkSummary";
import type { BoxProps } from "@chakra-ui/react";
import type { FC } from "react";

import { useCustomToast } from "@/hooks/useCustomToast";
import { useFontSize } from "@/hooks/useFontSize";

type SummaryTableProps = {
  summary: TalkSummary;
} & BoxProps;

export const SummaryTable: FC<SummaryTableProps> = ({ summary, ...rest }) => {
  const fontSize = useFontSize("lg");
  const { showErrorToast, showSuccessToast } = useCustomToast();

  function copyClipboard(item: [string, string]) {
    navigator.clipboard
      .writeText(item[1])
      .then(function () {
        showSuccessToast(`${item[0]}の内容をクリップボードへコピーしました`);
      })
      .catch(function () {
        showErrorToast("クリップボードへのコピーに失敗しました");
      });
  }

  return (
    <Box p={4} borderRadius={8} bgColor="white" {...rest}>
      <Table variant="simple">
        <Tbody>
          {Object.entries(summary).map((item, index) => {
            const isLast = index === Object.entries(summary).length - 1;

            return (
              <Tr key={index} fontSize={fontSize}>
                <Td w="260px" borderBottom={isLast ? "none" : "1px solid"}>
                  <Text
                    as="span"
                    fontWeight="bold"
                    wordBreak="break-word"
                    overflowWrap="break-word"
                  >
                    {item[0]}
                  </Text>
                </Td>
                <Td borderBottom={isLast ? "none" : "1px solid"}>
                  <Text
                    lineHeight="1.5"
                    whiteSpace="pre-wrap"
                    wordBreak="break-word"
                    overflowWrap="break-word"
                  >
                    {item[1]}
                  </Text>
                </Td>
                <Td w="12" borderBottom={isLast ? "none" : "1px solid"}>
                  <IconButton
                    color="gray"
                    fontSize="2xl"
                    aria-label="copy"
                    icon={<MdContentCopy />}
                    onClick={() => {
                      copyClipboard(item);
                    }}
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};
