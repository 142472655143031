import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  Textarea,
  VStack
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";

import type { SubmitHandler } from "react-hook-form";

export type QAFormData = {
  question: string;
  answer: string;
  memo: string;
  referralLink: string;
  ngOperationNote?: string;
  summary?: string;
};

type Props = {
  faqData: {
    id: keyof QAFormData;
    label: string;
    placeholder?: string;
    value: string | undefined;
  }[];
  backAction: () => void;
  submitAction: SubmitHandler<QAFormData>;
};

const validationRules = {
  question: {
    required: "質問を入力してください。"
  },
  answer: {
    required: "回答を入力してください。"
  },
  memo: {},
  referralLink: {},
  ngOperationNote: {},
  summary: {}
};

export const QAndAForm = (props: Props) => {
  const { submitAction, backAction, faqData } = props;

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<QAFormData>();

  const onSubmit: SubmitHandler<QAFormData> = async data => {
    await submitAction(data);
    console.log(data);
  };

  const isNew = faqData?.[0].id === "question" && faqData?.[0].value === "";

  return (
    <Box as="form" w="full" noValidate onSubmit={handleSubmit(onSubmit)}>
      <VStack w="full" px={10} pt="2" pb={8} spacing="8">
        {faqData?.map((item, index) => (
          <FormControl key={index}>
            <FormLabel fontSize="3xl" fontWeight="bold">
              {item.label}
            </FormLabel>
            <VStack align="start" w="full">
              <Controller
                name={item.id}
                control={control}
                defaultValue={item.value || ""}
                render={({ field }) =>
                  item.id === "referralLink" ? (
                    <Input w="full" placeholder={item.placeholder} {...field} />
                  ) : (
                    <Textarea
                      w="full"
                      placeholder={item.placeholder}
                      {...field}
                    />
                  )
                }
                rules={validationRules[item.id]}
              />
              {errors[item.id] && (
                <Text color="red.500" fontSize="sm">
                  {errors[item.id]?.message}
                </Text>
              )}
            </VStack>
          </FormControl>
        ))}

        <HStack justify="flex-end" w="full" spacing="6">
          <Button
            colorScheme={isNew ? "teal" : "purple"}
            // isDisabled={!isValid}
            onClick={backAction}
            type="reset"
            variant="outline"
          >
            キャンセル
          </Button>
          <Button
            colorScheme={isNew ? "teal" : "purple"}
            // isDisabled={!isValid}
            type="submit"
            variant="solid"
          >
            {isNew ? "新規追加" : "更新"}
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};
