import { Button, Stack } from "@chakra-ui/react";

import type { WordForFAQSearch } from "@/api/aspida/@types";
import type { FC } from "react";

import { useFontSize } from "@/hooks/useFontSize";

type ProposalTagButtonsProps = {
  tags: WordForFAQSearch[];
  handleClick: (word: string) => void;
};

export const ProposalTagButtons: FC<ProposalTagButtonsProps> = props => {
  const { tags, handleClick, ...rest } = props;
  const fontSize = useFontSize("xs");

  return (
    <Stack direction="row" py="1" {...rest} flexWrap="wrap">
      {tags.map((tag, index) =>
        tag.word ? (
          <Button
            key={tag.word + index}
            h={6}
            px={2}
            fontSize={fontSize}
            colorScheme="gray"
            onClick={() => {
              handleClick(tag.word!);
            }}
            variant="outline"
          >
            {tag.word}
          </Button>
        ) : null
      )}
    </Stack>
  );
};
