import { ResGetCalls, ResGetCallsItem } from "@api/@types";
import { useEffect, useState } from "react";

import { getCalls } from "@/api/admin/Calls/list";

type Props = {
  startDateJst?: string | null;
  endDateJst?: string | null;
  limit: number;
  offset: number;
};

export const useGetCalls = (props: Props) => {
  const [calls, setCalls] = useState<ResGetCallsItem[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<number>(0); // 再取得を管理するための状態
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    const fetchData = async () => {
      const res: ResGetCalls = await getCalls(props);
      if (res) {
        const calls: ResGetCallsItem[] = res.data.map(item => {
          return {
            last_updated_at_utc: item.last_updated_at_utc,
            call_id: item.call_id,
            operator_id: item.operator_id,
            transcription: item.transcription,
            email: item.email
          };
        });

        setCalls(calls);
        setHasMore(res.has_next);
      }
    };
    try {
      setIsLoading(true);
      fetchData();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  return {
    calls,
    hasMore,
    refetch: () => setTrigger(prev => prev + 1),
    isLoading
  };
};
