import { Button, HStack, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import type { ResponseGetSummaryTarget } from "@api/@types";

import { getSummaryTargets } from "@/api/admin/Summary/list.ts";
import { SummariesTable } from "@/components/Admin/SummariesTable";
import { useCustomToast } from "@/hooks/useCustomToast";
import { useGetSumOfSummaryTargetChars } from "@/hooks/useGetSumOfSummaryTargetChars";
import { AdminHeader } from "@/layouts/AdminHeader";
import { routing } from "@/utils/routing";

export const SummarizeList = () => {
  const [summaryTargets, setSummaryTargets] = useState<
    ResponseGetSummaryTarget[]
  >([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { usedCount, limit } = useGetSumOfSummaryTargetChars();
  const { showWarningToast } = useCustomToast();
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await getSummaryTargets({});
      setSummaryTargets(
        res.items.sort((a, b) => b.importance_level - a.importance_level)
      );
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (usedCount === null || limit === null) return;
    if (usedCount > limit) {
      showWarningToast(
        `通話要約項目の合計が${limit}字を超えています(${usedCount}字)。`,
        `要約精度低下もしくは要約失敗の要因になる為、削減を検討してください。`,
        5000
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usedCount, limit]);

  return (
    <VStack w="full" spacing="6">
      <AdminHeader title="通話要約 一覧" />
      <VStack w="full" px={10} pb={8} spacing="6">
        <HStack justify="end" w="full">
          <Button
            px="4"
            colorScheme="teal"
            leftIcon={<MdAddCircleOutline size="24px" />}
            onClick={() => navigate(routing.admin.summary.new.path)}
          >
            新規追加
          </Button>
        </HStack>
        <SummariesTable summaries={summaryTargets} isLoading={isLoading} />
      </VStack>
    </VStack>
  );
};
