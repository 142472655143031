import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Skeleton,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useMediaQuery
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import {
  MdOutlineDescription,
  MdQuestionAnswer,
  MdRefresh
} from "react-icons/md";
import { useNavigate } from "react-router-dom";

import type {
  CallTranscriptionItem,
  ResponseFAQContent,
  ResponseFAQContentItem,
  WordForFAQSearch
} from "@/api/aspida/@types";
import type { TranscribeProps } from "@/api/transcribe";
import type { ProposalProps } from "@/components/ProposalAccordion";

import { createCall } from "@/api/createCall";
import { postSearchFaq } from "@/api/searchFaq";
import { getNgOperations } from "@/api/searchNgOperations";
import { getSearchTags } from "@/api/searchTags";
import { transcribe } from "@/api/transcribe";
import { FAQRanking } from "@/components/FAQRanking";
import { HomeHeading } from "@/components/HomeHeading";
import { MicVAD } from "@/components/MicVAD";
import { OperatorNotes } from "@/components/OperatorNotes";
import { ProposalAccordion } from "@/components/ProposalAccordion";
import { ProposalSearchBar } from "@/components/ProposalSearchBar";
import { ProposalTagButtons } from "@/components/ProposalTagButtons";
import { SpeakerBox } from "@/components/SpeakerBox";
import { Spinner } from "@/components/Spinner";
import { UserVoiceActivity } from "@/components/UserVoiceActivity";
import { useMainContext } from "@/contexts/mainContext/useMainContext";
import { HomeContextProvider } from "@/contexts/pages/Home/HomeContextProvider";
import { useCustomToast } from "@/hooks/useCustomToast.tsx";
import { useFontSize } from "@/hooks/useFontSize";
import { useGetFrequencyRank } from "@/hooks/useGetFrequencyRank";
import { useRecorders } from "@/hooks/useRecorders.tsx";
import { Header } from "@/layouts/Header";
import { INTERVAL_TIME, MAX_TOTAL_RECORDING_TIME } from "@/utils/constants";
import { convertBrTagsToNewline } from "@/utils/convertUtils";
import { notifyErrorLog } from "@/utils/notifyErrorLog.ts";
// import { requestTimeout } from "@/utils/await-timeout.ts";
// import { AdditionalQuestions } from "@/components/AdditionalQuestions";
// import { Summary } from "@/components/Summary";

export const Home = () => {
  const [isLargerThan960] = useMediaQuery("(min-width: 960px)");
  const mainCtx = useMainContext();
  const fontSize = useFontSize();
  const smallFontSize = useFontSize("sm");
  const [tabIndex, setTabIndex] = useState(1);
  const { showInfoToast, showWarningToast } = useCustomToast();

  const [transcriptions, setTranscriptions] = useState<CallTranscriptionItem[]>(
    []
  );
  const [tags, setTags] = useState<WordForFAQSearch[]>([]);
  const [words, setWords] = useState<string[]>([]);
  const [qalist, setQalist] = useState<ProposalProps[]>([]);
  const [ngOperationList, setNgOperationList] = useState<string[]>([]);
  const talkingTimeFrom = useRef<number | null>(null);
  const transcriptionQueue = useRef<TranscribeProps[]>([]);
  const isWorkingTranscriptionQueue = useRef<boolean>(false);
  const isTalkingRef = useRef<boolean | null>(mainCtx.isRecording);
  const [talkingStates, setTalkingStates] = useState<{
    [key: number]: boolean;
  }>({ 0: false, 1: false });

  const [isMediaRecorderInitialized, setIsMediaRecorderInitialized] =
    useState<boolean>(false);
  const vadRef = useRef(null);
  const [isActiveVoice, setIsActiveVoice] = useState<boolean>(false);
  const [isLoadingRefreshTags, setIsLoadingRefreshTags] =
    useState<boolean>(false);
  const [isLoadingSearchResult, setIsLoadingSearchResult] =
    useState<boolean>(false);

  const initCustomerChannel = localStorage.getItem("customer-channel") || "0";
  const [customerChannel, setCustomerChannel] =
    useState<string>(initCustomerChannel);
  const customerChannelRef = useRef<string>(customerChannel);

  useEffect(() => {
    customerChannelRef.current = customerChannel;
    localStorage.setItem("customer-channel", customerChannel);
  }, [customerChannel]);

  const {
    recorderContexts,
    toggleRecordingWithVADChanged,
    isLoadingAudioMedia
  } = useRecorders();

  useEffect(() => {
    mainCtx.setMainContext({ callId: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof mainCtx.callId !== "string") {
      return;
    }
    if (!mainCtx.callId) {
      return;
    }
    const old_transcriptions = mainCtx.transcriptions;
    const fetchNgOperationData = async () => {
      // linter対応
      if (mainCtx.callId === null) {
        return;
      }
      const res: string[] = await getNgOperations(mainCtx.callId);
      console.log("NGオペレーション", res);
      // 仕様的に最大2件までのアラートを表示
      const updatedItems = res.slice(0, 2);
      setNgOperationList(updatedItems);
    };
    mainCtx.setMainContext({ transcriptions: transcriptions });
    //文字起こしに変更が生じる度にNGオペレーションに該当しないかチェック
    //文字起こしリクエストが走っても、transcriptionsの値に変化がないケースとして、新規文字起こし分がマスク時にすべて消された場合などがあるので、それ以外のケースでNGオペレーションを取得する
    if (old_transcriptions.length != transcriptions.length) {
      console.log("文字起こし検知", transcriptions);
      console.log(ngOperationList); //TODO: 今はconsoleに出力しているが、将来的に画面に表示する
      fetchNgOperationData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcriptions]);

  const navigate = useNavigate();

  const isEnableRecording = () => {
    const currentTimestamp = Date.now();
    // console.log(currentTimestamp - talkingTimeFrom.current!);
    return (
      currentTimestamp - talkingTimeFrom.current! < MAX_TOTAL_RECORDING_TIME
    );
  };
  const callId = mainCtx.callId;

  const handleRefreshTags = async () => {
    console.log(transcriptions.length);
    if (!callId || transcriptions.length === 0) {
      return;
    }
    setIsLoadingRefreshTags(true);
    const res = await getSearchTags({ callId });
    console.log("getSearchTags");
    if (res) {
      setTags([...(res.data ?? [])]);
    }
    setIsLoadingRefreshTags(false);
  };

  const handleClickTagsButton = (word: string) => {
    const newWords = Array.from(new Set([...words, word]));
    setWords(newWords);
  };

  const handleSearch = async (inputValue: string) => {
    if (!inputValue) {
      return;
    }
    const searchWordsArray = inputValue.split(/[\u3000 ]+/);

    setIsLoadingSearchResult(true);

    const res: ResponseFAQContent | false = await postSearchFaq({
      tags: searchWordsArray,
      call_id: callId ?? undefined
    });
    if (res) {
      const newList: ProposalProps[] = res.data.map(
        (item: ResponseFAQContentItem) => {
          return {
            id: item.id,
            title: item.question ?? "",
            content: convertBrTagsToNewline(item.answer ?? ""),
            href: item.url,
            memo: convertBrTagsToNewline(item.memo ?? ""),
            priority: item.priority,
            updatedAt: item.updated_at,
            ng_operation_note: item.ng_operation_note,
            summaryContent: item.summary
          };
        }
      );

      setQalist(newList);
    }

    setIsLoadingSearchResult(false);
  };

  // FAQ頻出ランキング
  const { proposals: frequencyRanks } = useGetFrequencyRank();

  useEffect(() => {
    isTalkingRef.current = mainCtx.isRecording;

    if (mainCtx.isRecording) {
      console.log("start recording");
      talkingTimeFrom.current = Date.now();
    } else {
      if (recorderContexts.current.length > 0) {
        navigate("/feedback");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCtx.isRecording]);

  useEffect(() => {
    if (recorderContexts.current.length == 1 && !mainCtx.enableMicrophone) {
      showWarningToast("お相手の音声もミュートされています。");
    }

    if (!mainCtx.enableMicrophone) {
      showInfoToast("音声をミュートしました。");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCtx.enableMicrophone]);

  const getSpeaker = (channel: number) => {
    const speaker = (() => {
      if (recorderContexts.current.length === 1) {
        return "unknown";
      } else {
        return String(channel) === customerChannelRef.current
          ? "customer"
          : "operator";
      }
    })();

    return speaker;
  };

  const addRecordingQueueTask = (
    audioBlob: Blob,
    recorderIndex: number,
    recordingTime: number
  ) => {
    const currentRecorderContext = recorderContexts.current[recorderIndex];
    const { channel } = currentRecorderContext;
    const speaker = (() => {
      if (recorderContexts.current.length === 1) {
        return "unknown";
      } else {
        return String(channel) === customerChannelRef.current
          ? "customer"
          : "operator";
      }
    })();
    if (mainCtx.callId) {
      const newQueue: TranscribeProps = {
        audioBlob: audioBlob,
        callId: mainCtx.callId,
        speaker: speaker,
        recordingTime: recordingTime
      };
      transcriptionQueue.current.unshift(newQueue);
    }
  };

  useEffect(() => {
    if (mainCtx.callId) {
      console.log("recorderContexts.current.length", recorderContexts.current);

      // NOTE:会話開始後、自動でタグがでるように初回実行
      handleRefreshTags();

      const interval = setInterval(() => {
        if (isTalkingRef.current) {
          toggleRecordingWithVADChanged();
        }

        if (isTalkingRef.current) {
          if (!isEnableRecording()) {
            mainCtx.setMainContext({ isRecording: false });
            window.confirm("録音時間が長いため、一度録音を停止します。");
          }
        }
      }, INTERVAL_TIME);

      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCtx.callId]);

  useEffect(() => {
    // NOTE: 話者分離対応した際、同時にtranscribe requestが発生すると片方の発話リクエストが上書き保存されて消えてしまうため、Queueシステムを入れて同時リクエスト数が１になるように調整
    const interval = setInterval(() => {
      (async () => {
        if (!isWorkingTranscriptionQueue.current) {
          const transcribeProps = transcriptionQueue.current.pop();
          if (transcribeProps) {
            isWorkingTranscriptionQueue.current = true;
            console.log("dequeue", transcribeProps);
            try {
              const res = await Promise.race([
                transcribe(transcribeProps)
                // requestTimeout(5000)
              ]);
              if (res instanceof Array) {
                setTranscriptions(res);
              }
            } catch (e) {
              notifyErrorLog(e);
            }
            isWorkingTranscriptionQueue.current = false;
          }
        }
      })();
    }, INTERVAL_TIME);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const startVoiceRecording = async () => {
    if (!mainCtx.isRecording) {
      // create CallID
      const callId = await createCall();
      mainCtx.setMainContext({ callId: callId, isRecording: true });
    }
  };

  useEffect(() => {
    if (!isLoadingAudioMedia && !(recorderContexts.current.length > 0)) return;
    setTimeout(() => {
      (async () => {
        await startVoiceRecording();
      })();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingAudioMedia]);

  return (
    <VStack w="100%" minW="656px" pb="8" bgColor="gray.50" spacing="6">
      {isLoadingAudioMedia && isMediaRecorderInitialized && <Spinner />}
      {recorderContexts.current.map((recorderContext, index) => {
        return (
          <MicVAD
            key={index}
            stream={recorderContext.recorder.stream}
            speaker={getSpeaker(recorderContext.channel)}
            setTalkingStates={setTalkingStates}
            setIsMediaRecorderInitialized={setIsMediaRecorderInitialized}
            vadRef={vadRef}
            isActiveVoice={isActiveVoice}
            setIsActiveVoice={setIsActiveVoice}
            addRecordingQueueTask={addRecordingQueueTask}
          />
        );
      })}
      <Tabs
        w="full"
        defaultIndex={1}
        onChange={index => setTabIndex(index)}
        variant="enclosed"
      >
        <Header
          isLargerThan960
          isSpeaker={mainCtx.isRecording}
          startVoiceRecording={startVoiceRecording}
        >
          <TabList pos="relative" top="3px" flexDir="row-reverse">
            <Tab
              bg={tabIndex === 0 ? "gray.50" : "gray.100"}
              borderBottom="none"
            >
              FAQ頻出ランキング
            </Tab>
            <Tab
              bg={tabIndex === 1 ? "gray.50" : "gray.100"}
              borderBottom="none"
            >
              文字起こし
            </Tab>
          </TabList>
        </Header>
        <HomeContextProvider>
          <TabPanels>
            <TabPanel w="full">
              <FAQRanking
                isLargerThan960
                smallFontSize={smallFontSize}
                qalist={frequencyRanks} // TODO: ここを変更
              />
            </TabPanel>
            <TabPanel px="0">
              <Box w="full" maxW={isLargerThan960 ? "960px" : "full"} mx="auto">
                <VStack px="6" spacing="6">
                  <VStack w="full" spacing="3">
                    <HomeHeading icon={MdOutlineDescription} title="文字起こし">
                      {recorderContexts.current.length > 1 && (
                        <HStack>
                          <FormControl alignItems="center" display="flex">
                            <FormLabel mb="0" fontSize={fontSize}>
                              話者切り替え
                            </FormLabel>
                            <Switch
                              defaultChecked={customerChannel === "1"}
                              isChecked={customerChannel === "1"}
                              onChange={e => {
                                console.log(e);
                                setCustomerChannel(prev =>
                                  prev === "0" ? "1" : "0"
                                );
                              }}
                              value={customerChannel}
                            />
                          </FormControl>
                        </HStack>
                      )}
                    </HomeHeading>
                    {ngOperationList.map((item, index) => (
                      <Alert
                        key={index}
                        fontSize={smallFontSize}
                        status="warning"
                        variant="subtle"
                      >
                        <AlertIcon />
                        {item}
                      </Alert>
                    ))}
                    <Card pos="relative" w="full" p={4} borderRadius={8}>
                      <Box overflowY="scroll" maxH="352px" pb={10} bg="white">
                        <SpeakerBox transcriptions={transcriptions} />
                      </Box>
                      <Box pos="absolute" bottom={4}>
                        <UserVoiceActivity
                          talkingStates={talkingStates}
                          customerChannel={customerChannel}
                        />
                      </Box>
                    </Card>
                  </VStack>
                  <VStack w="full" spacing="6">
                    <VStack w="full" spacing="3">
                      <HStack align="flex-start" justify="flex-start" w="full">
                        <Heading as="h3" w="full" size={smallFontSize}>
                          メモ欄
                        </Heading>
                      </HStack>
                      <Box w="full" bg="white">
                        <OperatorNotes />
                      </Box>
                    </VStack>
                  </VStack>
                  {/* <HomeHeading
              icon={MdOutlineDescription}
              py={3}
              title="現在の質問"
            />
            <Box w="full" pb="4">
              <CurrentQuestions />
            </Box> */}
                  <VStack w="full" spacing="6">
                    <VStack w="full" spacing="3">
                      <HStack align="flex-start" justify="flex-start" w="full">
                        <HomeHeading icon={MdQuestionAnswer} title="提案候補" />
                        <Button
                          h={8}
                          fontSize={fontSize}
                          colorScheme="orange"
                          isDisabled={!callId || transcriptions.length === 0}
                          leftIcon={<MdRefresh />}
                          onClick={handleRefreshTags}
                        >
                          タグ更新
                        </Button>
                      </HStack>
                      <Card w="full">
                        <CardBody p={4}>
                          {mainCtx.callId && isLoadingRefreshTags ? (
                            <Spinner height="30" />
                          ) : tags.length > 0 ? (
                            <ProposalTagButtons
                              tags={tags}
                              handleClick={handleClickTagsButton}
                            />
                          ) : (
                            <Text>候補がありません。タグ更新してください</Text>
                          )}
                        </CardBody>
                      </Card>
                      <ProposalSearchBar
                        words={[...words]}
                        setWords={setWords}
                        handleSearch={handleSearch}
                      />
                    </VStack>
                    <VStack w="full" spacing="3">
                      <Heading as="h3" w="full" size={fontSize}>
                        検索結果
                      </Heading>
                      <Text
                        as="span"
                        align="right"
                        w="full"
                        fontSize={smallFontSize}
                      >
                        ※関連性が高いと推定されたものから順に表示されています。
                      </Text>
                      <Box w="full" p="2" bgColor="white">
                        {mainCtx.callId && isLoadingSearchResult ? (
                          <VStack>
                            <Skeleton w="full" h="20px" />
                            <Skeleton w="full" h="20px" />
                            <Skeleton w="full" h="20px" />
                          </VStack>
                        ) : (
                          <ProposalAccordion qalist={qalist} />
                        )}
                      </Box>
                    </VStack>
                  </VStack>
                  {/*
            <HomeHeading icon={QuestionOutlineIcon} title="追加質問例" />
            <Box w="full">
              <AdditionalQuestions />
            </Box> */}
                </VStack>
              </Box>
            </TabPanel>
          </TabPanels>
        </HomeContextProvider>
      </Tabs>
    </VStack>
  );
};
