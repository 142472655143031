import { apiClient, getBearerToken, getOperatorId } from "@/utils/apiClient";
import { notifyErrorLog } from "@/utils/notifyErrorLog.ts";

type Props = {
  id: string;
  question: string;
  answer: string;
  memo: string;
  referralLink: string;
  ngOperationNote?: string;
  summary?: string;
};

export const upsertQAContent = async (params: Props) => {
  const { id, question, answer, memo, referralLink, ngOperationNote, summary } = params;

  const bearerToken = await getBearerToken();

  const operatorId = await getOperatorId();

  const newParams = {
    id: id,
    updated_by: operatorId,
    question: question,
    answer: answer,
    memo: memo,
    url: referralLink,
    ng_operation_note: ngOperationNote,
    category: "dummy", // NOTE: backend APIの仕様上ではrequiredなのでdummyを入れている
    summary: summary
  };

  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };

  try {
    const res = await apiClient.admin.upsertfaq.$post({
      body: newParams,
      config: {
        headers: headers
      }
    });
    return res.status;
  } catch (e) {
    notifyErrorLog(e);
    return false;
  }
};
