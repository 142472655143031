import { Link } from "@chakra-ui/react";

import type { LinkProps } from "@chakra-ui/react";

export const LinkifyLink = (props: LinkProps) => {
  return (
    <Link color="blue.500" href={props.href} isExternal word-break="break-all">
      {props.children}
    </Link>
  );
};
