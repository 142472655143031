export type RecorderContextType = {
  analyser: AnalyserNode;
  recorder: MediaRecorder;
  channel: number;
  talkingTimeFrom: number; // timestamp
  talkingTimeTo: number | null; // timestamp
};

// targetAnalyser: AnalyserNode;
type DetectSpeechProps = {
  analyser: AnalyserNode;
};
export const detectSpeech = (props: DetectSpeechProps) => {
  const { analyser } = props;

  const bufferLength = analyser.frequencyBinCount;
  // console.log(targetAnalyser.frequencyBinCount); // fftSize / 2
  const dataArray = new Uint8Array(bufferLength);

  analyser.getByteTimeDomainData(dataArray);
  const THRESHOLD = 0.03; // この値は実験的に調整する必要がある e.g. 0.03;
  let valuesAboveThreshold = 0;
  for (let i = 0; i < bufferLength; i++) {
    const amplitude = Math.abs(dataArray[i] - 128) / 128;
    if (amplitude > THRESHOLD) {
      valuesAboveThreshold++;
    }
  }

  // console.log(valuesAboveThreshold);

  const rate = 0.08; // NOTE: experimental value
  return valuesAboveThreshold > bufferLength * rate;
};

type SetupVoiceDetectionProps = {
  stream: MediaStream;
};

export const setupVoiceDetectionRecorders = (
  props: SetupVoiceDetectionProps
): RecorderContextType[] => {
  const { stream } = props;
  const audioContext = new AudioContext();
  const source = audioContext.createMediaStreamSource(stream);
  console.log(source);

  // オーディオトラックを取得
  const audioTrack = stream.getAudioTracks()[0];
  console.log(audioTrack);
  // オーディオトラックの設定を取得
  const settings = audioTrack.getSettings();
  console.log(settings);

  const isStereo = settings.channelCount !== 1;
  console.log(isStereo ? "stereo" : "monaural");

  if (isStereo) {
    const splitter = audioContext.createChannelSplitter(2); // 2チャンネル（ステレオ）用
    console.log(splitter);

    source.disconnect();
    // 左右のチャンネルを分離
    source.connect(splitter);

    // 左チャンネル用 ChannelMergerNode の作成
    const leftMerger = audioContext.createChannelMerger(1);
    splitter.connect(leftMerger, 0, 0); // 0は左チャンネル

    // 右チャンネル用 ChannelMergerNode の作成
    const rightMerger = audioContext.createChannelMerger(1);
    splitter.connect(rightMerger, 1, 0); // 1は右チャンネル

    // 左チャンネルの MediaStreamAudioDestinationNode
    const leftDestination = audioContext.createMediaStreamDestination();
    leftMerger.connect(leftDestination);
    const leftAnalyser = audioContext.createAnalyser();
    leftAnalyser.fftSize = 2048;
    leftMerger.connect(leftAnalyser);

    // 右チャンネルの MediaStreamAudioDestinationNode
    const rightDestination = audioContext.createMediaStreamDestination();
    rightMerger.connect(rightDestination);
    const rightAnalyser = audioContext.createAnalyser();
    rightAnalyser.fftSize = 2048;
    rightMerger.connect(rightAnalyser);

    // 左チャンネル用 MediaRecorder の作成
    const leftRecorder = new MediaRecorder(leftDestination.stream);
    // 右チャンネル用 MediaRecorder の作成
    const rightRecorder = new MediaRecorder(rightDestination.stream);

    return [
      {
        analyser: leftAnalyser,
        recorder: leftRecorder,
        channel: 0,
        talkingTimeFrom: 0,
        talkingTimeTo: null
      },
      {
        analyser: rightAnalyser,
        recorder: rightRecorder,
        channel: 1,
        talkingTimeFrom: 0,
        talkingTimeTo: null
      }
    ];
  } else {
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 2048;
    source.connect(analyser);

    // 左チャンネル用 MediaRecorder の作成
    const recorder = new MediaRecorder(stream);

    return [
      {
        analyser: analyser,
        recorder: recorder,
        channel: 0,
        talkingTimeFrom: 0,
        talkingTimeTo: null
      }
    ];
  }
};
