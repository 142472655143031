import { createContext, useContext } from "react";

import type { HomeContextType } from "@/contexts/pages/Home/HomeContextProvider";

type HomeContextHookType = HomeContextType & {
  setHomeContext: (newObj: HomeContextType) => void;
};

export const defaultHomeContext: HomeContextType = {
  selectedQuestionText: ""
};
// context default value
const defaultHomeContextHook: HomeContextHookType = {
  ...defaultHomeContext,
  setHomeContext: () => {}
};

export const HomeContext = createContext(defaultHomeContextHook);

export const useHomeContext = () => useContext(HomeContext);
