import type {
  Body_transcribe_transcribe_post,
  CallTranscription,
  CallTranscriptionItem
} from "./aspida/@types";

import { apiClient, getBearerToken } from "@/utils/apiClient";

export type TranscribeProps = {
  audioBlob: Blob;
  callId: string;
  speaker: "customer" | "operator" | "unknown";
  recordingTime: number;
};

export const transcribe = async (
  props: TranscribeProps
): Promise<CallTranscriptionItem[]> => {
  const { audioBlob, callId, speaker, recordingTime } = props;
  const params = {
    call_id: callId,
    speaker: speaker,
    recording_time: recordingTime
  };
  const body: Body_transcribe_transcribe_post = {
    file: audioBlob as File
  };

  const bearerToken = await getBearerToken();
  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };

  try {
    const transcription: CallTranscription = await apiClient.transcribe.$post({
      query: params,
      body: body,
      config: {
        headers: headers
      }
    });
    console.log(transcription.data);
    return transcription.data;
  } catch (e) {
    // TODO: エラー通知
    console.error("Error:", e);
    return [];
  }
};
