import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Button,
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from "@chakra-ui/react";
import { MdLaunch } from "react-icons/md";

export const AdminMenu = () => {
  return (
    <Menu>
      <MenuButton as={Button} leftIcon={<HamburgerIcon />}>
        Menu
      </MenuButton>
      <MenuList>
        <MenuItem as={Link} href="/" rel="noopener noreferrer" target="_blank">
          <HStack justify="space-between" w="full" h="full" p={0}>
            <Text>ダイアルメイトを表示</Text>
            <Icon as={MdLaunch} />
          </HStack>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
