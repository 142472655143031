import { useEffect, useState } from "react";

import { ZOOM_TO_NUMBER, useZoomContext } from "@/contexts/zoom/useZoomContext";

export const useChakraSize = (initialSize: string): string => {
  const [size, setSize] = useState(initialSize);
  const { zoom } = useZoomContext();

  useEffect(() => {
    const SIZES = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "12",
      "14",
      "16",
      "20",
      "24",
      "28",
      "32",
      "36",
      "40"
    ]; // TODO Chakra UI側で定義されている値を利用したい。現在は40以上は未定義、{num}x4pxずつ増えていく

    const currentIndex = SIZES.indexOf(initialSize);
    const zoomOffset = ZOOM_TO_NUMBER[zoom];

    const maxIndex = SIZES.length - 1;
    const proposedIndex = currentIndex + zoomOffset;
    const newSizeIndex = Math.min(maxIndex, proposedIndex);

    setSize(SIZES[newSizeIndex]);
  }, [initialSize, zoom]);

  return size;
};
