import { Button, HStack, RadioGroup, Text } from "@chakra-ui/react";
import { type FC } from "react";

import { useZoomContext } from "@/contexts/zoom/useZoomContext";
import { useFontSize } from "@/hooks/useFontSize";

export const Zoom: FC = () => {
  const { zoom, setZoom } = useZoomContext();
  const fontSize = useFontSize("lg");

  const handleZoom = (value: "normal" | "middle" | "large") => {
    setZoom(value);
  };

  return (
    <HStack>
      <Text fontSize={fontSize}>文字サイズ :</Text>
      <RadioGroup defaultValue={zoom}>
        <HStack>
          <Button
            w="44px"
            h="44px"
            color={"black"}
            fontSize="md"
            bg={zoom === "normal" ? "gray.300" : "gray.100"}
            border="1px solid"
            borderColor="gray.400"
            borderRadius="50%"
            dropShadow="base"
            onClick={() => handleZoom("normal")}
          >
            標準
          </Button>
          <Button
            w="50px"
            h="50px"
            color={"black"}
            fontSize="lg"
            bg={zoom === "middle" ? "gray.300" : "gray.100"}
            border="1px solid"
            borderColor="gray.400"
            borderRadius="50%"
            dropShadow="base"
            onClick={() => handleZoom("middle")}
          >
            中
          </Button>
          <Button
            w="52px"
            h="52px"
            color={"black"}
            fontSize="xl"
            bg={zoom === "large" ? "gray.300" : "gray.100"}
            border="1px solid"
            borderColor="gray.400"
            borderRadius="50%"
            dropShadow="base"
            onClick={() => handleZoom("large")}
          >
            大
          </Button>
        </HStack>
      </RadioGroup>
    </HStack>
  );
};
