import { apiClient, getBearerToken, getOperatorId } from "@/utils/apiClient";
import { notifyErrorLog } from "@/utils/notifyErrorLog.ts";

type Props = {
  summaryTargets: {
    id: string;
    name: string;
    details: string;
    importanceLevel: number;
  }[];
};

export const bulkUpdateSummaryTarget = async (params: Props) => {
  const bearerToken = await getBearerToken();

  const operatorId = await getOperatorId();

  const summaryTargets = params.summaryTargets.map(v => {
    return {
      id: v.id,
      operator_id: operatorId,
      name: v.name,
      details: v.details,
      importance_level: v.importanceLevel || 0,
      updated_by: operatorId
    };
  });

  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };

  try {
    const res = await apiClient.admin.bulk_update_summary_target.$post({
      body: {
        operator_id: operatorId,
        summary_targets: summaryTargets
      },
      config: {
        headers: headers
      }
    });
    return res.status;
  } catch (e) {
    notifyErrorLog(e);
    return false;
  }
};
