import { Body_admin_create_faq_csv_admin_faq_create_csv_post } from "@/api/aspida/@types";
import { apiClient, getBearerToken, getOperatorId } from "@/utils/apiClient";

export const faqCreateCsv = async (file: File): Promise<boolean> => {
  const bearerToken = await getBearerToken();
  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };

  const operatorId = await getOperatorId();

  const params: Body_admin_create_faq_csv_admin_faq_create_csv_post = {
    operator_id: operatorId,
    media_file: file
  };

  try {
    const response = await apiClient.admin.faq.create.csv.post({
      body: params,
      config: {
        headers: headers
      }
    });
    console.log(response);
    return response.body; // boolean 型を返す
  } catch (e) {
    console.error("Error:", e);
    return false;
  }
};
