import { apiClient, getBearerToken } from "@/utils/apiClient";
import { FAQItem } from "@api/@types";
export const getNgOperations = async (call_id: string) => {
  const bearerToken = await getBearerToken();

  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };

  try {
    const res: FAQItem[] = await apiClient.operation.note.list.$get({
      query: { call_id: call_id },
      config: {
        headers: headers
      }
    });

    const results: string[] = res
      .filter((operation: FAQItem) => !!operation.ng_operation_note)
      .map((operation: FAQItem) => operation.ng_operation_note as string);

    return results;
  } catch (e) {
    console.log("failed to search_ng operation");
    console.log(e);
    // TODO: エラー通知
    return [];
  }
};
