import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack
} from "@chakra-ui/react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MdLogin, MdVisibility, MdVisibilityOff } from "react-icons/md";

import type { SubmitHandler } from "react-hook-form";

import { useAuthContext } from "@/contexts/auth/useAuthContext";

type FormInputs = {
  email: string;
  password: string;
  newPassword: string;
};

export const Login = () => {
  const { login, completeNewPassword, isChangingPassword } = useAuthContext();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const {
    control,
    formState: { errors, isValid },
    handleSubmit
  } = useForm<FormInputs>({
    mode: "onChange"
  });

  const onSubmit: SubmitHandler<FormInputs> = async data => {
    if (isChangingPassword) {
      // パスワード変更処理
      await completeNewPassword(data.newPassword);
      return;
    }

    // login関数を呼び出してログイン処理を行う
    await login(data.email, data.password);
  };

  return (
    <Flex align="center" justify="center" minH="100vh" bgColor="gray.50">
      <Box
        as="form"
        p={20}
        pt={8}
        pb={12}
        borderWidth={1}
        borderRadius="xl"
        bgColor="cyan.100"
        noValidate // ブラウザのバリデーションを無効化し、react-hook-form のバリデーションを使用する
        onSubmit={handleSubmit(onSubmit)}
      >
        <Heading mb={5} fontSize="24px" textAlign="center">
          ログイン
        </Heading>
        <VStack spacing={8}>
          <VStack w={72} spacing={5}>
            {!isChangingPassword ? (
              <>
                <FormControl id="email" isInvalid={errors.email ? true : false}>
                  <FormLabel>メールアドレス</FormLabel>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "メールアドレスは必須です",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "無効なメールアドレス形式です"
                      }
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        bgColor="white"
                        placeholder="メールアドレスを入力"
                        size="lg"
                        type="email"
                      />
                    )}
                  />
                  {errors.email && (
                    <Text color="red.500">{errors.email.message}</Text>
                  )}
                </FormControl>
                <FormControl
                  id="password"
                  isInvalid={errors.password ? true : false}
                >
                  <FormLabel>パスワード</FormLabel>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "パスワードは必須です",
                      minLength: { value: 10, message: "最小10文字必要です" }
                    }}
                    render={({ field }) => (
                      <InputGroup size="lg">
                        <Input
                          {...field}
                          pr="sm"
                          bg="white"
                          placeholder="パスワードを入力"
                          type={show ? "text" : "password"}
                        />
                        <InputRightElement>
                          <IconButton
                            fontSize="2xl"
                            bg="transparent"
                            aria-label={show ? "Hide" : "Show"}
                            icon={show ? <MdVisibility /> : <MdVisibilityOff />}
                            onClick={() => setShow(!show)}
                            size="sm"
                          />
                        </InputRightElement>
                      </InputGroup>
                    )}
                  />
                  {errors.password && (
                    <Text color="red.500">{errors.password.message}</Text>
                  )}
                </FormControl>
              </>
            ) : (
              <FormControl id="password">
                <FormLabel>パスワード変更</FormLabel>
                <Controller
                  name="newPassword"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "パスワードは必須です",
                    minLength: { value: 10, message: "最小10文字必要です" }
                  }}
                  render={({ field }) => (
                    <InputGroup size="lg">
                      <Input
                        {...field}
                        pr="sm"
                        bg="white"
                        placeholder="新しいパスワードを入力"
                        type={show ? "text" : "password"}
                      />
                      <InputRightElement>
                        <IconButton
                          fontSize="2xl"
                          bg="transparent"
                          aria-label={show ? "Hide" : "Show"}
                          icon={show ? <MdVisibility /> : <MdVisibilityOff />}
                          onClick={handleClick}
                          size="sm"
                        />
                      </InputRightElement>
                    </InputGroup>
                  )}
                />
                {errors.newPassword && (
                  <Text color="red.500">{errors.newPassword.message}</Text>
                )}
                <Text mt="2" color="red.500">
                  ※パスワードには、半角英数字記号が使用できます。
                  アルファベットは大文字、小文字を区別して登録してください。
                </Text>
              </FormControl>
            )}
          </VStack>
          <Button
            sx={{ "> .chakra-button__icon": { ml: "-1em" } }}
            w="full"
            bg="gray.300"
            _hover={{ bg: "gray.400" }}
            _active={{ bg: "gray.500" }}
            colorScheme="gray"
            isDisabled={!isValid}
            leftIcon={<MdLogin />}
            size="lg"
            type="submit"
            variant="solid"
          >
            {isChangingPassword ? "変更してログイン" : "ログイン"}
          </Button>
        </VStack>
      </Box>
    </Flex>
  );
};
