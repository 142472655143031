import { Box, Button, FormControl, FormLabel, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useFormContext } from "react-hook-form";

type FormData = {
  file: File | null;
};

const validationRules = {
  file: {
    required: "ファイルを選択してください。",
    validate: {
      isCSV: (value: File | null) =>
        value?.name.split(".").pop()?.toLowerCase() === "csv" ||
        "CSVファイルを選択してください。"
    }
  }
};

export const QACsvFileUploadForm = () => {
  const {
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors
  } = useFormContext<FormData>();
  const [fileName, setFileName] = useState<string | null>(null);

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (fileExtension !== "csv") {
        setError("file", {
          type: "validate",
          message: "CSVファイルを選択してください。"
        });
        setFileName(null);
      } else {
        clearErrors("file");
        setValue("file", file);
        setFileName(file.name);
      }
    }
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept: { "text/csv": [".csv"] },
    multiple: false
  });

  const fileRejectionItems = fileRejections.map(({ file }) => (
    <Text key={file.name} color="red.500" fontSize="sm">
      {file.name}はCSVファイルではありません。
    </Text>
  ));

  return (
    <FormControl isInvalid={!!errors.file}>
      <FormLabel htmlFor="file">ファイルを選択</FormLabel>
      <Text mb={6}>
        question, answer, memo, url, ng_operation_note
        のテキストを１行目に入れて、
        追加する項目を行ごとにいれたCSVファイルを用意してください。
      </Text>
      <Controller
        name="file"
        control={control}
        rules={validationRules.file}
        render={({ field }) => (
          <>
            <Box
              {...getRootProps()}
              p={4}
              textAlign="center"
              border="2px dashed"
              borderColor="gray.300"
              borderRadius="md"
              _hover={{ borderColor: "gray.500" }}
              cursor="pointer"
            >
              <input
                {...getInputProps({
                  onChange: e => {
                    if (e.target.files && e.target.files.length > 0) {
                      const file = e.target.files[0];
                      const fileExtension = file.name
                        .split(".")
                        .pop()
                        ?.toLowerCase();
                      if (fileExtension !== "csv") {
                        setError("file", {
                          type: "validate",
                          message: "CSVファイルを選択してください。"
                        });
                        setFileName(null);
                      } else {
                        clearErrors("file");
                        field.onChange(file);
                        setFileName(file.name);
                      }
                    } else {
                      field.onChange(null);
                      setFileName(null);
                    }
                  }
                })}
              />
              <Text>
                ここにファイルをドラッグ＆ドロップするか、クリックしてファイルを選択してください
              </Text>
              <Button mt={2} colorScheme="teal">
                ファイルを選択
              </Button>
            </Box>
            {fileName && (
              <Text mt={2} color="teal.500">
                選択されたファイル: {fileName}
              </Text>
            )}
            {errors.file && typeof errors.file.message === "string" && (
              <Text color="red.500" fontSize="sm">
                {errors.file.message}
              </Text>
            )}
            {fileRejectionItems}
          </>
        )}
      />
    </FormControl>
  );
};
