import type { ResponseCreateCall } from "./aspida/@types";

import { apiClient, getBearerToken } from "@/utils/apiClient";

export const createCall = async (): Promise<string | undefined> => {
  const bearerToken = await getBearerToken();
  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };

  try {
    const body: ResponseCreateCall = await apiClient.create_call.$post({
      config: {
        headers: headers
      }
    });
    console.log(body);
    return body.call_id;
  } catch (e) {
    // TODO: エラー通知
    console.error("Error:", e);
    return undefined;
  }
};
