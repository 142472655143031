import type { ResponseFAQContent } from "./aspida/@types";

import { apiClient, getBearerToken } from "@/utils/apiClient";

export const getFrequencyRank = async () => {
  const bearerToken = await getBearerToken();
  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };

  try {
    const res: ResponseFAQContent = await apiClient.faq.frequency_rank.$get({
      config: {
        headers: headers
      }
    });
    console.log(res);
    return res;
  } catch (e) {
    console.log("frequency_rank api error");
    // TODO: エラー通知
    return false;
  }
};
