import type { WordForFAQSearchList } from "./aspida/@types";

import { apiClient, getBearerToken } from "@/utils/apiClient";

type Params = {
  callId: string;
};

export const getSearchTags = async (params: Params) => {
  const { callId } = params;

  const newParams = {
    call_id: callId
  };
  const bearerToken = await getBearerToken();
  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };

  try {
    const body: WordForFAQSearchList = await apiClient.search_tags.$get({
      query: newParams,
      config: {
        headers: headers
      }
    });
    console.log(body);
    return body;
  } catch (e) {
    console.log("search_tags api error");
    // TODO: エラー通知
    return false;
  }
};
