import { forwardRef, Input } from "@chakra-ui/react";

export const DateInput = forwardRef(
  (
    { value, onClick, onChange, size = "lg", placeholder = "YYYY-MM-dd" },
    ref
  ) => (
    <Input
      ref={ref}
      bg="white"
      autoComplete="off"
      onChange={onChange}
      onClick={onClick}
      placeholder={placeholder}
      size={size}
      value={value}
    />
  )
);

DateInput.displayName = "DateInput";
