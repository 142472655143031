import { useToast } from "@chakra-ui/react";

import type { ToastPosition } from "@chakra-ui/react";

export const useCustomToast = () => {
  const toast = useToast();
  const defaultDuration = 3000;
  const toastOptions = {
    position: "top" as ToastPosition | undefined,
    isClosable: true,
    containerStyle: {
      width: "768px",
      maxWidth: "100%",
      paddingX: "2"
    }
  };

  const showErrorToast = (
    title: string,
    description?: string,
    duration?: number
  ) => {
    const id = title;
    if (!toast.isActive(id)) {
      toast({
        ...toastOptions,
        duration: duration ?? defaultDuration,
        id,
        title,
        description,
        status: "error"
      });
    }
  };

  const showSuccessToast = (
    title: string,
    description?: string,
    duration?: number
  ) => {
    const id = title;
    if (!toast.isActive(id)) {
      toast({
        ...toastOptions,
        duration: duration ?? defaultDuration,
        id,
        title,
        description,
        status: "success"
      });
    }
  };

  const showInfoToast = (
    title: string,
    description?: string,
    duration?: number
  ) => {
    const id = title;
    if (!toast.isActive(id)) {
      toast({
        ...toastOptions,
        duration: duration ?? defaultDuration,
        id,
        title,
        description,
        status: "info"
      });
    }
  };

  const showWarningToast = (
    title: string,
    description?: string,
    duration?: number
  ) => {
    const id = title;
    if (!toast.isActive(id)) {
      toast({
        ...toastOptions,
        duration: duration ?? defaultDuration,
        id,
        title,
        description,
        status: "warning"
      });
    }
  };

  return {
    showErrorToast,
    showSuccessToast,
    showInfoToast,
    showWarningToast
  };
};
