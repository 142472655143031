import {
  Box,
  Button,
  HStack,
  Heading,
  ModalBody,
  Text,
  VStack,
  useDisclosure
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import type { SummaryFormData } from "@/components/Admin";
import type { ResponseGetSummaryTarget } from "@api/@types";

import { deleteSummaryTarget } from "@/api/admin/Summary/delete.ts";
import { getSummaryTarget } from "@/api/admin/Summary/get.ts";
import { upsertSummaryTarget } from "@/api/admin/Summary/upsert.ts";
import { SummaryTargetForm } from "@/components/Admin";
import { CenterDialog } from "@/components/Dialogs/CenterDialog";
import { useCustomToast } from "@/hooks/useCustomToast";
import { AdminHeader } from "@/layouts/AdminHeader";
import { routing } from "@/utils/routing";

type PageStateType = "show" | "edit";

export const SummarizeContent = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isEditing = searchParams.get("edit") === "true";
  const [pageState, setPageState] = useState<PageStateType>(
    isEditing ? "edit" : "show"
  );
  const { showSuccessToast } = useCustomToast();
  const { pageId } = useParams();
  const [content, setContent] = useState<ResponseGetSummaryTarget | undefined>(
    undefined
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setPageState(isEditing ? "edit" : "show");
  }, [isEditing]);

  useEffect(() => {
    (async () => {
      await fetchContent();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchContent = async () => {
    if (pageId === undefined) return;
    const data = await getSummaryTarget({ id: pageId });
    if (data) {
      setContent(data);
    }
  };

  const removeAction = async () => {
    if (pageId === undefined) return;
    const res = await deleteSummaryTarget({ id: pageId });
    console.log(res);
    if (res) {
      navigate(routing.admin.summary.list.path);
      showSuccessToast(`通話要約:「${content?.name}」を削除しました。`);
    }
  };

  const submitAction = async (data: SummaryFormData) => {
    if (pageId === undefined) return;
    const status = await upsertSummaryTarget({
      id: pageId,
      name: data.name,
      details: data.details,
      importanceLevel: data.importanceLevel
    });

    if (status) {
      fetchContent();
      navigate(routing.admin.summary.content.genPath(pageId));
    }
    console.log(data);
    console.log("submitAction");
  };

  const backAction = () => {
    if (pageId === undefined) return;
    console.log("backAction");
    navigate(routing.admin.summary.content.genPath(pageId));
  };

  const handleEdit = () => {
    console.log("handleEdit");
    setSearchParams({ edit: "true" });
  };

  const summaryData: {
    id: keyof SummaryFormData;
    label: string;
    desription?: string;
    placeholder?: string;
    value: SummaryFormData[keyof SummaryFormData] | undefined;
  }[] = [
    {
      id: "name",
      label: "項目名",
      placeholder: "例: 年齢",
      value: content?.name
    },
    {
      id: "details",
      label: "要約のシステム指示",
      placeholder: "例: 会話内容から年齢を推定してください",
      desription: "AIに要約して欲しい内容をわかりやすく記述してください",
      value: content?.details
    },
    {
      id: "importanceLevel",
      label: "重要度",
      value: content?.importance_level || 1
    }
  ];

  if (content === undefined) return <></>;

  return (
    <VStack w="full" spacing="6">
      <AdminHeader
        title={pageState === "show" ? "通話要約 詳細" : "通話要約 編集"}
        onReturnClick={() => {
          if (pageState === "show") {
            navigate(routing.admin.summary.list.path);
          } else {
            if (pageId === undefined) return;
            navigate(routing.admin.summary.content.genPath(pageId));
          }
        }}
      />
      {pageState === "show" ? (
        <VStack w="full" px={10} pb={8} spacing="8">
          <HStack justify="flex-end" w="full">
            <HStack spacing="6">
              <Button
                colorScheme="purple"
                onClick={() => onOpen()}
                variant="outline"
              >
                削除
              </Button>
              <Button colorScheme="purple" onClick={handleEdit}>
                編集
              </Button>
            </HStack>
          </HStack>
          <VStack w="full" spacing="10">
            {summaryData.map((item, index) => (
              <VStack key={item.id + index} align="start" w="full">
                <Heading as="h3" w="full" fontSize="3xl" fontWeight="bold">
                  {item.label}
                </Heading>
                {item.desription && (
                  <Text mt={1.5} fontSize="md">
                    {item.desription}
                  </Text>
                )}
                <Box px={4} py={2} min-h={20}>
                  <Text fontSize="2xl">{item.value}</Text>
                </Box>
              </VStack>
            ))}
          </VStack>
        </VStack>
      ) : (
        <SummaryTargetForm
          summaryData={summaryData}
          submitAction={submitAction}
          backAction={backAction}
        />
      )}
      <CenterDialog
        isOpen={isOpen}
        primaryBtnLabel="削除"
        primaryBtnColorScheme="red"
        primaryBtnClick={removeAction}
        secondaryBtnLabel="キャンセル"
        secondaryBtnClick={onClose}
        secondaryBtnColorScheme="red"
        secondaryBtnVariant="outline"
        onClose={onClose}
      >
        <ModalBody p={6}>
          <Text align="left">通話要約:「{content.name}」 を削除しますか</Text>
        </ModalBody>
      </CenterDialog>
    </VStack>
  );
};
