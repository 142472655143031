import { Button, HStack, Input } from "@chakra-ui/react";
import { useEffect, useState, type FC } from "react";
import { MdSearch } from "react-icons/md";

import { useMainContext } from "@/contexts/mainContext/useMainContext";
import { useFontSize } from "@/hooks/useFontSize";

type ProposalSearchBarProps = {
  words: string[];
  setWords: (words: string[]) => void;
  handleSearch: (inputValue: string) => void;
};

export const ProposalSearchBar: FC<ProposalSearchBarProps> = ({
  words,
  setWords,
  handleSearch
}) => {
  const mainCtx = useMainContext();
  const callId = mainCtx.callId;
  const fontSize = useFontSize();
  const [inputValue, setInputValue] = useState("");
  const [isCompositionFinished, setIsCompositionFinished] =
    useState<boolean>(true);

  useEffect(() => {
    setInputValue(words.join(" "));
  }, [words]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWords(e.target.value.split(/[\u3000 ]+/));
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && isCompositionFinished) {
      e.preventDefault();
      handleSearch(inputValue);
    }
  };

  return (
    <HStack w="full" spacing={0}>
      <Input
        fontSize={fontSize}
        borderRightRadius={0}
        borderLeftRadius={6}
        bgColor="white"
        isDisabled={!callId}
        onChange={handleInputChange}
        onCompositionEnd={() => setIsCompositionFinished(true)}
        onCompositionStart={() => setIsCompositionFinished(false)}
        onKeyDown={handleKeyDown}
        placeholder="検索タグを入力"
        type="search"
        value={inputValue}
      />
      <Button
        fontSize={fontSize}
        borderRightRadius={6}
        borderLeftRadius={0}
        colorScheme="gray"
        isDisabled={!inputValue || !callId}
        leftIcon={<MdSearch />}
        onClick={() => handleSearch(inputValue)}
        size="md"
      >
        検索
      </Button>
    </HStack>
  );
};
