import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  fonts: {
    heading: `"Inter", "Noto Sans JP", sans-serif`,
    body: `"Inter", "Noto Sans JP", sans-serif`
  },
  styles: {
    global: {
      "#root": {
        padding: 0,
        width: "100%"
      }
    }
  }
});

export const theme = extendTheme(customTheme);
