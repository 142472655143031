import {
  Box,
  Button,
  Card,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Text,
  Textarea,
  VStack,
  useMediaQuery
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { MdContentCopy } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import type { TalkSummary } from "@/api/talkSummary";
import type { SubmitHandler } from "react-hook-form";

import { postFeedbackResult } from "@/api/feedbackResult";
import { talkSummary } from "@/api/talkSummary";
import { OperatorNotes } from "@/components/OperatorNotes";
import { RatingsButton } from "@/components/Ratings";
import { SpeakerBox } from "@/components/SpeakerBox";
import { Spinner } from "@/components/Spinner";
import { SummaryTable } from "@/components/SummaryTable";
import { useMainContext } from "@/contexts/mainContext/useMainContext";
import { useCustomToast } from "@/hooks/useCustomToast";
import { useFontSize } from "@/hooks/useFontSize";
import { usePostAggregateRelatedFaq } from "@/hooks/usePostAggregateRelatedFaq";
import { Header } from "@/layouts/Header";

type SurveyFormData = {
  q1: string;
  q1Rating?: string;
  q2: string;
  q2Rating?: string;
  q3?: string;
  q3Rating?: string;
};

const questions = [
  {
    id: "q1",
    ratingId: "q1Rating",
    label: "「文字起こし」として抽出された内容は適切でしたか",
    placeholder: "回答を入力してください"
  },
  {
    id: "q2",
    ratingId: "q2Rating",
    label: "「提案候補」の内容は適切でしたか",
    placeholder: "回答を入力してください"
  },
  {
    id: "q3",
    ratingId: "q3Rating",
    label: "その他困ったことなど",
    placeholder: "回答を入力してください"
  }
];

export const Feedback = () => {
  const [isLargerThan960] = useMediaQuery("(min-width: 960px)");
  const methods = useForm<SurveyFormData>();
  const { handleSubmit, control } = methods;
  const navigate = useNavigate();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [talkSummaryContent, setTalkSummaryContent] = useState<
    TalkSummary | undefined
  >(undefined);
  const { showSuccessToast, showErrorToast } = useCustomToast();
  const textFontSize = useFontSize();
  const headingSize = useFontSize("lg");
  const mainCtx = useMainContext();
  const { postData } = usePostAggregateRelatedFaq();

  const onSubmit: SubmitHandler<SurveyFormData> = async (
    data: SurveyFormData
  ) => {
    setIsLoadingSubmit(true);
    const callId = mainCtx.callId;
    if (!callId) {
      throw Error("CallID Not Fond in /feedback");
    }
    const jsonData = JSON.parse(JSON.stringify(data));
    let status = true;
    for (const key of Object.keys(data)) {
      const questionObject = questions.find(q => {
        return q.id === key;
      });
      if (questionObject) {
        const question = questionObject.label || "";
        const ratingFieldKey = questionObject.ratingId || "";
        const answer = {
          rating: jsonData[ratingFieldKey] || "",
          description: jsonData[key] || ""
        };
        const childStatus = await postFeedbackResult({
          callId,
          question,
          answer
        });
        status = status && childStatus;
      }
    }
    if (!status) {
      showErrorToast(
        "フィードバック送信に失敗しました。",
        "お手数おかけしますが、修正が完了するまでお待ちください。"
      );
    }

    setIsLoadingSubmit(false);
    mainCtx.setMainContext({ callId: null, transcriptions: [], notes: "" });
    navigate("/");
    console.log("アンケートデータ:", data);
  };

  useEffect(() => {
    const getTalkSummary = async () => {
      const callId = mainCtx.callId;
      if (!callId) {
        throw Error("CallID Not Fond in /feedback");
      }
      talkSummary(callId).then(res => {
        if (res) {
          setTalkSummaryContent(res);
        }
      });
    };
    if (!mainCtx.callId) {
      navigate("/");
    }
    getTalkSummary();
    postData({ call_id: mainCtx.callId as string }); // バックグランドタスクで即座に202を返す
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  type copyClipboardProps = {
    label: string;
    text: string;
  };
  const copyClipboard = ({ label, text }: copyClipboardProps) => {
    navigator.clipboard
      .writeText(text)
      .then(function () {
        showSuccessToast(`${label}の内容をクリップボードへコピーしました`);
      })
      .catch(function () {
        showErrorToast("クリップボードへのコピーに失敗しました");
      });
  };

  return (
    <VStack w="full" minW="656px" pb="8" bgColor="gray.50" spacing="8">
      <Header isLargerThan960 isSpeaker={false} />
      <VStack
        w="full"
        maxW={isLargerThan960 ? "960px" : "full"}
        px="6"
        spacing="6"
      >
        <Heading size={headingSize}>書き起こし</Heading>
        <Card w="full" p={4} borderRadius={8}>
          <Box overflowY="scroll" w="full" maxH="352px" pb={10} bg="white">
            <SpeakerBox transcriptions={mainCtx.transcriptions} />
          </Box>
        </Card>
        <Heading ml="16" size={headingSize}>
          メモ欄
          <IconButton
            mx={3}
            color="gray"
            fontSize="2xl"
            aria-label="copy"
            icon={<MdContentCopy />}
            onClick={() => {
              copyClipboard({ label: "メモ欄", text: mainCtx.notes });
            }}
          />
        </Heading>

        <Box w="full" bg="white">
          <OperatorNotes />
        </Box>
        <Heading size={headingSize}>通話要約</Heading>
        <Box w="full">
          {talkSummaryContent ? (
            <SummaryTable summary={talkSummaryContent} />
          ) : (
            <Spinner height="48" />
          )}
        </Box>
        <Heading size={headingSize}>対応後のフィードバック</Heading>
        <Text w="full" fontSize={textFontSize}>
          ご対応後のフィードバックをお願いします。今後のアプリ精度改善などに利用されます。
        </Text>
        <Box as="form" w="full" onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            <VStack
              w="full"
              maxW={isLargerThan960 ? "960px" : "full"}
              pb="8"
              spacing="10"
            >
              {questions.map(question => (
                <FormControl key={question.id}>
                  <VStack spacing="4">
                    <FormLabel w="full" mr="0" htmlFor={question.id}>
                      <Heading as="h3" fontWeight="normal" size={textFontSize}>
                        <Text
                          as="span"
                          fontWeight="medium"
                          textTransform="uppercase"
                        >
                          {question.id}.
                        </Text>
                        {question.label}
                      </Heading>
                    </FormLabel>
                    <RatingsButton fieldName={question.ratingId} />
                    <Controller
                      control={control}
                      defaultValue=""
                      name={question.id as keyof SurveyFormData}
                      render={({ field }) => (
                        <Textarea
                          h="28"
                          fontSize={textFontSize}
                          id={question.id}
                          placeholder={question.placeholder}
                          {...field}
                        />
                      )}
                    />
                  </VStack>
                </FormControl>
              ))}
            </VStack>
            <Box w="100%" textAlign="center">
              <Button
                w="132px"
                textAlign="center"
                colorScheme="orange"
                isLoading={isLoadingSubmit}
                size="lg"
                type="submit"
              >
                送信
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </VStack>
    </VStack>
  );
};
