import { CalendarIcon } from "@chakra-ui/icons";
import { InputGroup, InputRightElement } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { ja } from "date-fns/locale";
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale
} from "react-datepicker";

import { DateInput } from "../DateInput";

import type { FC } from "react";

import "react-datepicker/dist/react-datepicker.css";
registerLocale("ja", ja);

type DatePicker = ReactDatePickerProps;

const DatePickerWrapper = styled.div`
  width: 148px;
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
`;

export const DatePicker: FC<DatePicker> = props => {
  return (
    <InputGroup>
      <DatePickerWrapper>
        <ReactDatePicker
          locale={ja}
          {...props}
          customInput={<DateInput size="md" />}
          dateFormat="YYYY-MM-dd"
          calendarStartDay={1}
          isClearable={false}
          showPopperArrow={false}
          className={"react-datapicker__input-text"}
        />
      </DatePickerWrapper>
      <InputRightElement
        top="0"
        right="1"
        color="gray.500"
        pointerEvents="none"
      >
        <CalendarIcon fontSize="md" />
      </InputRightElement>
    </InputGroup>
  );
};
