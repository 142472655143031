import {
  Button,
  HStack,
  ModalHeader,
  Stack,
  StackProps,
  Text,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import { type FC } from "react";
import { useLocation } from "react-router-dom";

import { HeaderMenu } from "@/components/DialMate/HeaderMenu";
import { CenterDialog } from "@/components/Dialogs/CenterDialog";
import { Logo } from "@/components/Logo";
import { MicToggleButton } from "@/components/MicToggleButton";
import { Operator } from "@/components/Operator";
import { Phone } from "@/components/Phone";
import { Zoom } from "@/components/Zoom";
import { useAuthContext } from "@/contexts/auth/useAuthContext";
import { useMainContext } from "@/contexts/mainContext/useMainContext";
import { useCustomToast } from "@/hooks/useCustomToast";

type HeaderProps = {
  isLargerThan960: boolean;
  isSpeaker: boolean;
  children?: React.ReactNode;
  startVoiceRecording?: () => Promise<void>;
} & StackProps;

export const Header: FC<HeaderProps> = ({
  isLargerThan960,
  isSpeaker,
  children,
  startVoiceRecording,
  ...res
}) => {
  const { userEmail } = useAuthContext();
  const location = useLocation();
  const isHome = location.pathname === "/";
  const mainCtx = useMainContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showInfoToast } = useCustomToast();

  const handleRecording = async () => {
    if (!mainCtx.isRecording) {
      startVoiceRecording && (await startVoiceRecording());
    } else {
      onOpen();
    }
  };

  return (
    <Stack
      pos="sticky"
      zIndex="1"
      top="0"
      align="center"
      w="full"
      py="8"
      pb={isHome ? "0" : "8"}
      bgColor="cyan.100"
      {...res}
    >
      <VStack
        pos="relative"
        w="full"
        maxW={isLargerThan960 ? "960px" : "full"}
        px="6"
        spacing="6"
      >
        <HStack justify="space-between" w="full">
          <Logo />
          <HStack>
            <Operator h="8" w="8" />
            <Text fontSize="xl">{userEmail}</Text>
            <HeaderMenu />
          </HStack>
        </HStack>
        {isHome && (
          <VStack w="full" spacing="6">
            <HStack justify="space-between" w="full">
              <HStack spacing="3">
                <HStack justify="start" spacing={0}>
                  <Phone isSpeaker={isSpeaker} />
                  <Button
                    borderTopLeftRadius="none"
                    borderBottomLeftRadius="none"
                    colorScheme={mainCtx.isRecording ? "red" : "green"}
                    onClick={handleRecording}
                    size="lg"
                  >
                    {mainCtx.isRecording ? "終了" : "開始"}
                  </Button>
                </HStack>
              </HStack>
              <Zoom />
            </HStack>
            <HStack justify="space-between" w="full" pl="5">
              <MicToggleButton />
              {children}
            </HStack>
          </VStack>
        )}
      </VStack>
      <CenterDialog
        isOpen={isOpen}
        primaryBtnLabel="終了"
        primaryBtnColorScheme="red"
        primaryBtnClick={() => {
          mainCtx.setMainContext({ isRecording: false });
          showInfoToast("通話を終了しました。");
          onClose();
        }}
        secondaryBtnLabel="キャンセル"
        secondaryBtnClick={onClose}
        secondaryBtnColorScheme="red"
        secondaryBtnVariant="outline"
        onClose={onClose}
      >
        <ModalHeader p={6}>
          <Text align="left">通話を終了しますか</Text>
        </ModalHeader>
      </CenterDialog>
    </Stack>
  );
};
