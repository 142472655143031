import { Box, Text } from "@chakra-ui/react";
import LinkifyReact from "linkify-react";
import { type FC } from "react";
import { LuStickyNote } from "react-icons/lu";

import type { BoxProps } from "@chakra-ui/react";
import type { ReactNode } from "react";

import { LinkifyLink } from "@/components/LinkifyLink";
import { useFontSize } from "@/hooks/useFontSize";

type ProposalMemoAreaProps = {
  memo: string;
} & BoxProps;

type ParseAndReplaceProps = (input: string) => ReactNode;

//Note: boldを表す**をText Componentに変換する
const parseAndReplace: ParseAndReplaceProps = input => {
  return (
    <>
      {input.split(/\*\*(.*?)\*\*/g).map((part, index) =>
        index % 2 === 1 ? (
          <Text key={index} as="span" fontWeight="bold">
            {part}
          </Text>
        ) : (
          part
        )
      )}
    </>
  );
};

export const ProposalMemoArea: FC<ProposalMemoAreaProps> = props => {
  const { memo, ...rest } = props;
  const fontSize = useFontSize("sm");
  return (
    <Box p={2} lineHeight="140%" bg="yellow.100" borderRadius="base" {...rest}>
      <Text
        as="span"
        display="inline-block"
        fontSize={fontSize}
        whiteSpace="pre-wrap"
      >
        <Text
          as="span"
          display="inline-block"
          fontSize={fontSize}
          fontWeight="bold"
        >
          <Box display="inline-block" verticalAlign="-4px" mr="1" fontSize="18">
            <LuStickyNote />
          </Box>
          メモ：
        </Text>
        <LinkifyReact
          options={{
            tagName: {
              url: () => LinkifyLink // 文字列にあるURLをLinkifyLinkに変換する
            }
          }}
        >
          {parseAndReplace(memo)}
        </LinkifyReact>
      </Text>
    </Box>
  );
};
