import type { RequestSearchFAQ, ResponseFAQContent } from "./aspida/@types";

import { apiClient, getBearerToken } from "@/utils/apiClient";

export const postSearchFaq = async (
  params: RequestSearchFAQ
): Promise<ResponseFAQContent | false> => {
  const { tags, call_id } = params;
  const bearerToken = await getBearerToken();
  const [, payload] = bearerToken.split(".");
  const { sub } = JSON.parse(atob(payload));

  const newParams: RequestSearchFAQ = {
    tags,
    operator_id: sub,
    call_id: call_id
  };
  const headers = {
    Authorization: `Bearer ${bearerToken}`
  };

  try {
    const res: ResponseFAQContent = await apiClient.search_faq.$post({
      body: newParams,
      config: {
        headers: headers
      }
    });
    console.log(res);
    return res;
  } catch (e) {
    console.log("search_faq api error");
    // TODO: エラー通知
    return false;
  }
};
