import { Textarea } from "@chakra-ui/react";

import { useMainContext } from "@/contexts/mainContext/useMainContext";

export const OperatorNotes = () => {
  const mainCtx = useMainContext();

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    mainCtx.setMainContext({ notes: inputValue });
  };

  return (
    <Textarea
      onChange={handleInputChange}
      placeholder="自由メモ帳です。このメモは応対ごとにリセットされます。"
      size="md"
      value={mainCtx.notes}
    />
  );
};
