import { Alert, AlertIcon } from "@chakra-ui/react";

import type { FC } from "react";

import { ProposalProps } from "@/components/ProposalAccordion/";
import { useFontSize } from "@/hooks/useFontSize";

type ProposalNgAlertProps = Pick<ProposalProps, "ng_operation_note">;

export const ProposalNgAlert: FC<ProposalNgAlertProps> = ({
  ng_operation_note
}) => {
  const smallFontSize = useFontSize("sm");

  return (
    <Alert
      my="2"
      p={2}
      fontSize={smallFontSize}
      borderRadius="base"
      status="warning"
      variant="subtle"
    >
      <AlertIcon />
      {ng_operation_note}
    </Alert>
  );
};
