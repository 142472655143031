import { Box, HStack, Icon, Text, useRadioGroup } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { MdOutlineSentimentNeutral } from "react-icons/md";

import type { FC } from "react";
import type { IconType } from "react-icons";

import { useFontSize } from "@/hooks/useFontSize";

type RatingProps = {
  id: string;
  label: string;
  icon: IconType | React.FC;
};

type Props = {
  fieldName: string;
};

export const RatingsButton: FC<Props> = (props: Props) => {
  const { fieldName } = props;
  const { register, watch, setValue: setFormValue } = useFormContext();
  const selectedValue = watch(fieldName);
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: fieldName,
    value: selectedValue,
    onChange: val => setFormValue(fieldName, val)
  });
  const fontSize = useFontSize("lg");
  const group = getRootProps();

  const ratings: RatingProps[] = [
    { id: "good", label: "よかった", icon: FiThumbsUp },
    { id: "normal", label: "普通", icon: MdOutlineSentimentNeutral },
    { id: "bad", label: "悪かった", icon: FiThumbsDown }
  ];

  return (
    <HStack
      {...group}
      justifyContent="center"
      borderWidth={1}
      borderRadius="md"
      spacing="0.5"
    >
      {ratings.map(rating => {
        const { isChecked, ...inputProps } = getRadioProps({
          value: rating.label
        });

        return (
          <Box
            key={rating.id}
            as="label"
            px={5}
            py={3}
            color="gray.800"
            cursor="pointer"
            bgColor={isChecked ? "gray.300" : "gray.100"}
          >
            <input
              type="radio"
              {...inputProps}
              {...register(fieldName)}
              hidden
            />
            <HStack>
              <Icon as={rating.icon} fontSize={fontSize} />
              <Text fontSize={fontSize} fontWeight="semibold">
                {rating.label}
              </Text>
              <Icon as={rating.icon} fontSize={fontSize} />
            </HStack>
          </Box>
        );
      })}
    </HStack>
  );
};
