import { Box } from "@chakra-ui/react";
import Lottie from "react-lottie-player";

import animationData from "./loadingDots.json";

import type { BoxProps } from "@chakra-ui/react";
import type { FC } from "react";
import type { LottieProps } from "react-lottie-player";

type ChatLoadingBallonProps = {
  lottieProps?: LottieProps;
} & BoxProps;

export const ChatLoadingBalloon: FC<ChatLoadingBallonProps> = props => {
  const { lottieProps, ...boxProps } = props;
  return (
    <Box
      sx={{ filter: "drop-shadow(0px 1px 4px rgba(153, 153, 153, 0.16));" }}
      pos="relative"
      alignSelf="flex-start"
      overflow="hidden"
      w="63px"
      h="31px"
      my="auto"
      px={3}
      py={2}
      color="text.main"
      bg="baloon.assistant.bg"
      borderBottomRadius="20px"
      borderTopRightRadius="20px"
      borderTopLeftRadius="3px"
      {...boxProps}
    >
      <Lottie
        play
        loop
        animationData={animationData}
        style={{
          width: "98.2px",
          height: "98.2px",
          position: "absolute",
          top: "50%",
          left: "-20px",
          transform: "translateY(-50%)",
          color: "#FFF5F7"
        }}
        {...lottieProps}
      />
    </Box>
  );
};
