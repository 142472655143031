import { useState, useEffect } from "react";

import {
  defaultHomeContext,
  HomeContext
} from "@/contexts/pages/Home/useHomeContext";

export type HomeContextType = {
  selectedQuestionText?: string;
};

type Props = {
  children: React.ReactNode;
};
export const HomeContextProvider = ({ children }: Props) => {
  const [homeContext, setContext] =
    useState<HomeContextType>(defaultHomeContext);

  const setHomeContext = (obj: HomeContextType) => {
    setContext({ ...homeContext, ...obj });
  };

  useEffect(() => {
    console.log(homeContext);
  }, [homeContext]);

  // useEffect(() => {
  //   setHomeContext(defaultHomeContext)
  // }, [])

  // NOTE: 事前処理でapi fetchする必要がある時に利用
  // if(homeContext === defaultHomeContext)
  //   return (<div>Loading...</div>)

  return (
    <HomeContext.Provider value={{ ...homeContext, setHomeContext }}>
      {children}
    </HomeContext.Provider>
  );
};
