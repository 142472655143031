import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Button,
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from "@chakra-ui/react";
import { MdLaunch, MdLogout } from "react-icons/md";

import { useAuthContext } from "@/contexts/auth/useAuthContext";

export const HeaderMenu = () => {
  const { logout } = useAuthContext();
  return (
    <Menu>
      <MenuButton as={Button} pos="relative">
        <HamburgerIcon pos="relative" top="-4px" fontSize="large" />
        <Text pos="absolute" bottom="4px" left="12px" fontSize="10px">
          Menu
        </Text>
      </MenuButton>
      <MenuList>
        <MenuItem
          as={Link}
          href="#"
          onClick={event => {
            event.preventDefault();
            logout();
            console.log("LOGOUT:clicked");
          }}
        >
          <HStack justify="space-between" w="full" h="full" p={0}>
            <Text>ログアウト</Text>
            <Icon as={MdLogout} />
          </HStack>
        </MenuItem>
        <MenuItem
          as={Link}
          href="/admin/FAQ"
          rel="noopener noreferrer"
          target="_blank"
        >
          <HStack justify="space-between" w="full" h="full" p={0}>
            <Text>管理ページを表示</Text>
            <Icon as={MdLaunch} />
          </HStack>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
